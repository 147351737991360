import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, IconButton, Typography } from '@mui/material'
import { Edit, ExpandMore, Print } from '@mui/icons-material'

import MapPoligons from 'components/MapPoligons'
import OkTable from 'components/OkTable'

import { useInDebug, usePerfil } from 'useAuth'
import _ from 'lodash'
import html2canvas from 'html2canvas'

import ResumoTalhaoReport from 'entity/AreaCultivada/reports/ResumoTalhaoReport'
import LaudoVisita from 'entity/LaudoVisita'

import { toast } from 'react-toastify'

export default function CardTalhaoMap({ talhao, areaCultivada, laudos }) {
    const [isLoading, setIsLoading] = useState(false)
    const captureRef = React.createRef();
    const IN_DEBUG = useInDebug()
    const navigate = useNavigate()
    const perfil = usePerfil();

    const poligono = talhao.poligono
    const plantios = (areaCultivada?.plantios || []).filter(p => p.talhao.nome === talhao.nome)

    const imprimirResumoTalhao = async () => {
        // console.log('captureRef.current', captureRef.current)
        // const canvas = await html2canvas(captureRef.current);
        try {
            setIsLoading(true)
            const canvas = await html2canvas(captureRef.current, {
                useCORS: true,
                allowTaint: false,
                ignoreElements: (node) => {
                    if (node.nodeName === 'IFRAME') {
                        return true;
                    }
                    if (node.nodeName === 'SPAN') {
                        return true;
                    }
                    if (node.nodeName === 'BUTTON') {
                        return true;
                    }
                    if (node.nodeName === 'A') {
                        return true;
                    }
                    if (node.classList.contains('gmnoprint')) {
                        return true;
                    }
                    if (node.classList.contains('gm-style-cc')) {
                        return true;
                    }
                    return false;
                }

            });
            const imageTalhao = canvas.toDataURL("image/png", 1.0);
            new ResumoTalhaoReport({ talhao: talhao, areaCultivada, perfil, imageTalhao }).generate(IN_DEBUG);

        } catch (error) {
            toast.error("Erro ao gerar relatório de talhão. Verifique os dados e tente novamente")
        } finally {
            setIsLoading(false)
        }
        // const context = canvas.getContext("2d");
        // console.log('canvas', canvas)
        // console.log('context', context)
        // console.log('image',imageTalhao)
    }

    const filterLaudos = []

    if (laudos) {
        laudos.forEach((laudo) => {
            laudo.talhoesLaudo.filter((talhaoLaudo) => {
                if (talhao.nome === talhaoLaudo.nome) {
                    filterLaudos.push(laudo)
                }
                return null
            })
        })
    }

    return (
        <Card sx={{ m: 1, p: 1, display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            {poligono ? <Box sx={{ display: 'flex', flex: '1 1 350px', height: 350, maxWidth: 350 }} ref={captureRef}>
                <MapPoligons outrosPoligonos={[poligono]} />
            </Box> : <Box sx={{ display: 'flex', flex: 1, height: 350, maxWidth: 350, alignItems: "center", justifyContent: "center" }} >
                <Typography>Talhão não definido no mapa</Typography>
            </Box>}
            <Box sx={{ flex: 1 }} >
                <Box sx={{ display: 'flex', flex: 1, gap: 1, flexWrap: "wrap", pb: 1 }}>
                    <Typography variant='h6' sx={{ flex: "1 1 250px" }}>{talhao.nome}- Área{"(" + areaCultivada.unidadeArea + ")"}: {talhao.areaHa}</Typography>
                    <Button disabled={isLoading} onClick={() => { imprimirResumoTalhao() }}>{isLoading ? "Carregando" : "Imprimir Resumo"}</Button>
                    <Button onClick={(evt) => {
                        if (evt.ctrlKey) {
                            window.open('/laudoVisita?uidArea=' + areaCultivada.uid + "&talhao=" + talhao.nome, '_blank')
                        } else {
                            navigate('/laudoVisita?uidArea=' + areaCultivada.uid + "&talhao=" + talhao.nome)
                        }

                    }}>Novo Laudo</Button>

                </Box>
                <Typography variant='subtitle2'>Plantios</Typography>
                {/*  v = value.split('-').reverse().join("/") */}
                <OkTable
                    noDataComponent={"Sem dados de Plantio"}
                    data={plantios}
                    columns={[
                        { name: 'Variedade', field: "cultivar.descricao", },
                        { name: 'Área', field: "area", width: "100px", mobileGridRow: '1', mobileGridColumn: "3", },
                        { name: 'Ciclo', field: "cultivar.ciclo", width: "65px", mobileGridRow: '1', mobileGridColumn: "2", },
                        {
                            name: 'Início Plantio', width: "105px", type: "date",
                            selector: (row) => {
                                let data = _.get(row, "dataInicioPlantio")
                                if (data) {
                                    return data = data.split("-").reverse().join("/")
                                }
                                return data
                            }
                        },
                        {
                            name: 'Fim Plantio', width: "105px",
                            selector: (row) => {
                                let data = _.get(row, "dataFimPlantio")
                                if (data) {
                                    return data = data.split("-").reverse().join("/")
                                }

                                return data
                            }
                        },
                        {
                            name: 'Prev. Colheita', width: "115px",
                            selector: (row) => {
                                let data = _.get(row, "dataPrevInicioColheita")
                                if (data) {
                                    return data = data.split("-").reverse().join("/")
                                }

                                return data
                            }
                        },
                        {
                            name: 'Área Colhida', width: "130px",
                            selector: (row) => {
                                let areaColhida = _.get(row, "colheita.areaColhida")
                                let areaPlantada = _.get(row, "area")
                                let str = ""
                                if (areaColhida) {
                                    str += areaColhida + " " + areaCultivada.unidadeArea + " (";
                                    str += (parseFloat(areaColhida) / parseFloat(areaPlantada) * 100).toFixed(2);
                                    str += "%)";
                                }
                                return str
                            }
                        },
                        { name: 'Produção(' + areaCultivada.unidadeProducao + ")", field: "colheita.producao", width: "110px", },
                        { name: '' + areaCultivada.unidadeProducao + "/" + areaCultivada.unidadeArea, field: "colheita.produtividade", width: "70px", },

                    ]}
                />

                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography color="text.secondary">Laudos Relacionados</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OkTable
                            noDataComponent={"Sem Laudos"}
                            mobileColumns={6}
                            columns={[
                                {
                                    width: "100px",
                                    mobileOrder: 3,
                                    mobileGridColumn: "4 / span 3",
                                    cell: (row) => {
                                        return <div style={{ display: "flex" }}>
                                            <IconButton onClick={() => LaudoVisita.preparePrint(row, perfil, areaCultivada)}><Print /></IconButton>
                                            <IconButton onClick={(evt) => {
                                                if (evt.ctrlKey) {
                                                    window.open('/laudoVisita?uid=' + row.uid, '_blank')
                                                } else {
                                                    navigate('/laudoVisita?uid=' + row.uid)
                                                }

                                            }}><Edit /></IconButton>
                                        </div>
                                    }
                                },
                                { field: "dataCadastro", name: "Data", mobileOrder: 6, dataFormat: "dd/MM/yyyy hh:mm", mobileGridColumn: "4 / span 3" },
                                { name: "Status", selector: (l) => l.cancelado ? "Cancelado" : "Emitido", mobileOrder: 2, mobileGridColumn: "2 / span 2", },
                                { field: "sequencialIndex", name: "Nº", mobileOrder: 1, width: "50px", },
                                { field: "stringLotes", name: "Talhões", mobileOrder: 4, mobileGridColumn: "1 / span 6" },
                                { field: "userNome", name: "Técnico", mobileOrder: 5, mobileGridColumn: "1 / span 3" },
                            ]} data={filterLaudos} />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Card >
    )
}
