import BasicRoute from "components/routes/BasicRoute";
import { useSnapListEntity } from "entity";
import Convite from "entity/Convite";
import PainelConvites from "entity/Convite/components/PainelConvites";


const queryNaoAceitos = (q) => q.where("aceito", "==", null)
function CompConviteText() {
    const [convites] = useSnapListEntity(Convite, queryNaoAceitos)
    let text = 'Convites'
    if (convites && convites.length) {
        text += " (" + convites.length + ")"
    }
    return <span>{text}</span>
}
export default function RoutesMenusConvites() {
    return <>
        <BasicRoute
            uid='Convites'
            route="Convites"
            menuProps={{
                text: <CompConviteText />,
                sequence: "800001",
                iconName: "InsertComment",
            }}
            element={<PainelConvites />}
        />



    </>;
}