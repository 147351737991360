import QueryFactory from "QueryFactory";
import { collection, doc, setDoc, getDoc, onSnapshot, serverTimestamp, updateDoc } from "firebase/firestore";
import firebaseUtil from "firebaseUtil";

export function salvarPerfilLogin(UserFirebase) {
    let obj = { uid: UserFirebase.uid, name: UserFirebase.displayName, email: UserFirebase.email, emailVerified: UserFirebase.emailVerified };

    let plataforma = "WEB"

    if (window.cordova) {
        plataforma = window.cordova.platformId
    }

    saveOnlyNew(obj, "User", { lastLogin: serverTimestamp(), plataforma: plataforma })

}
export function salvarPerfilRegistro(UserFirebase, add) {
    let obj = {
        uid: UserFirebase.uid,
        nome: UserFirebase.displayName,
        email: UserFirebase.email,
        emailVerified: UserFirebase.emailVerified,
        lastLogin: serverTimestamp()
    };
    let startDate = new Date()
    let endDate = new Date(startDate)

    endDate.setMonth(startDate.getMonth() + 1);

    obj.firstLogin = serverTimestamp()
    obj.dataCriacao = serverTimestamp()
    obj.trialDate = { start: startDate.toISOString().split('T')[0], end: endDate.toISOString().split('T')[0], isActive: true }

    if (add) {
        obj = { ...obj, ...add }
    }

    saveOnlyNew(obj, "User")
}

export function saveOnlyNew(obj, colectionStr, ifExistOnlyUpdateData) {
    let colection = collection(firebaseUtil.db, colectionStr);

    const { uid } = obj;

    let docSalvar = null;

    if (uid) {
        docSalvar = doc(colection, uid);
    } else {
        docSalvar = doc(colection);
        obj.uid = docSalvar.id;
    }

    obj.lastChange = serverTimestamp()

    if (ifExistOnlyUpdateData) {
        return getDoc(docSalvar)
            .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                    let dataUpdate = { uid, lastChange: serverTimestamp(), ...ifExistOnlyUpdateData }
                    return updateDoc(docSalvar, dataUpdate);
                } else {
                    return setDoc(docSalvar, obj, { merge: true })
                }
            }).catch((err) => {
                console.log("ERRO NO CADASTRO/UPDATE USUÁRIO ", err);
            });
    } else {
        return setDoc(docSalvar, obj, { merge: true }).catch((err) => {
            console.log("ERRO NO CADASTRO/UPDATE USUÁRIO ", err);
        })
    }
}

//#region - crateSnapDocument (nao consideraPermissao)
export function crateSnapDocument(uid, strCollection, resolve, reject, uidUser) {
    return crateSnapDocumentDatabase(firebaseUtil.db, uid, strCollection, resolve, reject, uidUser)
}
export function crateSnapDocumentDatabase(database, uid, strCollection, resolve, reject, uidUser) {
    if (!database) database = firebaseUtil.db;
    // console.log("buscaSyncCollection", strCollection);
    let docSalvar = doc(collection(database, strCollection), uid);

    let observer = onSnapshot(docSalvar
        , docSnapshot => {
            if (docSnapshot.exists) {
                resolve(docSnapshot.data())
            } else {
                reject({ message: "not fond", code: 404 })
            }
        }, err => {
            console.log("buscaSyncObj error:", err);
            reject(err)
        });
    return observer;
}

//#endregion
//#region - crateSnapColectins (nao consideraPermissao)
export function getCollectionApi(strCollection) {
    const colStart = new QueryFactory(null, strCollection);
    // new Qu
    // as
    // let colStart = collection(firebaseUtil.db, strCollection)

    return colStart;
}
//#endregion

