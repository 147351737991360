import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Form } from 'react-final-form';
import TextInput from '../input/TextInput';
import OkTable from 'components/OkTable';


function FormFilter(props) {
    const { setFilteredValues, valuesList, columns } = props
    let filtersR = props.values
    const doFilter = useCallback((filters, valuesFiltrar) => {

        // estava sendo feita comparação de number com string, por isso gerava erro. Desenvolvi essa solução simples
        const compareValues = (v, f) => {
            if (typeof v === 'object') {
                let value = v.toDate().toISOString().split('T')[0]
                let newValue = value.split('-').reverse().join("/")

                if (newValue.includes(f))

                    return true
            }

            if (typeof v === "number") {
                let numberToString = v.toString()
                if (numberToString.toUpperCase().includes(f.toUpperCase())) {
                    return true;
                }
                return false;
            }

            if (typeof v === "string") {
                if (v.toUpperCase().includes(f.toUpperCase())) {
                    return true;
                }
                return false;
            }
        }

        if (valuesFiltrar) {
            let newArray = valuesFiltrar.filter(linha => {

                for (let index = 0; index < columns.length; index++) {
                    const column = columns[index];

                    if (column.filter) {
                        let filterValue = _.get(filters, column.field)
                        if (column.filter.compare) {
                            return column.filter.compare(filterValue, linha, filters)
                        } else {
                            if (filterValue) {
                                let compareValue = _.get(linha, column.field)
                                if (compareValue) {
                                    if (!compareValues(compareValue, filterValue, column)) {
                                        return false
                                    }

                                } else {
                                    return false
                                }
                            }
                        }
                    }

                }

                return true;
            })

            setFilteredValues(newArray)
        } else {
            setFilteredValues(undefined)
        }
    },
        [columns, setFilteredValues],
    )
    useEffect(() => {
        // console.log('useEffect', useEffect)
        if (valuesList) {
            doFilter(filtersR, valuesList)
        } else {
            setFilteredValues(undefined)
        }

        return () => {
            setFilteredValues(undefined)
        }
    }, [valuesList, filtersR, doFilter, setFilteredValues])
    return <Accordion sx={{ boxShadow: 3, mb: 1 }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>Filtro</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {(columns || []).map((column, index) => column.filter ? <FilterConsult key={index} filters={filtersR} column={column} />
                : "")}
        </AccordionDetails>
    </Accordion>
}

function FilterConsult(props) {
    const { column } = props
    const filter = column.filter
    // const value = _.get(filters, column.field)
    // const onChange = (v) => {
    //     setFilters(old => {
    //         let newV = { ...old }
    //         _.set(newV, column.field, v)
    //         return newV;
    //     })
    // }

    if (filter.render) {
        return <>{filter.render(props)}</>
    }

    return <TextInput name={column.field} label={column.headerName} sx={{ width: "100%", my: 1 }} />


}

export default function DefaultConsultView({ columns, values, onRowClick, initialFilters, onFilteredValues, prospTable }) {

    const [filteredValues, setFilteredValues] = useState(values)



    useEffect(() => {
        if (filteredValues && onFilteredValues) {
            onFilteredValues(filteredValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = () => {
        console.log('handleSubmit', handleSubmit)
    }
    const initialValues = initialFilters || {}

    return (
        <Box sx={{ flex: 1, height: { xs: "100%", sm: "60vh" } }}>

            <Box sx={{ textAlign: "center", mt: 0 }}>
                <Form onSubmit={handleSubmit} initialValues={initialValues} >
                    {(formProps) => {
                        return (
                            <form onSubmit={formProps.handleSubmit}>
                                <FormFilter
                                    setFilteredValues={setFilteredValues}
                                    valuesList={values}
                                    columns={columns}
                                    {...formProps}
                                />
                            </form>
                        );
                    }}
                </Form>

            </Box>
            {filteredValues === undefined && <div style={{ display: 'flex', justifyContent: "center" }}> <CircularProgress /></div>}
            {/* 
            <DataTable
                idField="uid"
                columns={columns}
                rows={filteredValues || []}
                onRowClick={onRowClick}               
            /> */}
            {filteredValues &&
                <OkTable columns={columns} data={filteredValues} onRowClicked={onRowClick} pagination {...prospTable} />}
        </Box>
    )
}
