import {  TextField } from '@mui/material'
import DataTable from "react-data-table-component";
import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import {  useFieldValue } from 'components/form/input/NestedField'
import TextInput from 'components/form/input/TextInput'
import React from 'react'
import _ from 'lodash'

export default function PainelFases({ areaCultivada }) {
    const [itensFase, setitensFase] = useFieldValue("fasesCultura.itensFase")
    const [areaTotalLaudo] = useFieldValue("areaTotalLaudo")
    let areaFases = (itensFase || []).reduce((a, it) => a + parseFloat(it.area||0), 0).toFixed(2)
    const updatePorPercent = (item, index, valor) => {
        let newAr = itensFase.slice()
        
        newAr[index].area = (areaTotalLaudo * valor / 100).toFixed(2)
        setitensFase(newAr)
    }
    const updatePorArea = (item, index, valor) => {
        let newAr = itensFase.slice()
    
        newAr[index].porcentagem = (areaTotalLaudo * valor / 100).toFixed(2)
        setitensFase(newAr)
    }
    
    return (
        <FormContent>
            <FormRow>

                <TextField
                    sx={{ flex: 1 }}
                    label="Área do Laudo"
                    variant="standard"
                    value={areaTotalLaudo}
                    disabled
                />
                <TextField sx={{ flex: 1 }}

                    label="Área em Fases"
                    variant="standard"
                    value={areaFases}
                    disabled
                />

            </FormRow>

            <DataTable
                columns={[
                    {
                        name: "Nome",
                        selector: (row) => _.get(row, "nome")
                    },

                    {
                        name: "Porc. (%)",
                        width: "30%",
                        cell: (row, index) => <TextInput type={'number'} name={"fasesCultura.itensFase." + index + ".porcentagem"} parse={(v) => { updatePorPercent(row, index, v); return v }} />

                    },
                    {
                        name: "Área",
                        width: "30%",
                        cell: (row, index) => <TextInput type={'number'} name={"fasesCultura.itensFase." + index + ".area"} parse={(v) => { updatePorArea(row, index, v); return v }} />
                    },
                ]}
                data={itensFase || []}
                noDataComponent='Sem fases'
            />
            {/* 
            <table>
                <thead>
                    <tr>
                        <td >
                            Nome
                        </td>
                        <td >
                            Porc. (%)
                        </td>
                        <td >
                            Área
                        </td>
                    </tr>
                </thead>
                <tbody>

                    {(itensFase || []).map((item, index) => (
                        <NestedField key={index} prefix={"fasesCultura.itensFase"} index={index}>
                            <tr>
                                <td valign="bottom">
                                    {item.nome}
                                </td>
                                <td valign="bottom">
                                    <TextInput name="porcentagem" parse={(v) => { updatePorPercent(item, index, v); return v }} />
                                </td>
                                <td valign="bottom">
                                    <TextInput name="area" parse={(v) => { updatePorArea(item, index, v); return v }} />
                                </td>
                            </tr>
                        </NestedField>
                    ))}
                </tbody>
            </table> */}


        </FormContent>
    )
}
