import EntitySearchInput from 'components/form/EntitySearch/EntitySearchInput'
import AreaCultivada from 'entity/AreaCultivada'
import React from 'react'
import { AreaCultivadaFormInter } from '../AreaCultivadaForm'


export default function SeletorAreaCultivada({ name = "areaCultivada", label = "Área Cultivada", showAdd, ...outerProps }) {
    const extraQuery = (query) => {

        query = query.where("ativo", "==", true)

        return query
    }
    return (
        <EntitySearchInput
            name={name}
            label={label}
            EntityClass={AreaCultivada}
            extraQuery={extraQuery}
            addFields={["nomeProdutor"]}
            specificColumns={[{ field: "nomeArea", headerName: "Nome da Área", filter: true, mobileGridColumn: "2/ spam 2", },
            { field: "nomeProdutor", headerName: "Produtor", filter: true, mobileGridColumn: "1/ spam 2", },
            { field: "safra", headerName: "Safra", filter: true },
            { field: "cultura", headerName: "Cultura", filter: true },
            { field: "areaHa", headerName: "Área (HA)", },]}
            NewForm={showAdd ? AreaCultivadaFormInter : null}
            {...outerProps}
        />
    )
}
