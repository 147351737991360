import React, { useEffect } from 'react'
import { useAppContext } from '../../../app/AppContext'
import { useNavigate } from "react-router-dom";
import SignUpPage from './components/SignUpPage';

export default function RoutesMenusSignUp() {

    const navigate = useNavigate();
    const { addMenu, addRoute, removeMenusRoutes } = useAppContext();
    useEffect(() => {
        // addMenu({ uid: 'signUpPage', sequence: "111112", text: 'Criar Conta', iconName: 'PersonAdd', action: () => { navigate("/signup") } })
        addRoute({ uid: "signUpPage", title: "SignUp", route: "/signup", element: <SignUpPage /> })

        return () => { removeMenusRoutes('loginPage', 'signUpPage') }
    }, [addMenu, addRoute, navigate, removeMenusRoutes])

    return (<></>)
}
