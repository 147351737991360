import _ from "lodash";
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import InputBox from "./InputBox";
import { PField } from "./NestedField";
import { fieldHasError, requiredValidator } from "./validator";
import { Delete } from "@mui/icons-material";

export default function SelectInput({
    name,
    label,
    options,
    toStringOptions,
    initialValue,
    required,
    helperText,
    sx,
    children,
    permiteLimpar,
    ...props
}) {
    if (options) {
        children = [];
        for (let i = 0; i < options.length; i++) {
            const item = options[i];
            children.push(
                <MenuItem key={item} value={item}>
                    {toStringOptions ? toStringOptions[i] : item}
                </MenuItem>
            );
        }
    }

    const labelId = _.uniqueId(name);

    return (
        <InputBox sx={sx}>
            <PField name={name} initialValue={initialValue} validate={required ? requiredValidator : null}>
                {(fieldProps) => {
                    const hasError = fieldHasError(fieldProps.meta);

                    return (
                        <FormControl required={required} error={hasError} variant="standard" sx={{ flex: 1 }}>
                            <InputLabel id={labelId}>{label}</InputLabel>
                            <Select label={label} labelId={labelId} {...fieldProps.input}
                                endAdornment={permiteLimpar && <IconButton style={{ marginRight: 25 }} size="small" onClick={() => fieldProps.input.onChange(null)}><Delete /></IconButton>}
                                {...props}>
                                {children}
                            </Select>
                            <FormHelperText>{hasError ? fieldProps.meta.error : helperText}</FormHelperText>
                        </FormControl>
                    );
                }}
            </PField>
        </InputBox>
    );
}
