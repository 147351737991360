import { useTheme } from '@emotion/react'
import { Check, Edit, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import ExpandMoreIcon from "@mui/icons-material//ExpandMore"

export default function PainelPageMobile({ pageMobile, disabled = false, setPageMobile, icon, title, previous, next, children, sx, fieldset, funcStatus, ...othersProps }) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    let iconStatus = null;

    if (funcStatus) {

        if (funcStatus()) {
            iconStatus = <Check sx={{ mr: 2, color: '#008868', fontSize: "2rem" }} />
        } else {
            iconStatus = <Edit sx={{ mr: 2, color: '#f44336', fontSize: "2rem" }} />
        }


    }
    return (
        <>
            {
                mobile ? <>
                    {pageMobile === title &&
                        <Box sx={{ display: "flex", flexDirection: 'column' }}>
                            <Box sx={{ display: "flex", flex: 0, backgroundColor: theme.palette.secondary.dark, p: 1, alignItems: "center" }}>

                                {previous && <IconButton onClick={() => setPageMobile(previous)}><NavigateBefore sx={{ color: theme.palette.secondary.contrastText }} /></IconButton>}
                                <Typography sx={{ color: theme.palette.secondary.contrastText, flex: 1, }} variant='h6'>{title}</Typography>
                                {next && <IconButton onClick={() => setPageMobile(next)}><NavigateNext sx={{ color: theme.palette.secondary.contrastText }} /></IconButton>}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: 'column', flex: 1, m: 1, gap: 2, ...sx }} {...othersProps}>
                                {children}
                            </Box>
                        </Box>
                    }
                </>
                    :
                    <>
                        {fieldset ?
                            <fieldset style={{ marginTop: 5, marginBottom: 5 }}>
                                <legend>
                                    <Typography variant="subtitle2">{title}</Typography>
                                </legend>
                                <Box sx={{ display: "flex", flexDirection: 'column', flex: 1, m: 1, gap: 2, ...sx }} {...othersProps}>
                                    {children}
                                </Box>
                            </fieldset>
                            :
                            <Accordion disabled={disabled}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "#dddddd",
                                        alignItems: "center"
                                    }}
                                    expandIcon={<ExpandMoreIcon />}>
                                    {icon && <img src={icon} width="34px" alt={title} />}
                                    <Typography variant="subtitle2" sx={{ display: "flex", alignItems: "center", ml: 2, flex: 1, }} >{title}</Typography>
                                    {iconStatus}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: "flex", flexDirection: 'column', flex: 1, m: 1, gap: 2, ...sx }} {...othersProps}>
                                        {children}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </>
            }
        </>
    )
}
