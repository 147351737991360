import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

import Logo from './logo.png'

import './style.css';

export default function BemVindo() {
    const navigate = useNavigate();
    return (
        <div className='container-background'>
            <div className='container' style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1, maxWidth: "365px" }}>
                <img src={Logo} alt='Logotipo e-laudo' />
                <p className='title' style={{ fontSize: "35px", textAlign: 'center' }}>
                    Elabore laudos padronizados.
                </p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: "15px", width: "100%", padding: "0 35px" }}>
                    <Button color='secondary' onClick={() => navigate("/login")}> Fazer login</Button>
                    <Button color='primary' onClick={() => navigate("/signup")}> Criar uma conta</Button>
                </div>
            </div>
        </div>
    )
}
