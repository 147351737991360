import BasicRoute from "components/routes/BasicRoute";
import InsumoConsulta from "entity/Insumo/components/InsumoConsulta";
import InsumoForm from "entity/Insumo/components/InsumoForm";


export default function RoutesMenusInsumo() {
    return <>
        <BasicRoute
            uid='InsumoConsult'
            route="consulta-insumo"
            
            menuProps={{
                text: "Insumos",
                sequence: "300000",
                iconName: "ShoppingCart",
            }}
            element={<InsumoConsulta />}
        />
        <BasicRoute
            uid='insumoForm'
            route="insumo"
            // menuProps={{
            //     text: "Nova Reunião",
            //     iconName: "GroupAdd",
            //     sequence: "000000",
            // }}
            element={<InsumoForm />}
        />



    </>;
}