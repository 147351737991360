import React, { useEffect } from "react";
import { useAppContext } from "../../../app/AppContext";
import { useNavigate } from "react-router-dom";

export default function RoutesMenusSupport() {
    const navigate = useNavigate();
    const { addMenu, addRoute } = useAppContext();
    useEffect(() => {
        addMenu({
            uid: "support",
            sequence: "111119",
            text: "Suporte",
            iconName: "SupportAgent",
            action: () => {
                window.open(
                    "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20a%20aplicação.",
                    "_blank"
                );
            },
        });
    }, [addMenu, navigate, addRoute]);

    return <></>;
}
