import React, { useEffect } from 'react'

import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import AutocompleteInput from 'components/form/input/AutocompleteInput'
import CheckboxInput from 'components/form/input/CheckboxInput'
import DateInput from 'components/form/input/DateInput'
import TextInput from 'components/form/input/TextInput'
import SeletorInsumo from 'entity/Insumo/components/SeletorInsumo'

export default function ManejoForm(props) {
    const { talhoesLaudo } = props

    const { dose, area, quantidadeTotal, valorUnitario, index, vazao, volumeTanque } = props.values

    const { change } = props.form

    useEffect(() => {

        let total = 0
        if (dose && area) {
            total = parseFloat(dose) * parseFloat(area)
        }

        change("quantidadeTotal", total.toFixed(2))

    }, [dose, area, change])
    useEffect(() => {

        let total = 0
        if (quantidadeTotal && valorUnitario) {
            total = parseFloat(quantidadeTotal) * parseFloat(valorUnitario)
        }

        change("valorTotal", total.toFixed(2))

    }, [quantidadeTotal, valorUnitario, change])
    useEffect(() => {
        let value = 0
        if (dose && vazao && volumeTanque) {
            value = (parseFloat(volumeTanque) / parseFloat(vazao)) * parseFloat(dose)
        }
        change("dosePorTanque", value.toFixed(2))

    }, [dose, vazao, volumeTanque, change])
    const permiteVarios = index === undefined
    return (<FormContent sx={{ p: 1 }}>
        <FormRow>
            <SeletorInsumo
                name='mercadoria'
                label="Insumo"
                helperText={permiteVarios ? "Informe vários para um lançamento multiplo" : ""}
                multiple={permiteVarios}
                searchButtonDisabled={false}
                required
            />
        </FormRow>
        <FormRow noWrap>
            <AutocompleteInput
                required
                name="talhao" label="Talhão"
                options={talhoesLaudo}
                disabled={!talhoesLaudo ? true : false}
                getOptionLabel={(option) => option ? option.nome : ""}
                isOptionEqualToValue={(option, value) => option.nome === value.nome}
                onSelect={(v) => {
                    console.log('onSelect', v)
                    if (v) {

                        change("area", v.areaHa)
                    }

                }}
            />
            <TextInput sx={{ flex: 1 }} type="number" name="area" label="Área Aplicação" required />
        </FormRow>
        <FormRow noWrap>
            <TextInput name="dose" label="Dose" type="number" required />
            <TextInput name="quantidadeTotal" label="Quantidade Total" type="number" required disabled />
        </FormRow>
        <FormRow noWrap>
            <TextInput name="valorUnitario" label="Valor Unitário" type="number" />
            <TextInput name="valorTotal" label="Valor Total" type="number" disabled />
        </FormRow>

        <FormRow noWrap>
            <TextInput name="vazao" label="Vazão" type="number" />
            <TextInput name="dosePorTanque" label="Dose por Tanque" type="number" disabled />
        </FormRow>
        <FormRow noWrap>
            <TextInput name="volumeTanque" label="Volume Tanque (L)" type="number" />
            <DateInput
                name="dataPrevista"
                label="Data Prevista Realizar"
            />
        </FormRow>
        <FormRow noWrap>
            <CheckboxInput name="executado" label="Executado" />

        </FormRow>

        {/* <FormRow>
            <TextInput sx={{ flex: 1 }} name="obs" label="Observação" multiline />

        </FormRow> */}


    </FormContent>
    )
}
