import Entity from "entity";
import MembroEquipe from "entity/MembroEquipe";
import Perfil from "entity/Perfil";
import { setDoc } from "firebase/firestore";
import { generateUid } from "functions";

export default class Convite extends Entity {
    static get collectionUID() {
        return "Convite";
    }

    static async onQuery(collection) {
        let perfil = this.globalProps?.perfil
        if (!perfil) {
            throw new Error(
                "this.globalProps deve receber perfil como globalProps"
            );
        }

        collection = collection.where("emailConvidado", "==", perfil.email)
        return collection;
    }


    async initialize() { }



    static get labelField() {
        return "assunto";
    }

    static get searchColumns() {
        return [
            { field: "assunto", headerName: "Assunto", filter: true },
            { field: "emailConvidado", headerName: "Email", filter: true },
            { name: "Status", selector: (l) => l.aceito ? "Aceito" : "Pendente", },


        ];
    }


    static criaConviteEquipe(emailConvidado, nomeConvidado, perfilConvite, dadosConvite) {
        if (!emailConvidado) throw new Error("E-mail do convidado deve ser informado");
        if (!perfilConvite) throw new Error("Perfil do convite deve ser informado");
        emailConvidado = emailConvidado.toLowerCase()
        let _dadosConvite = {}
        if (dadosConvite) {
            _dadosConvite = dadosConvite
        }
        const convite = {
            uid: generateUid(),
            dataConvite: new Date(),
            tipo: "Equipe",
            assunto: "Equipe",
            descricao: "Convite para participar da Equipe",
            emailConvidado,
            nomeConvidado,
            quemConvidou: perfilConvite.uid,
            perfilConvidou: perfilConvite,
            aceito: null

        }
        let novoMembro = { uid: convite.uid, uidConvite: convite.uid, dataConvite: new Date(), statusConvite: "Pendente", email: emailConvidado, nome: nomeConvidado }
        let docRef = `User/${perfilConvite.uid}/Equipe`
        const refMembro = MembroEquipe.getRefDocMembro(docRef, novoMembro.uid)
        _dadosConvite.refMembro = refMembro

        convite.dadosConvite = _dadosConvite

        this.saveValuesEntity(convite)
        MembroEquipe.saveValuesEntity(novoMembro)
    }

    static aceitaConviteEquipe(uidConvite, perfil, dadosConvite) {
        if (!uidConvite) throw new Error("uidConvite deve ser informado");
        if (!perfil) throw new Error("perfilAceitou deve ser informado");
        let updateConvite = { uid: uidConvite, aceito: true, dataAceite: new Date() }
        updateConvite.uidConvidado = perfil.uid
        let DocRefMembro = dadosConvite.refMembro
        let updateMembro = { dataAceite: updateConvite.dataAceite, conviteAceito: true, statusConvite: "Aceito", nome: perfil.nome, userUid: perfil.uid }
        setDoc(DocRefMembro, updateMembro, { merge: true })
        this.saveValuesEntity(updateConvite)
        Perfil.saveValuesEntity({ uid: perfil.uid, contaInternaDe: dadosConvite.tokenConta })
    }

    static recusarConviteEquipe(uidConvite, perfil, dadosConvite) {
        if (!uidConvite) throw new Error("uidConvite deve ser informado");
        if (!perfil) throw new Error("perfilAceitou deve ser informado");
        let updateConvite = { uid: uidConvite, aceito: false }
        updateConvite.uidConvidado = perfil.uid

        let DocRefMembro = dadosConvite.refMembro
        let updateMembro = { conviteAceito: false, statusConvite: "Recusado", nome: perfil.nome, userUid: perfil.uid }
        setDoc(DocRefMembro, updateMembro, { merge: true })
        this.saveValuesEntity(updateConvite)
    }


}
