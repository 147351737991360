import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import FormContent from "components/form/FormContent";
import TextInput from "components/form/input/TextInput";
import { useEffect, useState } from "react";
import BaseDialog from "./BaseDialog";

export default function ConfirmDialog({ title, labelYes = "Sim", labelNo = "Não", requiredInput, children, ...props }) {
    const [confirmDisabled, setConfirmDisabled] = useState();
    const [requiredField, setRequiredField] = useState("");

    useEffect(() => {
        setConfirmDisabled(requiredInput && requiredField !== requiredInput);
    }, [requiredField, requiredInput]);

    function handleChange(input) {
        setRequiredField(input.target.value);
    }

    function onClose() {
        setRequiredField("");
    }

    return (
        <BaseDialog onClose={onClose} {...props}>
            {(dialogProps) => (
                <>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{children}</DialogContentText>

                        {requiredInput ? (
                            <>
                                <DialogContentText>
                                    Digite <strong>{requiredInput}</strong> para confirmar.
                                </DialogContentText>

                                <FormContent>
                                    <TextInput name="required" value={requiredField} onChange={handleChange} />
                                </FormContent>
                            </>
                        ) : null}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" onClick={() => dialogProps.onClose(false)} autoFocus>
                            {labelNo}
                        </Button>
                        <Button variant="text" onClick={() => dialogProps.onClose(true)} disabled={confirmDisabled}>
                            {labelYes}
                        </Button>
                    </DialogActions>
                </>
            )}
        </BaseDialog>
    );
}
