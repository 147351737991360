
import { Check, LaunchSharp } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material'
import OkTable from 'components/OkTable'
import ManejosTalhaoReport from 'entity/AreaCultivada/reports/ManejosTalhaoReport';
import LaudoVisita from 'entity/LaudoVisita';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInDebug, usePerfil } from 'useAuth';

export default function TabelaManejos({ manejos, areaCultivada, laudos }) {
    const IN_DEBUG = useInDebug()
    const perfil = usePerfil()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)


    const imprimirManejosTalhao = async () => {
        new ManejosTalhaoReport({ manejos, areaCultivada, perfil }).generate(IN_DEBUG);
    }

    const setExecuted = async (value) => {
        setIsLoading(true)
        let updateLaudo = null

        if (!value.executado || value.executado === false) {
            laudos.forEach((laudo) => {
                let manejosToUpdate = laudo.manejos.manejos.find(m => m.uid === value.uid)
                if (manejosToUpdate) {
                    manejosToUpdate.executado = true
                    updateLaudo = laudo
                }
            })
        } else {
            laudos.forEach((laudo) => {
                let manejosToUpdate = laudo.manejos.manejos.find(m => m.uid === value.uid)
                if (manejosToUpdate) {
                    manejosToUpdate.executado = false
                    updateLaudo = laudo
                }
            })
        }
        const laudoUpdate = new LaudoVisita()
        laudoUpdate.update(updateLaudo)
        await laudoUpdate.save()
        setIsLoading(false)
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flex: 1, gap: 1, p: 1, alignItems: 'center' }}>
                <Typography variant='h6' sx={{ flex: 1 }}>Manejos</Typography>
                <Button onClick={() => { imprimirManejosTalhao() }}>Imprimir</Button>
            </Box>
            <OkTable
                noDataComponent={"Sem manejos"}
                data={manejos}
                columns={[
                    { name: "Talhão", field: "talhao.nome", mobileGridColumn: "1 / span 2" },
                    { name: "Área", field: "area", mobileGridRow: '2', mobileGridColumn: "3", width: "100px", },
                    { name: "Insumo", field: "mercadoria.descricao", },
                    { name: "Dose", field: "dose", },
                    { name: "QT", field: "quantidadeTotal", width: "100px", },
                    {
                        name: "Data Prevista", field: "dataPrevista", width: "120px",
                        selector: (row) => {
                            return row.dataPrevista ? row.dataPrevista : "Sem Data"
                        }
                    },
                    {
                        name: "Status", width: "100px", selector: (row) => {
                            return row.executado ? "Executado" : "Pendente"
                        }
                    },
                    {
                        // name: "Marcar como realizado",
                        width: "65px",
                        mobileGridColumn: "3", mobileGridRow: '1',
                        cell: (row) => <IconButton disabled={isLoading} sx={{ fontSize: "9px" }} onClick={() => setExecuted(row)}><Check /></IconButton>
                    },
                    {
                        width: "65px",
                        mobileGridColumn: "3", mobileGridRow: '1',
                        cell: (row) => <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('#/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Manejos", '_blank')
                            } else {
                                navigate('/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Manejos")
                            }

                        }}><LaunchSharp /></IconButton>
                    }
                ]}
            />

        </Box>
    )
}
