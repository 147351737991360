import { useAppContext } from 'app/AppContext';
import React from 'react'
import {
    Routes,
    Route,
} from "react-router-dom";


export default function RoutesControl() {
    const { routes } = useAppContext()
    if (routes && routes.length) {

        return (
            <Routes>
                {routes.map((r, index) => <Route key={index} path={r.route} element={r.element} />)}
            </Routes>
        )
    } else {
        return <div>Loading Routes</div>
    }
}
