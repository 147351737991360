import BasicRoute from "components/routes/BasicRoute";
import BemVindo from "./components/BemVindo";

export default function RoutesMenusHome() {


    return <>
        <BasicRoute uid='publicHome' route="" menuProps={{
            text: "Início",
            sequence: "000000",
            iconName: "Home",
        }} element={<BemVindo />} />
    </>;
}