import { Dashboard, Edit } from '@mui/icons-material'
import { Button, Card, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function AreasAtivas({ areaCultivadas }) {
    const navigate = useNavigate()

    return (
        <Card sx={{ m: 2, p: 1, flex: "1 1 360px", maxHeight: 400, maxWidth: 400, overflow: 'auto' }}>
            <Typography variant='h6'>Áreas Ativas</Typography>
            {(areaCultivadas || []).map((a, i) => <div key={i} style={{ borderBottom: "1px solid #ccc", display: "flex",alignItems:"center" }}>
                <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
                    <IconButton onClick={(evt) => {
                        if (evt.ctrlKey) {
                            window.open('#/PainelArea?uidArea=' + a.uid, '_blank')
                        } else {
                            navigate('/PainelArea?uidArea=' + a.uid)
                        }

                    }}><Dashboard /></IconButton>
                    <IconButton onClick={(evt) => {
                        if (evt.ctrlKey) {
                            window.open('#/areaCultivada?uid=' + a.uid, '_blank')
                        } else {
                            navigate('/areaCultivada?uid=' + a.uid)
                        }

                    }}><Edit /></IconButton>
                </div>
                <div  style={{ flex:1 }}>
                    <Typography variant='subtitle2'>{a.nomeArea}</Typography>
                    <div style={{ display:"flex", flexWrap:'wrap' }}>
                        <Typography variant='caption'>{a.nomeProdutor} - </Typography>
                        <Typography variant='caption'>{a.safra}-{a.cultura}</Typography>
                    </div>
                </div> 
                <div  style={{ marginLeft:5}}>
                    <Button onClick={(evt) => {
                        if (evt.ctrlKey) {
                            window.open('/laudoVisita?uidArea=' + a.uid, '_blank')
                        } else {
                            navigate('/laudoVisita?uidArea=' + a.uid)
                        }

                    }}>Novo</Button>
                </div>
            </div>)}
        </Card>
    )
}
