import { toBase64 } from "./file";

/**
 * Se possível, converte o parâmetro em um objeto Image.
 */
export async function toImage(source) {
    if (source instanceof Image) {
        return source;
    } else {
        const image = new Image();

        if (source instanceof File) {
            image.src = await toBase64(source);
        } else {
            if (source.startsWith) {
                if (source.startsWith("data:image")) {
                    image.src = source;
                } else {

                    image.src = "data:image/jpeg;base64," + source;
                }
            } else {
                image.src = source;

            }
        }

        return image;
    }
}

/**
 * Redimensiona tamanho da imagem, mantendo proporções.
 */
export async function scaleImage(source, maxWidth, maxHeight, format = "image/jpeg", quality = 0.7) {


    const image = await toImage(source);
    return new Promise(resolve => {
        const canvas = document.createElement("canvas");
        image.onload = function () {
            let width = image.width || 300;
            let height = image.height || 300;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }

            canvas.width = width;
            console.log('width', width)
            canvas.height = height;
            console.log('height', height)

            const context = canvas.getContext("2d");
          //  console.log('context', context)
            context.drawImage(image, 0, 0, width, height);


            let rr = canvas.toDataURL(format, quality);
            console.log('rr22', rr)
            resolve(rr)
        }
    }) 

}


/**
 * Rotaciona imagem. Suporta apenas rotações múltiplas de 90°.
 */
export async function rotateImage(source, degrees, format = "image/jpeg", quality = 0.7) {
    // console.log('source', source)
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = await toImage(source);

    if (degrees % 180 === 0) {
        canvas.width = image.width;
        canvas.height = image.height;
    } else if (degrees % 90 === 0) {
        canvas.width = image.height;
        canvas.height = image.width;
    } else {
        throw new Error("Ângulo de rotação da imagem deve ser múltiplo de 90°.");
    }

    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((degrees * Math.PI) / 180);
    context.drawImage(image, -image.width / 2, -image.height / 2);

    let rr = canvas.toDataURL(format, quality);
    console.log('rr', rr)
    return rr
}
