import FormRow from 'components/form/FormRow'
import AutocompleteInput from 'components/form/input/AutocompleteInput'
import ImageInput from 'components/form/input/ImageInput'
import { useFieldValue } from 'components/form/input/NestedField'
import TextInput from 'components/form/input/TextInput'
import React from 'react'

export default function PainelFotos() {
    
    const [deleteFotos, setDeleteFotos] = useFieldValue('deleteFotos')
    const [talhoesLaudo] = useFieldValue('talhoesLaudo')
    // console.log('fotos', fotos)
    function handleRemoveImage(image) {
        console.log('image', image)
        if (image.uid) {
            let ar = deleteFotos ? deleteFotos.slice() : []
            ar.push(image);
            console.log('ar',ar)
            setDeleteFotos(ar)
            // visita.deleteImage(image.imagem);
        }
    }
    return (
        <FormRow>
            <ImageInput name={'fotos'} label={'Fotos'} imageField="foto" geolocationField="pontoGeo" multiple onRemove={handleRemoveImage}>
                <FormRow>
                    <AutocompleteInput
                        name="talhao" label="Talhão"
                        options={talhoesLaudo}
                        disabled={!talhoesLaudo ? true : false}
                        getOptionLabel={(option) => option ? option.nome : ""}
                        isOptionEqualToValue={(option, value) => option.nome === value.nome}
                    />
                </FormRow>
                <FormRow>
                    <TextInput name="descricao" label="Descrição" multiline rows={4} />
                </FormRow>
            </ImageInput>

        </FormRow>
    )
}
