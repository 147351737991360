import BasicRoute from "components/routes/BasicRoute";
import PoliticaPrivacidade from "./components/Politicas";
import Termos from "./components/Termos";

export default function RoutesMenusPoliticas() {
    return <>
        <BasicRoute uid='privacidade' route="privacidade" menuProps={{
            text: "Política de Privacidade",
            sequence: "999999",
            iconName: "Security",
        }} element={(<><Termos /><PoliticaPrivacidade /></>)} />
    </>;
}