import { Card, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    ResponsiveContainer,
    PieChart, Pie, Legend, Tooltip, Cell
} from 'recharts';


const RADIAN = Math.PI / 180;
const colors = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042'
]
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <Typography x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </Typography>
    );
};
const CustomTooltip = (props) => {

    const { active } = props;

    if (active) {
        // console.log('props', props);

        const { payload } = props;
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].name} : ${payload[0].value} HA`}</p>

            </div>
        );
    }

    return null;
}
export default function GraficoAreasCultura({ areaCultivadas }) {
    const [totalSafra, setTotalSafra] = useState([])
    const [totalAreaGeral, setTotalAreaGeral] = useState(0)
    useEffect(() => {

        if (areaCultivadas) {
            let data = []
            let dataAux = []
            let totalAreaGeral = null;
            let totalArea = 0;
            if (areaCultivadas) {
                areaCultivadas.forEach(area => {
                    if (!area.cultura) {
                        return
                    }
                    if (area.ativo !== undefined && area.ativo === false) {
                        return;
                    }
                    if (isNaN(area.areaHa)) {
                        return;
                    }
                    let nome = area.cultura.toUpperCase() + " - " + area.safra.toUpperCase();
                    let v = dataAux[nome];
                    if (!v) {
                        v = 0;
                    }
                    let ha = parseFloat(area.areaHa);
                    v = v + ha;

                    totalArea = totalArea + ha;
                    totalAreaGeral = totalAreaGeral + ha;
                    dataAux[nome] = v;
                });

                let keys = Object.keys(dataAux);

                data = keys.map(nome => { return { name: nome, value: dataAux[nome], totalArea } })
            }
            setTotalSafra(data)
            setTotalAreaGeral((totalAreaGeral || 0).toFixed(2))

        }

    }, [areaCultivadas])
    const renderLegend = (props) => {
        const { payload } = props;

        return (<div >
            {payload.length > 6 ? "Muitas Safras" :
                <div >
                    {payload.map((entry, index) => (
                        <div key={index}>
                            <Typography style={{ color: entry.color }}>
                                {`${entry.payload.name}: ${entry.payload.value.toFixed(2)} HA`}
                            </Typography></div>
                    ))}
                </div>
            }
        </div>
        );
    }

    return (
        <Card sx={{ m: 2, p: 1, flex: "1 1 325px", maxWidth: 400 }}>
            <Typography sx={{ textAlign: "center" }} variant='h6'>Área assistida: {totalAreaGeral}</Typography>


            <ResponsiveContainer height={280} >
                <PieChart width={270} height={280}>
                    <Pie
                        data={totalSafra}
                        dataKey="value"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        innerRadius={40} outerRadius={80} fill="#82ca9d" >{
                            totalSafra.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]} />)}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend layout='vertical' content={renderLegend} />
                </PieChart>
            </ResponsiveContainer>
        </Card>
    )
}
