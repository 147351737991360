import { Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import FormDialog from 'components/dialog/FormDialog'
import { PField, useFieldValue } from 'components/form/input/NestedField'
import { fieldHasError, requiredValidator } from 'components/form/input/validator'
import OkTable from 'components/OkTable'
import KmlImport from 'entity/AreaCultivada/components/KmlImport'
import { generateUid } from 'functions'
import React, { useState } from 'react'
import PainelTalhaoForm from '../PainelTalhaoForm'
import "./style.css"
export default function TabelaTalhoes({ areaCultivada }) {

    const [talhoes, setTalhoes] = useFieldValue("talhoes")
    
    const [talhaoEdit, setTalhaoEdit] = useState(null)

    // evita NaN quando importa um talhão sem área calculada
    if (talhoes) {
        talhoes.forEach((talhao) => {
            if(!talhao.areaHa) {
                talhao.areaHa = 0
            }
        })
    }

    const updateTalhao = (talhao) => {
        let newArray = (talhoes || []).slice();
        let indexOf = talhao.index;
        if (indexOf === undefined) {
            talhao.index = 0
            newArray.push(talhao)
        } else {
            newArray[indexOf] = talhao;
        }
        setTalhoes(newArray)
    }
    const deleteTalhao = (talhao) => {
        if (talhao.index !== undefined) {
            let newArray = (talhoes || []).slice();
            newArray.splice(talhao.index, 1)
            setTalhoes(newArray)
        }
    }

    const startNew = () => {

        let t = { uid: generateUid() }
        let indexAux = 1;
        while ((talhoes || []).map(t => t.nome).includes("TH" + indexAux)) {
            indexAux++
        }
        t.nome = "TH" + indexAux
        setTalhaoEdit(t)
    }
    return (
        <PField
            name={"talhoes"}
            validate={requiredValidator}
        >
            {(fieldProps) => {
                const hasError = fieldHasError(fieldProps.meta);
                return <Box sx={{ flex: 1 }}>
                    <div style={{ display: "flex", padding: 10, gap: 5, alignItems: "center" }}>
                        <Typography variant='h6' style={{ flex: 1 }}>
                            Talhões
                        </Typography>
                        {hasError && <Typography sx={{ color: '#f00' }}>{fieldProps.meta.error}</Typography>}
                        <KmlImport talhoes={talhoes} addTalhoes={t => setTalhoes(old => (old || []).concat(t))} />
                        <Button onClick={startNew} >Adicionar Talhão</Button>

                    </div >
                    <OkTable
                        noWrapMobile
                        columns={[
                            { name: "Nome", field: "nome" },
                            { name: "Área " + areaCultivada.unidadeArea, field: "areaHa" },
                            {
                                cell: (t, i) => <div style={{ display: "flex" }}>
                                    <IconButton onClick={() => setTalhaoEdit({ ...t, index: i })}>
                                        <Edit />
                                    </IconButton>

                                    <IconButton onClick={() => deleteTalhao({ ...t, index: i })}>
                                        <Delete />
                                    </IconButton>
                                </div>,
                                width: "110px"
                            },
                        ]}
                        data={talhoes}
                    />

                    {talhaoEdit && <FormDialog
                        height='90%'
                        title={"Talhão"}
                        onClose={() => {
                            setTalhaoEdit(undefined)
                        }}
                        open={talhaoEdit ? true : false}
                        value={talhaoEdit}
                        validValues={async (v) => {
                            let errorsMessage = []

                            for (let index = 0; index < talhoes.length; index++) {
                                const t = talhoes[index];
                                if (v.index !== index) {
                                    if (v.nome === t.nome) {
                                        errorsMessage.push("Nome de talhão já utilizado")
                                    }
                                }
                            }
                            if (errorsMessage.length) {
                                return errorsMessage
                            }

                            return null
                        }}
                        updateValue={values => {

                            updateTalhao(values);
                            setTalhaoEdit(undefined)
                        }}
                    >
                        {(formProps) => <PainelTalhaoForm areaCultivada={areaCultivada} {...formProps} />}
                    </FormDialog>}

                </Box >
            }}</PField>
    )
}
