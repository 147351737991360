import { useAppContext } from "app/AppContext";
import PerfilForm from "entity/Perfil/components/PerfilForm";
import PerfilConsulta from "entity/Perfil/components/PerfilConsulta";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from 'useAuth'
import BasicRoute from "components/routes/BasicRoute";
import MeuPerfilForm from "entity/Perfil/components/MeuPerfilForm";

export default function RoutesMenusPerfil() {
    const navigate = useNavigate();
    const { addMenu, addRoute, removeMenusRoutes } = useAppContext();

    const isAdmin = useIsAdmin();

    useEffect(() => {
        if (isAdmin) {
            addRoute({ uid: "perfil", title: "Perfil", route: "perfil", element: <PerfilForm /> });

            addMenu({
                uid: "consultaPerfil",
                text: "Perfis",
                sequence: "900000",
                iconName: "People",
                action: () => {
                    navigate("/consulta-perfil");
                },
            });

            addRoute({ uid: "consultaPerfil", title: "Perfis", route: "consulta-perfil", element: <PerfilConsulta /> });
        }

        return () => {
            removeMenusRoutes(["perfil", "consultaPerfil"]);
        };
    }, [addMenu, addRoute, isAdmin, navigate, removeMenusRoutes]);

    return <>

        <BasicRoute
            uid='meuPerfil'
            route="meuperfil"
            menuProps={{
                text: "Meu Perfil",
                iconName: "Person",
                sequence: "999998",
            }}
            element={<MeuPerfilForm />}
        />
    </>;
}
