import _ from "lodash";
import { includesInsensitive } from "util/string";

/**
 * Filtra a lista a partir do dicionário de filtros especificado.
 * Deve satisfazer todos os filtros simultaneamente.
 *
 * 'filters' é uma lista de objetos no formato:
 * {
 *     value: String,
 *     databaseFilter: Boolean,
 *     doFilter: function
 * }
 */
export function filterList(list, filters) {
    if (_.isEmpty(filters)) {
        return list;
    }

    return list.filter((item) => {
        for (const key in filters) {
            const fieldValue = _.get(item, key);

            if (!filters[key].databaseFilter && !includesInsensitive(fieldValue, filters[key].value)) {
                return false;
            }
        }

        return true;
    });
}

/**
 * Filtra a lista a partir do dicionário de filtros especificado.
 * Deve satisfazer pelo menos um dos filtros.
 *
 * 'filters' é uma lista de objetos no formato:
 * {
 *     value: String,
 *     databaseFilter: Boolean,
 *     doFilter: function
 * }
 */
export function filterListAny(list, filters) {
    if (_.isEmpty(filters)) {
        return list;
    }

    return list.filter((item) => {
        for (const key in filters) {
            const fieldValue = _.get(item, key);

            if (!filters[key].databaseFilter && includesInsensitive(fieldValue, filters[key].value)) {
                return true;
            }
        }

        return false;
    });
}

/**
 * TODO: comentar
 * @param {*} collection
 * @param {*} filters
 * @returns
 */
export function filterCollection(collection, filters) {
    for (const key in filters) {
        const filter = filters[key];

        if (filter.databaseFilter) {
            if (filter.doFilter) {
                collection = filter.doFilter(collection, filter);
            } else {
                collection = collection.where(key, "==", filter.value);
            }
        }
    }

    return collection;
}
