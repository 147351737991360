import React, { useState } from 'react'

import List from '@mui/material/List';

import Divider from '@mui/material/Divider';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppContext } from 'app/AppContext';
import ExpandLess from '@mui/icons-material//ExpandLess';
import ExpandMore from '@mui/icons-material//ExpandMore';

import Help from '@mui/icons-material/Help';
import * as Icons from '@mui/icons-material/';
import { Box, Collapse, Icon, IconButton, Typography, useTheme } from '@mui/material';

import "./style.css"

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { usePerfil } from 'useAuth';
import SwitchDB from './components/SwitchDB';

const ItemMenu = ({ menu, handleDrawerClose, colorInvert }) => {

    let classesNameUsar = colorInvert ? "menuSecundario" : "menuPrimario"
    const [expanded, setExpanded] = useState(false)
    const expandAction = () => {
        setExpanded(!expanded)
    }

    const actionClick = () => {
        if (menu.action) {
            menu.action();
            if (handleDrawerClose) handleDrawerClose()
        }

    }
    let iconeAux = ""
    let iconeUsar = <Help className={classesNameUsar} style={{ backgroundColor: 'unset' }} />;

    if (menu.icone) {
        let Teste = menu.icone
        iconeUsar = <Teste className={classesNameUsar} style={{ backgroundColor: 'unset' }} />;
    }
    if (menu.iconeStr) {
        const RecIcon = Icons[menu.iconeStr]
        iconeUsar = <RecIcon className={classesNameUsar} style={{ backgroundColor: 'unset' }} />;
    }
    if (menu.iconName) {
        const RecIcon = Icons[menu.iconName]
        iconeUsar = <RecIcon className={classesNameUsar} style={{ backgroundColor: 'unset' }} />;
    }

    if (menu.iconFile) {
        iconeUsar = (<Icon >
            <img src={menu.iconFile} style={{ width: 34, padding: 3 }} alt="iconM" />
        </Icon>)
        // iconeUsar = <RecIcon className={classesNameUsar} />;
    }


    if (menu.children) {
        return <>
            <ListItem button onClick={expandAction} className={classesNameUsar}>
                <ListItemIcon>
                    {iconeUsar}
                </ListItemIcon>


                <ListItemText
                    disableTypography
                    primary={<Typography><span>{menu.text} {iconeAux}</span></Typography>}
                />
                {menu.children && <>{expanded ? <ExpandLess className={classesNameUsar} /> : <ExpandMore className={classesNameUsar} />}</>}
            </ListItem>
            {menu.children &&
                <Collapse in={expanded} timeout="auto" unmountOnExit className={'menuSecundario'}>
                    {menu.children.map((m, i) => <ItemMenu key={"submenu" + m.text + i} menu={m} colorInvert={!colorInvert} handleDrawerClose={handleDrawerClose} />)}
                </Collapse>
            }
            {menu.divider && <Divider />}
        </>
    }
    return <>
        <ListItem button onClick={actionClick} className={classesNameUsar}>
            <ListItemIcon>
                {iconeUsar}
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={<Typography  ><span>{menu.text} {iconeAux}</span></Typography>}
            />
        </ListItem>
        {menu.divider && <Divider />}
    </>
}
export default function MenuControl({ handleDrawerClose, toggleTheme }) {
    const { menus } = useAppContext()
    const theme = useTheme();
    const perfil = usePerfil();

    return (
        <>
            <Divider />
            <List className={'menuPrimario'}>
                {menus && <>
                    {menus.map((menu, index) => <ItemMenu key={index} menu={menu} handleDrawerClose={handleDrawerClose} />)}
                </>}
            </List>
            <Divider />
            {perfil !== null ?
                <Box
                    sx={{
                        display: "flex",
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <SwitchDB closeMenu={handleDrawerClose} />
                </Box>
                :
                null
            }

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: theme.palette.primary.light,
                    // bgcolor: 'background.default',
                    color: 'text.primary',
                    // borderRadius: 1,
                    // p: 3,
                }}
            >
                {/* {theme.palette.mode} mode */}
                <IconButton sx={{ ml: 1, color: "#fff" }} onClick={toggleTheme} color="inherit">
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
            </Box>
            {/* {perfil &&
                <SwitchDB closeMenu={handleDrawerClose} />
            } */}
            <Typography sx={{ alignSelf: "center", fontSize: 14, color: "#fff" }} >{perfil?.email}</Typography>
            <Typography sx={{ alignSelf: "center", fontSize: 14, color: "#fff" }}>v.{process.env.REACT_APP_VERSION}</Typography>
        </>
    )
}
