import React, { useEffect, useState } from 'react'

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';

import FormRow from 'components/form/FormRow';
import TextInput from 'components/form/input/TextInput';

import LaudoVisita from 'entity/LaudoVisita';
import { useReloadEntityField, useUrlQueryUid2Field } from 'components/form';
import AreaCultivada from 'entity/AreaCultivada';
import SeletorAreaCultivada from 'entity/AreaCultivada/components/SeletorAreaCultivada';
import AutocompleteInput from 'components/form/input/AutocompleteInput';
import { useInDebug, usePerfil } from 'useAuth';
import { useCoordinates } from 'components/form/input/GeolocationButton';
import PainelPageMobile from './components/util/PainelPageMobile';
import DateInput from 'components/form/input/DateInput';
import PainelFases from './components/PainelFases';
import PainelFotos from './components/PainelFotos';
import { Button } from '@mui/material';
import ContainerPainelMobile from './components/util/ContainerPainelMobile';

import IconInfolavoura from "./images/iconsMenu/infolavoura.png"
import IconFaseCultura from "./images/iconsMenu/fase_cultura.png"
import IconFotos from "./images/iconsMenu/fotos.png"
import IconOcorrencia from "./images/iconsMenu/ocorrencia.png"
import IconControlePragas from "./images/iconsMenu/controle_pragas.png"

import IconPlantios from "./images/iconsMenu/plantio.png"
import IconColheitas from "./images/iconsMenu/colheita.png"

import IconParecer from "./images/iconsMenu/parecer.png"

import IconManejos from "./images/iconsMenu/manejos.png"

import _ from 'lodash'
import PainelManejos from './components/PainelManejos';
import LaudoVisitaReport from 'entity/LaudoVisita/reports/LaudoVisitaReport';
import PainelOcorrencia from './components/PainelOcorrencia';
import PainelPlantio from './components/PainelPlantio';
import PainelColheita from './components/PainelColheita';
import Cultura from 'entity/Cultura';
import { fetchArray } from 'entity';
import Perfil from 'entity/Perfil';
import { useSearchParams } from 'react-router-dom';
import CheckboxInput from 'components/form/input/CheckboxInput';
function LaudoVisitaFormInter(props) {
    const IN_DEBUG = useInDebug()

    const [pageMobile, setPageMobile] = useState("Área Cultivada")

    const { values } = props
    const { areaCultivadaUid, areaCultivada, talhoesLaudo, userUid, pontoGeoInicial, cancelado } = values
    // console.log('LaudoVisitaFormInter', values)
    const perfil = usePerfil()

    const [searchParams] = useSearchParams();
    const tabMobile = searchParams.get("tabMobile")
    const searchTalhao = searchParams.get("talhao")

    useEffect(() => {
        if (tabMobile) {
            setPageMobile(tabMobile)
        }
        return () => {
            setPageMobile("Área Cultivada")
        }

    }, [tabMobile])


    useReloadEntityField(AreaCultivada, "areaCultivada", props)
    const [localCoordinates] = useCoordinates();

    useEffect(() => {
        if (!pontoGeoInicial && localCoordinates) {
            console.log('localCoordinates', localCoordinates)
            props.form.change("pontoGeoInicial", localCoordinates)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localCoordinates, pontoGeoInicial])

    useEffect(() => {
        if (areaCultivadaUid && !areaCultivada) {
            props.form.change("areaCultivada", { uid: areaCultivadaUid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaCultivadaUid, areaCultivada])

    useEffect(() => {
        if (perfil && (!userUid || userUid === perfil.uid)) {
            if (!userUid) {
                props.form.change("userUid", perfil.uid)
            }
            props.form.change("userNome", perfil.nome)
            props.form.change("userEmail", perfil.email)
            props.form.change("userCrea", perfil.crea)

            props.form.change("perfilDados", Perfil.getTokenConta(perfil))
            props.form.change("userEmpresa", perfil.empresa)
        }


    }, [perfil, props.form, userUid])

    const selectAreaCultivada = area => {
        if (area) {

            if (!talhoesLaudo || !talhoesLaudo.length) {
                if (searchTalhao) {
                    area.talhoes.forEach(t => {
                        if (t.nome === searchTalhao) {

                            props.form.change("talhoesLaudo", [t])
                        }
                    })

                } else {

                    props.form.change("talhoesLaudo", area.talhoes)
                }
            }
            props.form.change("areaCultivadaUid", area.uid)
            props.form.change("areaTotalAreaCultivadas", parseFloat(area.areaHa))
            let keys = {}
            keys[area.uid] = true;
            props.form.change("areasCultivadasKeys", keys)
            props.form.change("areasCultivadasListString", [area.nomeArea])
            props.form.change("cultura", area.cultura)
            props.form.change("safra", area.safra)
            props.form.change("nomesCulturas", area.nomeArea)
            props.form.change("stringProdutores", area.nomeProdutor)

            loadDadosEvolucao(area)
        } else {
            props.form.change("talhoesLaudo", [])
            props.form.change("areaCultivadaUid", null)

        }
    }

    useUrlQueryUid2Field('uidArea', 'areaCultivada', AreaCultivada, props.form, selectAreaCultivada)
    const loadDadosEvolucao = (area) => {
        props.form.change("fasesCultura", area.fasesCultura)
        props.form.change("porcentagemColhida", area.porcentagemColhida)
        props.form.change("porcentagemPlantada", area.porcentagemPlantada)
        props.form.change("dataPrevistaColheita", area.dataPrevistaColheita)
        props.form.change("expectativaProdutividade", area.expectativaProdutividade)
        props.form.change("plantios", area.plantios || [])

        // props.form.change("fasesCultura", area.fasesCultura)
        // area.cultura
        if (area.fasesCultura) {
            props.form.change("fasesCultura", area.fasesCultura)
        } else {

            Cultura.query().then(query => {
                query = query.where("nome", "==", area.cultura)
                fetchArray(query.get()).then(result => {
                    if (result.length) {
                        let fase = result[0]
                        // console.log('result fase', fase)
                        if (fase.fases) {
                            props.form.change("fasesCultura.itensFase", fase.fases.map(f => { return { nome: f } }))
                        }
                    } else {
                        // console.log('sem Cultura', area.cultura)
                        props.form.change("fasesCultura", Cultura.getFasesPadrao)
                    }


                })
            })

        }
    }


    useEffect(() => {

        if (talhoesLaudo) {
            let soma = 0
            let str = ''
            talhoesLaudo.forEach(t => {
                if (t.areaHa) {
                    soma += parseFloat(t.areaHa)
                }
                str += t.nome + ";"
            });

            // stringLotes
            props.form.change("stringLotes", str)
            props.form.change("areaTotalLaudo", soma.toFixed(2))
        }

    }, [props.form, talhoesLaudo])


    async function salvarImprimir() {
        props.form.submit().then((v) => {
            new LaudoVisitaReport({ visita: values, perfil, areaCultivada: areaCultivada }).generate(IN_DEBUG);
        })
    }

    return <ContainerPainelMobile indexMenu={1} pageMobile={pageMobile} setPageMobile={setPageMobile} values={values} disabled={!areaCultivada && true}>
        <PainelPageMobile title="Área Cultivada" fieldset
            funcStatus={() => {
                if (areaCultivada && talhoesLaudo && talhoesLaudo.length) {
                    return true
                } else {
                    return false
                }
            }}>
            <FormRow noWrap>
                <TextInput name="sequencialIndex" label="Nº" sx={{ flex: 1 }} disabled />
                <TextInput name="uid" label="Identificador" sx={{ flex: 2 }} disabled />
            </FormRow>
            <FormRow>
                <SeletorAreaCultivada required onSelectItem={selectAreaCultivada} showAdd disabled={areaCultivada ? true : false} />
                <AutocompleteInput
                    multiple
                    name="talhoesLaudo"
                    label="Talhões do Laudo"


                    options={areaCultivada?.talhoes || []}
                    getOptionLabel={(option) => option ? option.nome : ""}
                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                    required
                />
            </FormRow>
            <FormRow>
                <CheckboxInput label="Cancelado" checked={cancelado} name="cancelado" />
            </FormRow>
        </PainelPageMobile >
        <PainelPageMobile title="Informações da lavoura" icon={IconInfolavoura} disabled={!areaCultivada && true}
            funcStatus={() => {
                if (values.porcentagemPlantada || values.porcentagemColhida || values.dataPrevisaoColheita || values.expectativaProdutividade) {
                    return true
                } else {
                    return false
                }
            }}
        >
            <FormRow noWrap>
                <TextInput name="porcentagemPlantada" label="% Plantado" type='number' />
                <TextInput name="porcentagemColhida" label="% Colhido" type='number' />
            </FormRow>

            <FormRow>
                <DateInput
                    name="dataPrevisaoColheita"
                    label="Data Prevista Colheita"
                />
            </FormRow>
            <FormRow>
                <TextInput name="expectativaProdutividade" label="Estimativa de Produtividade" type='number' />
            </FormRow>
        </PainelPageMobile >
        <PainelPageMobile title="Fases da Cultura" icon={IconFaseCultura} disabled={!areaCultivada && true}
            funcStatus={() => {

                if (values?.fasesCultura?.itensFase) {
                    for (let index = 0; index < values.fasesCultura.itensFase.length; index++) {
                        const item = values.fasesCultura.itensFase[index];
                        if (item.porcentagem || item.area) {
                            return true
                        }
                    }

                } else {
                    return false
                }
            }}>
            <PainelFases areaCultivada={areaCultivada} />
        </PainelPageMobile >
        <PainelPageMobile title="Manejos" icon={IconManejos} disabled={!areaCultivada && true}
            funcStatus={() => {
                if (_.get(values, "manejos.manejos.length")) {
                    return true

                } else {
                    return false
                }
            }}>
            <PainelManejos areaCultivada={areaCultivada} />
        </PainelPageMobile>
        <PainelPageMobile title="Ocorrência" icon={IconOcorrencia} disabled={!areaCultivada && true} //notFastButton
            funcStatus={() => {
                if (_.get(values, "ocorrencia.nome") && _.get(values, "ocorrencia.tipo") &&
                    _.get(values, "ocorrencia.nivel")) {
                    return true
                } else {
                    return false
                }
            }}
        >
            <PainelOcorrencia areaCultivada={areaCultivada} />
        </PainelPageMobile>
        <PainelPageMobile title="Controle de Pragas" icon={IconControlePragas} disabled={!areaCultivada && true}
            funcStatus={() => {
                if (_.get(values, "questionario.manejoPlantasDaninhas") || _.get(values, "questionario.manejoPragas") ||
                    _.get(values, "questionario.manejoDoencas") || _.get(values, "questionario.outrasInformacoes")) {
                    return true
                } else {
                    return false
                }
            }}
        >
            <FormRow noWrap>
                <TextInput name="questionario.manejoPlantasDaninhas" label="Manejo de Plantas Daninhas" multiline />
                <TextInput name="questionario.manejoPragas" label="Manejo de Pragas" multiline />
            </FormRow>

            <FormRow>
                <TextInput name="questionario.manejoDoencas" label="Manejo de Doenças" multiline />
                <TextInput name="questionario.outrasInformacoes" label="Outras Informações" multiline />
            </FormRow>

        </PainelPageMobile>
        <PainelPageMobile title="Fotos" icon={IconFotos} disabled={!areaCultivada && true}
            funcStatus={() => {
                if (values?.fotos && values?.fotos.length) {
                    return true

                } else {
                    return false
                }
            }}>
            <PainelFotos areaCultivada={areaCultivada} />
        </PainelPageMobile>
        <PainelPageMobile title="Plantio" icon={IconPlantios} disabled={!areaCultivada && true} //notFastButton
            funcStatus={() => {
                let plantios = values.plantios
                if (plantios && plantios.length) {
                    for (let index = 0; index < plantios.length; index++) {
                        const item = plantios[index];



                        if (!(item && item.talhao && item.area && item.cultivar && item.dataEmergencia && item.dataFimPlantio && item.dataInicioPlantio && item.plantasMetro && item.sementeMetro))
                            return false

                    };
                }

                return true


            }}>
            <PainelPlantio areaCultivada={areaCultivada} />
        </PainelPageMobile>
        <PainelPageMobile title="Colheita" icon={IconColheitas} disabled={!areaCultivada && true} //notFastButton
            funcStatus={() => {
                let plantios = values.plantios
                if (plantios && plantios.length) {
                    for (let index = 0; index < plantios.length; index++) {
                        const item = plantios[index];

                        if (!(item && item.colheita && item.colheita.dataInicioColheita && item.colheita.dataFimColheita && item.colheita.producao))
                            return false
                    };
                }

                return true
            }}>
            <PainelColheita areaCultivada={areaCultivada} />
        </PainelPageMobile>

        <PainelPageMobile title="Parecer" icon={IconParecer} fieldset disabled={!areaCultivada && true}
            funcStatus={() => {
                if (values?.parecerExterno) {
                    return true

                } else {
                    return false
                }
            }}
        >
            <FormRow>
                <TextInput name="parecerExterno" label="Parecer" multiline rows={4} />
            </FormRow>
            <FormRow>
                <DateInput
                    name="dataProxVisita"
                    label="Próxima Visita"
                />
            </FormRow>
            <FormRow>
                <TextInput name="notaProxVisita" label="Nota Interna" multiline rows={4} />
            </FormRow>
            <Button onClick={salvarImprimir}>Salvar e Imprimir</Button>

        </PainelPageMobile>
    </ContainerPainelMobile >
}

export default function LaudoVisitaForm() {
    return (
        <DefaultEntityCrud
            EntityClass={LaudoVisita}
            title={"Laudo de Visita"}
            routeForm="laudoVisita"
            backRoute="consulta-laudoVisita"
            formActions={{ novoForm: false, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <LaudoVisitaFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}