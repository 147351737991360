import { Link } from "react-router-dom";

import ImgLogo from "./logo.png";

import firebaseUtil from "firebaseUtil";
import useAuth, { useNotAuthenticatedOnly } from "useAuth";

import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { Form, Field } from "react-final-form";
import { Button, CircularProgress, FormControlLabel, FormGroup, Switch, TextField, Typography } from "@mui/material";
// import GoogleAuth from "routes/public/components/GoogleAuth";
// import AppleAuth from "routes/public/components/AppleAuth";

const renderTextField = ({ input, label, meta: { touched, error }, valid, createUser, handleSubmit, ...custom }) => (
    <TextField
        variant="standard"
        InputLabelProps={{
            shrink: true,
        }}
        style={{ marginBottom: 15 }}
        label={label}
        error={touched && error ? true : false}
        helperText={touched && error ? error : ""}
        onKeyDown={(e) => {
            if (e.keyCode === 13 && valid) {
                handleSubmit(createUser)();
            }
        }}
        fullWidth
        {...input}
        {...custom}
    />
);

const renderCheckboxField = ({
    input,
    label,
    meta: { touched, error },
    valid,
    createUser,
    handleSubmit,
    ...custom
}) => (
    <FormGroup>
        <FormControlLabel
            width="100%"
            InputLabelProps={{
                shrink: true,
            }}
            style={{ marginBottom: 15 }}
            error={touched && error ? true : false}
            helperText={touched && error ? error : ""}
            onKeyDown={(e) => {
                if (e.keyCode === 13 && valid) {
                    handleSubmit(createUser)();
                }
            }}
            fullWidth
            {...custom}
            {...input}
            control={<Switch defaultChecked={false} />}
            label={label}
        />
    </FormGroup>
);

export default function SignUpPage() {
    const { redirectPosAuth, registerSignIn } = useAuth();

    useNotAuthenticatedOnly();

    const createUser = async (data) => {
        let errorMessage = ""
        const creating = new Promise((resolve, reject) => {
            firebaseUtil
                .register(data.nome, data.email, data.senha)
                .then((user) => {
                    registerSignIn();
                    redirectPosAuth();
                    resolve();
                })
                .catch((error) => {
                    switch (error.code) {
                        case "auth/email-already-exists":
                            errorMessage = "E-mail já existente"
                            break;
                        case 'auth/network-request-failed':
                            errorMessage = "Humm... Parece que você está sem conexão. Conecte-se a internet para cadastrar sua conta 🛜"
                            break
                        default:
                            break;
                    }
                    reject();
                });
        });
        toast.promise(creating, {
            pending: "Criando conta...",
            success: "Criado e logado com sucesso!",
            error: errorMessage,
        });
        return creating;
    };

    const handleContactSupport = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20a%20aplicação.",
            "_blank"
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
                maxWidth: "364px",
                margin: "0 auto",
                overflow: "hidden"
            }}
        >
            <div style={{ display: "flex", justifyContent: "center", padding: 25 }}>
                <img src={ImgLogo} alt="logo" width="150px" />
            </div>
            <Form
                onSubmit={createUser}
                validate={(values) => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = "Nome obrigatório";
                    }
                    if (!values.email) {
                        errors.email = "E-mail obrigatório";
                    }
                    if (!values.senha) {
                        errors.senha = "Senha obrigatória";
                    }
                    if (!values.confirmarSenha) {
                        errors.confirmarSenha = "Digite novamente sua senha";
                    } else if (values.confirmarSenha !== values.senha) {
                        errors.confirmarSenha = "As senhas devem ser iguais";
                    }
                    if (!values.termosECondicoes) {
                        errors.termosECondicoes = "Você precisa aceitar os termos";
                    }
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values, valid, meta }) => (
                    <form>
                        <Field
                            name="nome"
                            label="Nome"
                            valid={valid}
                            // handleSubmit={handleSubmit}
                            // createUser={createUser}
                            component={renderTextField}
                        />
                        <Field
                            name="email"
                            label="Email"
                            valid={valid}
                            // handleSubmit={handleSubmit}
                            // createUser={createUser}
                            component={renderTextField}
                        />
                        <Field
                            name="senha"
                            label="Senha"
                            valid={valid}
                            // handleSubmit={handleSubmit}
                            // createUser={createUser}
                            type="password"
                            component={renderTextField}
                        />
                        <Field
                            name="confirmarSenha"
                            label="Confirme sua senha"
                            valid={valid}
                            // handleSubmit={handleSubmit}
                            // createUser={createUser}
                            type="password"
                            component={renderTextField}
                        />
                        <Field
                            name="termosECondicoes"
                            label={
                                <div style={{ fontSize: "0.875rem" }}>
                                    Eu li e concordo com os{" "}
                                    <Link to="/privacidade" style={{ color: "#12954b", textDecoration: "none" }}>
                                        termos de uso
                                    </Link>{" "}
                                    e{" "}
                                    <Link to="/privacidade" style={{ color: "#12954b", textDecoration: "none" }}>
                                        a política de privacidade
                                    </Link>
                                    .
                                </div>
                            }
                            valid={valid}
                            // handleSubmit={handleSubmit}
                            // createUser={createUser}
                            component={renderCheckboxField}
                            type="checkbox"
                        // onChange={() => setSelected(!selected)}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "10px",
                                width: "100%",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ height: "40px", width: "100%" }}
                                onClick={handleSubmit}
                                disabled={submitting}
                            >
                                {!submitting && "Cadastrar"}
                                {submitting && <CircularProgress />}
                            </Button>
                            {/* <GoogleAuth title="Cadastrar com Google" disabled />
                            <AppleAuth title="Entrar com Apple" /> */}

                            <Typography
                                variant="caption"
                                fontWeight="bold"
                                textAlign="center"
                                sx={{ cursor: "pointer", border: "none", background: "transparent" }}
                                onClick={handleContactSupport}
                            >
                                Login com Google temporariamente desabilitado,
                                para mais informações entre em contato com o suporte
                                clicando nesta mensagem
                            </Typography>
                            <span>
                                Já tem uma conta?{" "}
                                <Link
                                    to="/login"
                                    style={{ textDecoration: "none", color: "#008868", fontWeight: "bold" }}
                                >
                                    Fazer login
                                </Link>
                            </span>
                        </div>
                    </form>
                )}
            />
        </motion.div>
    );
}
