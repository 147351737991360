import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { toImage } from "util/image";

/**
 * Exibe uma imagem a partir de uma URL ou de um File,
 * que pode ter sido gerado por um <input>.
 */
export default function ImageBox({ src, alt, maxWidth, maxHeight, sx, ...props }) {
    const [img, setImg] = useState();

    maxWidth ||= 200;
    maxHeight ||= 200;

    const ref = useCallback((node) => {
        if (node) {
            setImg(node);
        }
    }, []);

    toImage(src).then((result) => {
        if (img) {
            img.src = result.src;
        }
    });

    // TODO: loading image
    src = "";

    return (
        <Box sx={{ display: "inline-flex", justifyContent: "center", ...sx }} {...props}>
            <img
                style={{ objectFit: "contain", width: maxWidth, maxHeight: maxHeight }}
                ref={ref}
                src={src}
                alt={alt}
            />
        </Box>
    );
}
