import { Edit, Print } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import DefaultEntityConsult from 'components/form/DefaultEntityConsult'
import { fetchArray } from 'entity'
import AreaCultivada from 'entity/AreaCultivada'

import LaudoVisita from 'entity/LaudoVisita'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePerfil } from 'useAuth'

export default function LaudoVisitaConsulta() {
    const [areasAtivas, setAreasAtivas] = useState(null)
    const perfil = usePerfil()
    const navigate = useNavigate()

    //leoteste@compra.com
    useEffect(() => {
        AreaCultivada.query().then(query => {
            query = query.where("ativo", "==", true)
            fetchArray(query.get()).then(result => {
                result = result.filter(a => !a.fechado)
                setAreasAtivas(result)
            })
        })
    }, [perfil])

    const getAreaAtiva = (uid) => {
        for (let index = 0; index < areasAtivas.length; index++) {
            const area = areasAtivas[index];
            if (area.uid === uid) {
                return area;
            }
        }
    }

    const filterValues = useCallback(
        (list) => {
            return new Promise((resolve, reject) => {
                if (areasAtivas && areasAtivas.length > 10) {
                    list = list.filter(l => areasAtivas.map(a => a.uid).includes(l.areaCultivadaUid))
                }
                resolve(list)
            })
        },
        [areasAtivas],
    )

    const extraQuery = useCallback(
        (query) => {
            return new Promise((resolve, reject) => {

                if (areasAtivas) {
                    if (areasAtivas.length <= 10) {
                        query = query.where("areaCultivadaUid", "in", areasAtivas.map(a => a.uid))
                    }
                } else {
                    resolve(null)
                }
                query = query.orderBy("dataCadastro", "desc").limit(50)
                resolve(query)
            })
        }, [areasAtivas]
    )

    return (
        <DefaultEntityConsult
            noActionOnclickRow
            EntityClass={LaudoVisita}
            prospTable={{ mobileColumns: 3 }}
            addPreColumns={[{
                width: "100px",
                mobileOrder: 1,
                cell: (row) => {
                    return <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
                        <IconButton onClick={() => LaudoVisita.preparePrint(row, perfil, getAreaAtiva(row.areaCultivadaUid))}><Print /></IconButton>
                        <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('/laudoVisita?uid=' + row.uid, '_blank')
                            } else {
                                navigate('/laudoVisita?uid=' + row.uid)
                            }

                        }}><Edit /></IconButton>
                    </div>
                }
            }]}
            titulo={"Laudos de Visita"}
            routeForm={'laudoVisita'}
            extraQuery={extraQuery}
            filterValues={filterValues}
        />
    )
}
