import _ from "lodash";
import Entity, { fetchArray } from "entity";
import { generateUid } from "functions";

/**
 * Imagem salva no banco de dados.
 */
export default class ImageDB extends Entity {
    static get collectionUID() {
        return "Fotos";
    }

    async onLoad() {
        // Imagens dos indicadores.
        this.data.extencao ||= "jpeg";
        this.data.base64 ||= this.data.foto;
    }

    async preSave(entity) {
        entity.data.extencao ||= "jpeg";
        entity.data.base64 ||= this.data.foto;

        // TODO
        // delete entity.data.foto;
        // delete entity.data.fotoMini;
        // delete entity.data.fotoRelatorio;
    }

    async updateBase64(data) {
        const match = data.match(/^data:image\/(\w*);base64,(.*)$/);

        if (!match) {
            throw new Error("Formato de imagem inválido, deve ser base64");
        }

        this.data.extencao = match[1];
        this.data.base64 = match[2];
    }

    /**
     * Cria e salva uma nova imagem no banco a partir dos dados em formato Base64.
     *
     * @param {*} data String em formato Base64.
     * @param {*} ref Referência ao objeto que criou este.
     * @returns
     */
    static async fromBase64(data, ref) {
        const image = await this.create();
        image.updateBase64(data);

        if (ref) {
            image.data.ref = ref;
        }

        await image.save();
        return image;
    }

    // TODO: comentar
    static async fromBase64Array(array, ref, uidField = "imagem", dataField = "base64") {
        if (array) {
            for (const image of array) {
                const imageUid = _.get(image, uidField);
                const base64 = _.get(image, dataField);

                if (base64) {
                    if (imageUid) {
                        // Com UID, atualiza imagem existente.
                        const updateImage = await ImageDB.get(imageUid);
                        updateImage.updateBase64(base64);
                        updateImage.data.ref = ref;
                        updateImage.save();
                    } else {
                        // Sem UID, cria nova imagem.
                        const newImage = await ImageDB.fromBase64(base64, ref);

                        _.set(image, uidField, newImage.data.uid);
                    }
                }

                // Remove dados base64 da entidade referente.
                _.unset(image, dataField);
            }
        }
    }

    /**
     * Carrega a imagem com o UID especificado e retorna em formato Base64 para exibição.
     */
    static async loadBase64(uid) {
        if (!uid) {
            return null;
        }

        const image = await this.get(uid);

        if (image.data.base64) {
            return `data:image/${image.data.extencao};base64,${image.data.base64}`;
        } else {
            return null;
        }
    }

    /**
     * Carrega um array de imagens em formato Base64 para exibição.
     *
     * @param {*} array Array de dados que será carregado.
     * @param {*} uidField Campo de cada item do array que contém o UID da imagem.
     * @param {*} dataField Campo de cada item do array que será armazenada a imagem em base64.
     */
    static async loadBase64Array(array, uidField = "imagem", dataField = "base64") {
        if (array) {
            for (const image of array) {
                const uid = _.get(image, uidField);
                const base64 = await ImageDB.loadBase64(uid);

                if (base64) {
                    _.set(image, dataField, base64);
                }
            }
        }
    }
    //e-Laudo
    static async loadListFotosDoc(uidDocFotos) {
        if (uidDocFotos) {
            // console.log('uidDocFotos',uidDocFotos)
            let fotos = await fetchArray(ImageDB.collection.doc(uidDocFotos).collection("FotosLaudo").get())
            console.log('fotos',fotos)
            return fotos
        } else {
            return []
        }
    }

    static async saveListFotosDoc(uidDocFotos, fotos) {
        if (uidDocFotos && fotos) {
            let col = ImageDB.collection.doc(uidDocFotos).collection("FotosLaudo")
            // console.log('saveListFotosDoc', fotos)
            for (const foto of fotos) {
                const uid = foto.uid || generateUid();
                foto.uid = uid                
                await col.doc(foto.uid).set(foto, { merge: true });
            }
        }
    }
    static async deleteListFotosDoc(uidDocFotos, fotos) {
        if (uidDocFotos && fotos) {
            let col = ImageDB.collection.doc(uidDocFotos).collection("FotosLaudo")
            // console.log('deleteListFotosDoc', fotos)
            for (const foto of fotos) {
                if (foto.uid) {
                    await col.doc(foto.uid).delete()
                }
            }
        }
    }
}
