import BasicRoute from "components/routes/BasicRoute";
import AreaCultivadaConsulta from "entity/AreaCultivada/components/AreaCultivadaConsulta";
import AreaCultivadaForm from "entity/AreaCultivada/components/AreaCultivadaForm";
import DistribuicaoAreas from "entity/AreaCultivada/components/DistribuicaoAreas";
import PainelControleArea from "entity/AreaCultivada/components/PainelControle";
import { usePerfil } from "useAuth";


export default function RoutesMenusAreaCultivada() {
    const perfil = usePerfil()
    const showDistribuicao = perfil && ['admin', 'equipe'].includes(perfil.tipo)
    return <>
        {showDistribuicao &&
        <BasicRoute
            uid='distribuicaoAreas'
            route="distribuicaoAreas"
            menuProps={{
                text: "Distribuição de Áreas",
                sequence: "800000",
                iconName: "ViewQuilt",
            }}
            element={<DistribuicaoAreas />}
        />
    }
    <BasicRoute
        uid='PainelArea'
        route="PainelArea"
        // menuProps={{
            // text: "Distribuição de Áreas",
            // sequence: "800000",
            // iconName: "ViewQuilt",
        // }}
        element={<PainelControleArea />}
    />


        <BasicRoute
            uid='areaCultivadaConsult'
            route="consulta-areaCultivada"
            menuProps={{
                text: "Áreas Cultivadas",
                sequence: "200000",
                iconName: "ViewQuilt",
            }}
            element={<AreaCultivadaConsulta />}
        />
        <BasicRoute
            uid='areaCultivadaForm'
            route="areaCultivada"
            // menuProps={{
            //     text: "Nova Reunião",
            //     iconName: "GroupAdd",
            //     sequence: "000000",
            // }}
            element={<AreaCultivadaForm />}
        />



    </>;
}