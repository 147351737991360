import React, { useEffect, useState } from 'react'
import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import TextInput from 'components/form/input/TextInput';
import Cultura from '../..';
import DataTable from 'react-data-table-component';
import { useFieldValue } from 'components/form/input/NestedField';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Delete, Download, Upload } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export function CulturaFormInter(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [typedCulture, setTypedCulture] = useState(true)
    const [fases, setFases] = useFieldValue('fases')

    const navigate = useNavigate()

    const handleOpenDeleteModal = () => setModalOpen(true)
    const handleCloseDeleteModal = () => setModalOpen(false)

    useEffect(() => {
        if (props.values.delete) {
            if (props.values.delete.toLowerCase() === "deletar") {
                setTypedCulture(false)
            } else {
                setTypedCulture(true)
            }
        }
    }, [props.values.delete, props.values.nome])

    const addItem = () => {
        let newArray = fases.slice()
        newArray.push("Fase")
        setFases(newArray)
    }

    const deleteItem = (index) => {
        let newArray = fases.slice()
        newArray.splice(index, 1)
        setFases(newArray)
    }

    const deleteCulture = (uid) => {
        if (props.values.delete.toLowerCase() === "deletar") {
            try {
                Cultura.delete(uid).then(() => {
                    toast.success("Cultura excluída!")
                    handleCloseDeleteModal()
                    navigate("/consulta-cultura")
                })
            } catch (error) {
                toast.error("Oops, ocorreu algum erro no processo. Tente novamente")
            }
        }
    }

    const updateItem = (index, value) => {
        let newArray = fases.slice()
        newArray[index] = value
        setFases(newArray)
    }

    const moveUp = (index) => {
        let newArray = fases.slice()
        array_move(newArray, index, index - 1)
        setFases(newArray)
    }

    const moveDown = (index) => {
        let newArray = fases.slice()
        array_move(newArray, index, index + 1)
        setFases(newArray)
    }

    const array_move = (arr, old_index, new_index) => {
        if (new_index < 0) {
            new_index = 0
        }
        if (new_index < arr.length) {

            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxWidth: "400px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    //componente botao deletar, pegando useFiledValue uid do que quero deletar, recebe a entity que quer deletar
    //

    return (<FormContent sx={{ m: 1 }}>
        <FormRow>
            <TextInput name="nome" label="Nome" required />
            <Button onClick={addItem}>Adicionar Fase</Button>
            {props.values.uid && <Button color='error' onClick={() => handleOpenDeleteModal(true)}>Deletar</Button>}
        </FormRow>
        <FormRow>
            <DataTable
                columns={[{
                    name: "Nome da Fase",
                    selector: (row, index) => <TextField value={row} onChange={(evt) => updateItem(index, evt.target.value)} />
                },
                {
                    width: "150px",
                    cell: (row, index) => <div style={{ display: 'flex' }}>
                        <IconButton onClick={() => moveUp(index)}><Upload /></IconButton>
                        <IconButton onClick={() => moveDown(index)}><Download /></IconButton>
                        <IconButton onClick={() => deleteItem(index)}><Delete /></IconButton>
                    </div>
                },]}
                data={fases}

            />
        </FormRow>

        <Modal
            open={modalOpen}
            onClose={handleCloseDeleteModal}
        >
            <Box sx={style} display="flex" flexDirection="column" gap={2}>
                <Typography variant='h6' component='h2'>Excluir registros</Typography>
                <Typography>
                    Esta ação excluirá permanentemente este registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                </Typography>
                <Typography>
                    Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                </Typography>
                <form>
                    <FormContent>
                        <FormRow>
                            <TextInput label={'Digite "deletar" para confirmar a exclusão'} name="delete" initialValue="" required />
                        </FormRow>
                        <FormRow>
                            <Button color='primary' onClick={handleCloseDeleteModal}>Cancelar</Button>
                            <Button color='error' disabled={typedCulture} onClick={() => deleteCulture(props.values.uid)}>Excluir definitivamente</Button>
                        </FormRow>
                    </FormContent>
                </form>
            </Box>
        </Modal>
    </FormContent >)
}

export default function CulturaForm() {
    return (
        <DefaultEntityCrud
            EntityClass={Cultura}
            title={"Cultura"}
            routeForm="cultura"
            backRoute="consulta-cultura"
            formActions={{ novoForm: false, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <CulturaFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}