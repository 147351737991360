import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Entity from 'entity';
import Perfil from 'entity/Perfil';

import { getRedirectResult, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { crateSnapDocument, salvarPerfilLogin, salvarPerfilRegistro } from './dbApi';
import firebaseUtil from './firebaseUtil';

import _ from 'lodash';

export function useUserUid() {
    if (!firebaseUtil.getCurrentUser()) {
        return null;
    }
    return firebaseUtil.getCurrentUser().uid
}
export const getUserSnap = (uidUser, resolve, reject) => {
    let colCustomForm = 'User';
    return crateSnapDocument(uidUser, colCustomForm,
        (r) => {
            if (r.userUid && r.userUid !== r.uid)
                r.uid = r.userUid
            resolve(r)
        },
        (err) => {
            console.log('getUserSnap erro', err)
            if (uidUser) {
                if (firebaseUtil.getCurrentUser()) {
                    salvarPerfilLogin(firebaseUtil.getCurrentUser());
                }
            }
            reject(err)
        }
    )
}

export const PerfilContext = React.createContext({})
function PerfilControl() {

    const [perfil, setPerfil] = useState(null)

    return { perfil, setPerfil }
}
export const PerfilProvider = ({ children }) => {
    const perfilAppContext = PerfilControl();
    const { setPerfil } = perfilAppContext

    const useruid = useUserUid();

    useEffect(() => {
        let observer
        if (useruid) {
            console.log('NEW OBSERVER Perfil aaaaaaaaa')
            observer = getUserSnap(useruid,
                (r) => {
                    console.log('recebeU update perfil Entity.globalProps')
                    Entity.globalProps = { ...Entity.globalProps, perfil: r }
                    let startDate = new Date()
                    let endDate = new Date(startDate)
                    endDate.setMonth(startDate.getMonth() + 1);
                    if (!r.trialDate) {
                        let updatePerfil = { uid: useruid, trialDate: { start: startDate.toISOString().split('T')[0], end: endDate.toISOString().split('T')[0] } }
                        Perfil.saveValuesEntity(updatePerfil)
                    }

                    // verifica a plataforma atual (atualmente plano funciona apenas para android)
                    if (window.cordova && window.cordova.platformId === 'android') {

                        const { store, Platform } = window.CdvPurchase;

                        // faço um get no produto para verificar se houve cancelamento acessando a chave "owned"
                        const myProduct1 = store.get('elaudo.assinatura.pro', Platform.GOOGLE_PLAY);

                        if (r.planoContratado) {
                            const stripePlan = r.planoContratado.isActive;
                            const googlePlayPlan = myProduct1 ? myProduct1.owned : false;

                            const shouldRemainActive = stripePlan || googlePlayPlan;

                            if (r.planoContratado.isActive !== shouldRemainActive) {
                                let updatePlan = {
                                    uid: useruid,
                                    planoContratado: { isActive: shouldRemainActive }
                                };
                                Perfil.saveValuesEntity(updatePlan);
                            }
                        }

                        // if (myProduct1 && r.planoContratado) {
                        //     if (r.planoContratado.isActive === false && myProduct1.owned) {
                        //         let oldValues = { ...myProduct1 }
                        //         oldValues.owned = myProduct1.owned
                        //         let updatePlan = { uid: useruid, planoContratado: { isActive: oldValues.owned } }
                        //         Perfil.saveValuesEntity(updatePlan)
                        //     }
                        // }

                        // if (myProduct1 && r.planoContratado) {
                        //     // // caso os valores estejam diferentes entre si, aplica-se uma atualização com a situação atualizada
                        //     // if (r.planoContratado.isActive && (r.planoContratado.isActive !== myProduct1.owned)) {
                        //     //     let oldValues = { ...myProduct1 }
                        //     //     oldValues.owned = myProduct1.owned
                        //     //     let updatePlan = { uid: useruid, planoContratado: { isActive: oldValues.owned } }
                        //     //     Perfil.saveValuesEntity(updatePlan)
                        //     // }
                        // }
                    }
                    setPerfil((old) => {
                        if (!_.isEqual({ ...old, lastChange: null, lastLogin: null, updatePerfil: null }, { ...r, lastChange: null, lastLogin: null, updatePerfil: null })) {
                            return r
                        }
                        return old
                    })
                },
                (err) => {
                    setPerfil(null)
                }
            );
        } else {
            setPerfil(null)
        }
        return () => {
            if (observer) {
                observer();
            }
        }
    }, [setPerfil, useruid])

    return (
        <PerfilContext.Provider value={perfilAppContext}>
            {children}
        </PerfilContext.Provider>
    )
}

export const usePerfilContext = () => useContext(PerfilContext)

export const usePerfil = () => {
    const { perfil } = usePerfilContext();
    return perfil;
}

export function useIsAdmin() {
    let perfil = usePerfil();

    let isAdmin = false;

    if (perfil) {
        if (perfil.tipo === "admin") {
            isAdmin = true;
        }
    }
    return isAdmin;
}

export function useNotAuthenticatedOnly() {
    const { redirectPosAuth } = useAuth()

    useEffect(() => {
        if (firebaseUtil.getCurrentUser()) {
            redirectPosAuth()
        }
    }, [redirectPosAuth])

    return null;
}

export function useAuthenticatedOnly() {
    const { redirectLogin } = useAuth()

    useEffect(() => {
        if (!firebaseUtil.getCurrentUser()) {
            redirectLogin()
        }
    }, [redirectLogin])

    return null;
}
export default function useAuth() {
    const navigate = useNavigate();
    const currentUser = firebaseUtil.getCurrentUser();

    const redirectPosAuth = (pagePosLogin) => {
        navigate('/')
    }

    const redirectLogin = () => {
        navigate('/Login')
    }

    const redirectRegister = () => {
        navigate('/Register')
    }

    const isAuthenticated = useCallback(() => {
        if (!currentUser) {
            return false;
        } else {
            return true;
        }
    }, [currentUser])

    const userName = () => {
        return firebaseUtil.getCurrentUsername()
    }

    const userUid = () => {
        if (!firebaseUtil.getCurrentUser()) {
            return null
        }
        return firebaseUtil.getCurrentUser().uid
    }

    const user = () => {
        return firebaseUtil.getCurrentUser()
    }

    const registerLogin = () => {
        // console.log('registerLogin', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            salvarPerfilLogin(firebaseUtil.getCurrentUser());
        }
    }

    const registerSignIn = () => {
        // console.log('registerSignIn', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            console.log("firebaseUtil.getCurrentUser() ", firebaseUtil.getCurrentUser());
            salvarPerfilRegistro(firebaseUtil.getCurrentUser());
        }
    }

    const loginUserWithGoogle = () => {
        return firebaseUtil.loginUserWithGoogle()
        // const provider = new GoogleAuthProvider();

        // const auth = firebaseUtil.getAuth()
        // console.log('window.cordova', window.cordova)
        // if (window.cordova) {
        //     signInWithRedirect(auth, provider)
        //         .then((result) => {
        //             // eslint-disable-next-line no-unused-vars
        //             const credential = GoogleAuthProvider.credentialFromResult(result)
        //         }).catch((error) => {
        //             console.log("error ", error);
        //         })
        //     redirectPosAuth();
        // } else {
        //     signInWithPopup(auth, provider)
        //         .then((result) => {
        //             GoogleAuthProvider.credentialFromResult(result)
        //             registerLogin()
        //             redirectPosAuth()
        //         }).catch((error) => {
        //             console.log("error ", error);
        //         })
        //     redirectPosAuth();
        // }
    }

    const loginUserWithApple = async () => {
        return new Promise((resolve, reject) => {
            const provider = new OAuthProvider("apple.com")
            const auth = firebaseUtil.auth();
            signInWithRedirect(auth, provider)
                .then((result) => {
                    console.log('result', result)
                    return getRedirectResult(auth);
                })
                .then((result) => {
                    console.log('result2', result)
                    resolve()
                    redirectPosAuth();
                }).catch((error) => {
                    console.log('error', error)
                    const errorMessage = error.message;
                    console.log('errorMessage', errorMessage)
                    const credential = OAuthProvider.credentialFromError(error);
                    console.log('credentialFromError', credential)
                    reject(error)
                });
        })
    }

    return { redirectLogin, redirectRegister, redirectPosAuth, userName, userUid, user, registerLogin, registerSignIn, isAuthenticated, loginUserWithGoogle, loginUserWithApple }
}

export function useInDebug() {
    const currentURL = window.location.href
    const inDebug = currentURL.includes("localhost:") && !window.cordova
    if (inDebug) {
        return true
    } else {
        return false
    }
}