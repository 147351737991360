import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { usePerfil } from 'useAuth';
import { useNavigate } from 'react-router-dom';

export default function AlertaPeriodoTeste() {
    const [showMessage, setShowMessage] = useState(false)
    const [trialDate, setTrialDate] = useState({})
    const navigate = useNavigate()


    const perfil = usePerfil()
    useEffect(() => {
        if (perfil) {
            if (!perfil.planoContratado && perfil.tipo !== "admin" && perfil.trialDate) {
                let startTrialDate = perfil.trialDate.start.split('-').reverse().join("/")
                let endTrialDate = perfil.trialDate.end.split('-').reverse().join("/")

                setShowMessage(true)
                setTrialDate({ start: startTrialDate, end: endTrialDate })
            }
        }

    }, [perfil])

    const handleClose = () => {
        setShowMessage(false);
        window.localStorage.setItem("dialogPlanWarning", false)
    };

    const purchasePlan = () => {
        setShowMessage(false)
        navigate("/planos")
    }

    return (
        <>
            {showMessage &&
                <Dialog
                    // fullScreen={fullScreen}
                    open={showMessage}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        Período de testes
                    </DialogTitle>
                    <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <DialogContentText>
                            Seu período de testes teve início no dia {trialDate.start} e será finalizado no dia {trialDate.end}.
                        </DialogContentText>
                        <DialogContentText>
                            Após o período de testes você não será capaz de salvar nem criar novos laudos, sendo permitido apenas realizar a visualização e impressão.
                        </DialogContentText>
                        <DialogContentText style={{ fontSize: 14 }}>
                            * Clique em "fechar" para não exibir novamente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ flexWrap: 'wrap', padding: "0 25px 15px 25px", gap: "5px" }}>
                        <Button onClick={purchasePlan} >
                            Adquirir plano Pro
                        </Button>
                        <Button onClick={handleClose} color='error' >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}