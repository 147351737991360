import _ from 'lodash'
export class ValidatorHelper {
    constructor() {
        this.errors = null;
        this.verify = []
        this.typeToString = {
            required: (label) => `Informe o  campo: ${label}`
        }
        this.typeValidValue = {
            required: (values, param) => _.get(values, param) ? true : false
        }
    }
    addRequired(param, label) {
        this.verify.push({ type: "required", label, param })
    }

    valid(values) {
        let erros = []
        this.verify.forEach(v => {
            if (!this.typeValidValue[v.type](values, v.param)) {
                erros.push(this.typeToString[v.type](v.label))
            }
        });
        return erros

    }
}

export function generateUid() {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let autoId = ''

    for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length)
        )
    }

    return autoId
}