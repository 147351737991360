import React, { useState } from 'react'
import FormContent from 'components/form/FormContent';
import MembroEquipe from 'entity/MembroEquipe';
import { useSnapListEntity } from 'entity';
import OkTable from 'components/OkTable';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { usePerfil } from 'useAuth';
import Perfil from 'entity/Perfil';
import Convite from 'entity/Convite';
import FormDialog from 'components/dialog/FormDialog';
import FormAddMembro from './FormAddMembro';
import FormCriaConta from './FormCriaConta';
import firebaseUtil from 'firebaseUtil';
import useNotification from 'notification';



export default function PaineMembrosEquipe() {
    const { showError, showSuccess } = useNotification()
    const perfil = usePerfil();
    const tokenConta = Perfil.getTokenConta(perfil)

    const [editValue, setEditValue] = useState(null)
    const [createUser, setCreateUser] = useState(null)

    const [membros] = useSnapListEntity(MembroEquipe)


    const removeMembro = (membro) => {
        console.log('removeMembro', membro)
        if (membro.conviteAceito && membro.userUid) {
            Perfil.saveValuesEntity({ uid: membro.userUid, contaInternaDe: null })
        }
        MembroEquipe.delete(membro.uid)
        let conviteUid = membro.uid
        if (membro.conviteRef) {
            conviteUid = membro.conviteRef.id
        }
        Convite.delete(conviteUid)
    }

    const criarContaMembro = (values) => {
        const { nome, email, senha } = values
        firebaseUtil.createCount(email, senha).then(firebaseUser => {

            let perfilUpdate = {
                contaInternaDe: tokenConta,
                uid: firebaseUser.user.uid,
                nome: nome,
                email: email,
                tipo: "interna"
            }
            let novoMembro = { uid: perfilUpdate.uid, userUid: perfilUpdate.uid, statusConvite: "Aceito", conviteAceito: true, email: email, nome: nome }
            Perfil.saveValuesEntity(perfilUpdate)
            MembroEquipe.saveValuesEntity(novoMembro)
            showSuccess('Novo Usuário Criado')
        }).catch(error => {
            switch (error.code) {
                case 'auth/invalid-email':
                    showError('Endereço de e-mail Inválido')
                    break;
                case 'auth/email-already-in-use':
                    showError('Endereço de e-mail já em uso')
                    break;
                case 'auth/weak-password':
                    showError('Senha muito fraca')
                    break;
                // case 'auth/user-disabled':
                //     showError('O usuário pode ter sido desativado')
                //     break;
                // case 'auth/user-not-found':
                //     showError('Não há registro de usuário correspondente a esse identificador. O usuário pode ter sido excluído')
                //     break;
                // case 'auth/wrong-password':
                //     showError('Senha inválida ou o usuário não tem uma senha')
                //     break;
                default:
                    break;
            }
        })

    }

    const convidar = (convidar) => {
        console.log('convidar', tokenConta, convidar)
        Convite.criaConviteEquipe(convidar.email, convidar.nome, perfil, { tokenConta: tokenConta })
    }

    return (<FormContent sx={{ m: 1 }}>
        <Box style={{ display: "flex", p: 1, gap: 10 }}>
            <Typography variant='h6' style={{ flex: 1 }}>
                Membros da Equipe
            </Typography>

            <Button onClick={() => setCreateUser({ tipo: "Interna" })} >Criar Conta Interna</Button>
            <Button onClick={() => setEditValue({ tipo: "Convidado" })} >Convidar</Button>

        </Box >
        <OkTable
            data={membros || []}
            columns={[
                { width: '55px', cell: (row) => <IconButton onClick={() => removeMembro(row)}><Delete /></IconButton> },
                { name: 'Nome', field: "nome" },
                { name: 'Email', field: "email" },
                { name: 'Data Convite', field: "dataConvite" },
                { name: 'Convite', field: "statusConvite" },
            ]}
        />
        {editValue && <FormDialog
            title={"Adicionar Membro"}
            onClose={() => {
                setEditValue(undefined)
            }}
            open={editValue ? true : false}
            value={editValue}
            validValues={async (v) => {
                let errorsMessage = []
                for (let index = 0; index < membros.length; index++) {
                    const t = membros[index];

                    if (v.email === t.email) {
                        errorsMessage.push("Email já convidado")
                    }

                }
                if (errorsMessage.length) {
                    return errorsMessage
                }

                return null
            }}
            updateValue={values => {
                if (values) {

                    convidar(values);
                }
                setEditValue(undefined)
            }}
        >
            {(formProps) => <FormAddMembro {...formProps} />}
        </FormDialog>}
        {createUser && <FormDialog
            title={"Criar usuários Interno"}
            onClose={() => {
                setCreateUser(undefined)
            }}
            open={createUser ? true : false}
            value={createUser}
            validValues={async (v) => {
                let errorsMessage = []
                if (v.senha !== v.repeteSenha) {
                    errorsMessage.push(`Senhas diferentes`)
                }

                if (v.email) {
                    let isEmailValid = String(v.email)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                    if (!isEmailValid) {
                        errorsMessage.push("Email inválido")
                    }
                }
                if (errorsMessage.length) {
                    return errorsMessage
                }
                return null
            }}
            updateValue={values => {
                if (values) {

                    criarContaMembro(values)
                }
                setCreateUser(undefined)
            }}
        >
            {(formProps) => <FormCriaConta {...formProps} />}
        </FormDialog>}

    </FormContent >
    )



}

