import React from 'react'
import useAuth, { usePerfil } from 'useAuth'
import RoutesMenusPrivate from './private'
import RoutesMenusPublic from './public'


export default function RoutesMenus() {
    // eslint-disable-next-line no-unused-vars
    const perfil = usePerfil()
    const { isAuthenticated } = useAuth()
    const isAuthenticatedV = isAuthenticated()

    // console.log('RoutesMenus Private?:', isAuthenticatedV)
    if (isAuthenticatedV) {
        return (
            <>
                <RoutesMenusPrivate />
            </>
        )
    } else {
        return (
            <>
                <RoutesMenusPublic />
            </>

        )
    }


}
