import _ from "lodash";

import { format } from "date-fns";



import { ArrowDownRed, ArrowDownYellow, ArrowUpRed, ArrowUpYellow } from "./arrow";
import { LocationIcon } from "./LocationIcon";

export default class TableReportHelper {
    constructor(data) {
        this.data = data;

        this.textStyle = "cellValor";
        this.titleStyle = "cellTitulo";

        this.dateFormat = "dd/MM/yyyy";
        this.digits = 2;
        this.separator = ", ";

        this.conformidadeColors = {
            Ótimo: "#0ee650",
            Bom: "#0ee650",
            Regular: "#f7f71e",
            Ruim: "#f0483a",
        };
    }

    /**
     * Cria um helper para dados locais (ex: linhas da tabela).
     */
    helper(data) {
        return new TableReportHelper(data);
    }

    /**
     * Insere uma quebra de página.
     */
    pageBreak(props) {
        return {
            text: "", pageBreak: "after", ...props
        };
    }

    space(height = 20) {
        return { text: "", margin: [0, 0, 0, height] };
    }

    /**
     * Recebe um array com as linhas e cria uma tabela.
     */
    panel(rows, props, tableProps) {
        if (!_.isEmpty(rows)) {
            return this.painel(
                rows,
                {
                    widths: Array(rows[0].length).fill("*"),
                    ...props,
                },
                {
                    dontBreakRows: true,
                    ...tableProps,
                }
            );
        } else {
            return this.empty();
        }
    }
    panelBreakRows(rows, props, tableProps) {
        if (!_.isEmpty(rows)) {
            return this.painel(
                rows,
                {
                    widths: Array(rows[0].length).fill("*"),
                    ...props,
                },
            );
        } else {
            return this.empty();
        }
    }

    unbreakable(content, props) {
        return { stack: [content], unbreakable: true, ...props };
    }

    /**
     * Recebe as células criadas por 'cell()' e cria uma linha da tabela.
     *
     * Os resultados deve ser armazenados em um array e passados para o método 'panel()'.
     */
    row(...cells) {
        const result = [];

        for (const cell of cells) {
            // Expande a célula em múltiplas colunas se for um array.
            if (_.isArray(cell)) {
                result.push(...cell);
            } else if (!_.isNil(cell)) {
                result.push(cell);
            }
        }

        return result;
    }

    /**
     * Cria uma cálula da tabela, ou múltiplas se o prop 'colSpan' for especificado.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    cell(props) {
        if (props.type) {

            let text = props.text
            if (props.type === "money") {
                text = parseFloat(text).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                if (!props.alignment) props.alignment = 'right'
            } else if (props.type === "number") {
                text = parseFloat(text).toLocaleString('pt-br')
                if (!props.alignment) props.alignment = 'right'
            } else if (props.type === "date") {
                if (text) {

                    let date = text

                    if (typeof text === 'string' || text instanceof String) {
                        if (text.includes("-")) {
                            date = text.split('-').reverse().join('/')
                        }

                    } else {
                        if (text.toDate) {
                            text = text.toDate()
                        }
                        date = this.formatDate(text);

                    }
                    text = date
                }

            }

            props.text = text

        }

        const result = [props];


        if (props.colSpan) {
            // Se a célula ocupar múltiplas colunas, preenche com espaços vazios.
            // Este é um requerimento do pdfmake.
            result.push(...this.empty(props.colSpan - 1));
        }

        return result;
    }

    /**
     * Cria um array com uma ou mais células vazias.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    empty(colSpan = 1, props) {
        return Array(colSpan).fill({ style: this.textStyle, text: "", ...props });
    }

    /**
     * Cria uma célula com formato de título.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    title(content, props) {
        return this.cell({ style: this.titleStyle, text: content, ...props });
    }

    /**
     * Cria uma célula com formato de texto simples.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    text(content, props) {
        return this.cell({ style: this.textStyle, text: content, ...props });
    }

    /*
     * Cria uma célula com formato de texto simples
     * Esse helper foi criado para renderizar uma mensagem personalizada caso não houver nenhuma informação
     * passada na descrição da imagem  
    */
    conditionalImageText(content, props) {
        return this.cell(
            {
                style: this.textStyle,
                text: content
                    ? content
                    : "Nenhuma descrição foi inserida.",
                ...props
            }
        )
    }

    /**
     * Cria uma célula contendo o número formatado.
     * Aceita o prop 'digits', que são o número de casas decimais.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    number(content, props) {
        const digits = _.get(props, "digits");
        const suffix = _.get(props, "suffix", "");

        return this.text(this.formatNumber(content, digits) + suffix, props);
    }

    /**
     * Cria uma célula contendo a data formatada.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    date(content, props) {
        return this.text(this.formatDate(content), props);
    }

    /**
     * Cria uma célula contendo uma lista de valores.
     *
     * O resultado deve ser passado como um dos parâmetros do método 'row()'.
     */
    list(content, props) {
        return this.text(_.join(content, this.separator), props);
    }

    image(data, props) {
        if (!_.isEmpty(data)) {
            return this.cell({
                image: data,
                // style: this.textStyle,
                ...props,
            });
        } else {
            // console.log('sem imagem data', data)
            // console.log('sem imagem props', props)
            // return this.cell({ style: this.textStyle, text: "Não foi possivel carregar a imagem", ...props });
            return this.empty();
        }
    }

    arrowUp(status, props = {}) {
        let arrow;

        if (status === "Ruim") {
            arrow = ArrowUpRed;
        } else if (status === "Regular") {
            arrow = ArrowUpYellow;
        }

        return this.image(arrow, {
            width: 24,
            style: this.textStyle,
            ...props,
        });
    }

    arrowDown(status, props = {}) {
        let arrow;

        if (status === "Ruim") {
            arrow = ArrowDownRed;
        } else if (status === "Regular") {
            arrow = ArrowDownYellow;
        }

        return this.image(arrow, {
            width: 24,
            style: this.textStyle,
            ...props,
        });
    }

    location(coordinates, props) {
        if (!_.isEmpty(coordinates)) {
            return {
                image: LocationIcon,
                link: `https://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`,
                width: 20,
                style: this.textStyle,
                ...props,
            };
        } else {
            return this.empty();
        }
    }

    // TODO: mover campos específicos da aplicação para outra classe.
    conformidade(value, status, props) {
        return this.number(value, { fillColor: this.conformidadeColors[status], ...props });
    }



    textField(field, props) {
        if (props === "imagem") return this.conditionalImageText(this.get(field), props)

        return this.text(this.get(field), props);
    }

    numberField(field, props) {
        return this.number(this.get(field), props);
    }

    dateField(field, props) {
        return this.date(this.get(field), props);
    }

    listField(field, props) {
        return this.list(this.get(field), props);
    }

    imageField(field, props) {
        return this.image(this.get(field), props);
    }

    locationField(field, props) {
        return this.location(this.get(field), props);
    }

    conformidadeField(field, status, props) {
        return this.conformidade(this.get(field), status, props);
    }

    get(field) {
        const value = _.get(this.data, field);

        return _.isNil(value) ? "" : value;
    }

    formatNumber(number, digits = 2) {
        const value = parseFloat(number);
        digits = !_.isNil(digits) ? digits : this.digits;

        if (_.isNaN(value)) {
            return "";
        } else {
            return value.toLocaleString("pt-br", {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
            });
        }
    }

    formatDate(date) {
        try {

            return format(date, this.dateFormat);
        } catch (error) {
            return 'DATAERROR';

        }
    }

    tablePanel(colunas, linhas, props, tableProps) {

        const getValue = (c, l) => {
            if (c.getValue) {
                return c.getValue(l)
            }

            return _.get(l, c.field)
        }
        const getColor = (c, l) => {
            if (c.getColor) {
                return c.getColor(l)
            }
            return _.get(l, c.color)
        }
        let content = []

        content.push(this.row(...colunas.map(c => this.title(c.title, { ...c.propsField, alignment: c.alignment, }))))
        if (linhas) {
            linhas.forEach(l => {
                content.push(this.row(...colunas.map(c => this.text(getValue(c, l), { ...c.propsField, type: c.type, alignment: c.alignment, fillColor: getColor(c, l) }))))
            })
        } else {

            content.push(this.row(...colunas.map(c => this.text(c.value ? c.value : this.get(c.field), { ...c.propsField, type: c.type, alignment: c.alignment, }))))
        }

        return this.panel(
            content,
            { widths: colunas.map(c => c.width || "*"), ...props },
            { headerRows: 1, ...tableProps }
        )
    }

    painel(conteudo, config, configTable) {
        let rows = [];
        let defaultConfig = {
            style: 'tableExample',
            margin: [0, 0, 0, 1],
            layout: 'noBorders'
        };
        if (Array.isArray(conteudo)) {
            if (Array.isArray(conteudo[0])) {
                rows = conteudo;
            } else {
                rows.push(conteudo);
            }
        } else {
            defaultConfig = { ...defaultConfig, widths: ['*'] }
            rows.push([conteudo])
        }


        if (config) {
            defaultConfig = { ...defaultConfig, ...config }
        }

        return {
            // margin: defaultConfig.margin,
            // style: defaultConfig.style,
            table: {
                body: rows,
                widths: defaultConfig.widths,
                ...configTable
            },
            // layout: defaultConfig.layout,
            ...defaultConfig
        }
    }

    fieldset(titulo, content, prospTable) {
        let conteudo = null;
        if (typeof content === 'string') {
            conteudo = [[{ text: content }]];
        } else {

            if (Array.isArray(content)) {
                conteudo = []
                content.forEach(c => conteudo.push([c]));
            } else {
                conteudo = [[content]]
            }
        }
        return {
            style: 'tableExample',
            margin: [0, 2, 0, 2],
            table: {
                headerRows: 2,
                widths: [3, 250, '*'],
                heights: [5, 5, 'auto'],
                body: [
                    [
                        {
                            text: '',
                            border: [false, false, false, false],
                        },
                        {
                            rowSpan: 2,
                            border: [true, true, true, true],
                            text: titulo,
                            style: 'fieldsetTitulo',
                        },
                        {

                            text: '',
                            border: [false, false, false, false],
                        }
                    ],
                    [
                        {
                            text: '',
                            border: [true, true, false, false],
                        },
                        {
                            text: '',
                            border: [false, false, false, false],
                        },
                        {
                            text: '',
                            border: [false, true, true, false],
                        }
                    ],
                    [
                        {
                            colSpan: 3,
                            border: [true, false, true, true],
                            table: {
                                widths: ['*'],
                                body: conteudo
                            },
                            layout: 'noBorders'

                        }
                    ]
                    ,
                ]
            }
        }
    }



}
