
import FormContent from 'components/form/FormContent'
import TextInput from 'components/form/input/TextInput'
import React from 'react'

export default function FormCriaConta(props) {   

    return (
        <FormContent sx={{ p: 1 }}>
            <TextInput name="nome" label="Nome" required />
            <TextInput name="email" label="Email" required />
            <TextInput name="senha" label="Senha" required />
            <TextInput name="repeteSenha" label="Repitir Senha" required />
        </FormContent>
    )
}
