import { Box, IconButton, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect, useState } from "react";
import { useFieldValue } from "./NestedField";

export function useCoordinates() {
    const [coordinates, setCoordinates] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCoordinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                setError(null);
            },
            (error) => {
                setCoordinates(null);
                setError(error);

                console.error(error);
            }
        );
    }, []);

    return [coordinates, error];
}

export function getMapUrl(coordinates) {
    if (!coordinates) {
        return null
    }
    if (coordinates.latitude) {
        return `https://www.google.com/maps/place/${coordinates.latitude},${coordinates.longitude}`
    }
    return `https://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`

}

export default function GeolocationButton({ name, label, buttonProps, ...props }) {
    const [value] = useFieldValue(name);

    if (value) {
        return (
            <Box sx={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
                {label ? <Typography>{label}</Typography> : null}

                <IconButton href={getMapUrl(value)} target="_blank" color="primary" {...buttonProps}>
                    <LocationOnIcon />
                </IconButton>
            </Box>
        );
    } else {
        return null;
    }
}
