import { Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import DefaultEntityConsult from 'components/form/DefaultEntityConsult'
import Insumo from 'entity/Insumo'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function InsumoConsulta() {
    const navigate = useNavigate()

    return (
        <DefaultEntityConsult
            prospTable={{ noWrapMobile: true }}
            EntityClass={Insumo}
            titulo={"Insumos"}
            routeForm={'insumo'}
            addPreColumns={[{
                width: "50px",
                mobileOrder: 1,
                cell: (row) => {
                    return <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
                        <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('/insumo?uid=' + row.uid, '_blank')
                            } else {
                                navigate('/insumo?uid=' + row.uid)
                            }

                        }}><Edit /></IconButton>
                    </div>
                }
            }]}
        />
    )
}