import { useState } from 'react';

import AreaCultivada from 'entity/AreaCultivada';
import MembroEquipe from 'entity/MembroEquipe';
import { useSnapListEntity } from 'entity';

import FormDialogState from 'components/dialog/FormDialogState';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import FormRowLoading from 'components/form/FormRowLoading';
import SelectInput from 'components/form/input/SelectInput';
import OkTable from 'components/OkTable';
import { PField, useFieldValue } from 'components/form/input/NestedField';
import { fieldHasError, requiredValidator } from 'components/form/input/validator';

import { useAppContext } from 'app/AppContext';

import { Edit } from '@mui/icons-material';
import { Box, Button, Checkbox, IconButton, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'AppPersonalise';

function FormDialogMembros({ membrosEquipe, smScreen }) {
    const [membros, setMembros] = useFieldValue("membros")
    console.log("membros ", membros);

    const handleCheckboxChange = (event, membro) => {
        if (event.target.checked) {
            setMembros([...membros, membro]);
        } else {
            setMembros(membros.filter((a) => a !== membro));
        }
    };

    return (
        <FormContent sx={{ p: 1 }}>
            <FormRow >
                <SelectInput
                    required
                    name="permissao"
                    label="Permissão"
                    options={["Editor", "Leitura"]}//"Admin"
                />
            </FormRow>
            <PField
                name={"membros"}
                validate={requiredValidator}
            >
                {(fieldProps) => {
                    const hasError = fieldHasError(fieldProps.meta);
                    return (
                        <Box>
                            {hasError && <Typography sx={{ color: '#f00' }}>{fieldProps.meta.error}</Typography>}
                            <OkTable
                                selectableRows
                                onSelectedRowsChange={({ selectedRows }) => setMembros(selectedRows)}
                                data={membrosEquipe || []}
                                columns={[
                                    {
                                        cell: (t, i) => {
                                            return (
                                                <>
                                                    {smScreen && (
                                                        <Checkbox checked={membros.includes(t)}
                                                            onChange={(event) => handleCheckboxChange(event, t)} />
                                                    )}
                                                </>
                                            )
                                        },
                                        width: "110px"
                                    },
                                    { field: "nome", headerName: "Nome", },
                                    { field: "email", headerName: "Email", },
                                ]}
                            />
                        </Box>
                    )
                }}
            </PField>
        </FormContent>
    )
}

export default function DistribuicaoAreas() {
    const { useSetTitle } = useAppContext();
    useSetTitle('Distribuição de áreas')
    const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [queryExtra,] = useState({ query: (q) => q.where('ativo', '==', true) })
    const [queryExtraMembro,] = useState({ query: (q) => q.where('conviteAceito', '==', true) })
    const [membrosEquipe] = useSnapListEntity(MembroEquipe, queryExtraMembro.query)
    const [areas] = useSnapListEntity(AreaCultivada, queryExtra.query)

    const [valueDialog, setValueDialog] = useState(undefined)
    const [areasSelecionadas, setAreasSelecionadas] = useState([])
    console.log("areasSelecionadas ", areasSelecionadas);

    const atriburEquipe = (values) => {
        const { membros, permissao, areaCultivada } = values

        let usuarioPermitidosNew = membros.map(v => {
            return {
                membroEquipe: v,
                uidConvidado: v.userUid,
                emailConvidado: v.email,
                nome: v.nome,
                aceito: true,
                permissao: permissao,
                tipo: "Equipe"
            }
        })
        if (areaCultivada) {
            console.log('areaCultivada', areaCultivada)
        } else {
            areasSelecionadas.forEach(areaCultivada => {
                let upArea = { uid: areaCultivada.uid, usuariosConfirmados: areaCultivada.usuariosConfirmados }
                upArea.usuarioPermitidos = usuarioPermitidosNew
                if (!upArea.usuariosConfirmados) {
                    upArea.usuariosConfirmados = {}
                }
                for (const key in upArea.usuariosConfirmados) {
                    upArea.usuariosConfirmados[key] = false
                }
                usuarioPermitidosNew.forEach(u => {
                    upArea.usuariosConfirmados[u.uidConvidado] = true
                });
                AreaCultivada.saveValuesEntity(upArea)
            });
        }
    }

    const startArea = (area) => {
        let selectedMembros = []
        area.usuarioPermitidos.forEach(u => {
            for (let index = 0; index < membrosEquipe.length; index++) {
                const membro = membrosEquipe[index];
                if (u.uid === membro.uid) {
                    selectedMembros.push(membro)
                }
            }
        })
        setValueDialog({ areaCultivada: area, membrosSel: selectedMembros })
    }

    const handleCheckboxChange = (event, area) => {
        if (event.target.checked) {
            setAreasSelecionadas([...areasSelecionadas, area]);
        } else {
            setAreasSelecionadas(areasSelecionadas.filter((a) => a !== area));
        }
    };

    return (
        <FormContent sx={{ m: 2 }}>
            <Box style={{ display: "flex", p: 1, gap: 10 }}>
                <Typography variant='h6' style={{ flex: 1 }}>
                    Distribuição de áreas
                </Typography>
                <Button onClick={() => setValueDialog({})} disabled={areasSelecionadas.length === 0} >Atribuir a Selecionados</Button>
            </Box >
            <FormRowLoading condition={!areas} />
            <OkTable
                selectableRows
                onSelectedRowsChange={({ selectedRows }) => setAreasSelecionadas(selectedRows)}
                data={areas || []}
                columns={[
                    {
                        cell: (t, i) => {
                            return (
                                <>
                                    {smScreen && (
                                        <Checkbox checked={areasSelecionadas.includes(t)}
                                            onChange={(event) => handleCheckboxChange(event, t)} />
                                    )}
                                </>
                            )
                        },
                    },
                    { field: "nomeArea", headerName: "Área Cultivada", },
                    { field: "nomeProdutor", headerName: "Produtor", },
                    { field: "cultura", headerName: "Cultura", width: "100px" },
                    { field: "safra", headerName: "Safra", width: "100px" },
                    { field: "areaHa", headerName: "Área Total", width: "100px" },
                    {
                        headerName: "Membros da Equipe", cell: (ac) => {
                            let strMenbros = ''
                            let membros = (ac.usuarioPermitidos || []).filter(u => u.tipo !== "Convidado")
                            membros.forEach(m => {
                                strMenbros += m.nome + "; "
                            });
                            return <Box>{strMenbros}</Box>
                        }
                    },
                    { cell: (row) => <IconButton onClick={() => startArea(row)}><Edit /></IconButton>, width: "100px" },
                ]}
            />
            <FormDialogState
                stateValeu={valueDialog}
                setStateValue={setValueDialog}
                title={"Atribuir Equipe"}
                updateValue={values => {
                    atriburEquipe(values)
                }}
            >
                {(formProps) => <FormDialogMembros {...formProps} membrosEquipe={membrosEquipe} smScreen={smScreen} />}
            </FormDialogState>
        </FormContent >
    )
}

