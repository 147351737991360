import { Checkbox, FormControlLabel } from "@mui/material";
import InputBox from "./InputBox";
import { PField } from "./NestedField";

export default function CheckboxInput({ name, label, initialValue, sx, ...props }) {
    return (
        <InputBox sx={sx}>
            <PField type="checkbox" name={name} initialValue={initialValue}>
                {(fieldProps) => {
                    const control = <Checkbox {...fieldProps.input} {...props} />;

                    return label ? <FormControlLabel label={label} control={control} /> : control;
                }}
            </PField>
        </InputBox>
    );
}
