import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import GeolocationButton from 'components/form/input/GeolocationButton'
import ImageInput from 'components/form/input/ImageInput'
import { useFieldValue } from 'components/form/input/NestedField'
import SelectInput from 'components/form/input/SelectInput'
import TextInput from 'components/form/input/TextInput'
import React from 'react'

export default function PainelOcorrencia() {    
    const [deleteFotos, setDeleteFotos] = useFieldValue('deleteFotosOcorrencia')   
    
    function handleRemoveImage(image) {
        console.log('image', image)
        if (image.uid) {
            let ar = deleteFotos ? deleteFotos.slice() : []
            ar.push(image);
            console.log('ar', ar)
            setDeleteFotos(ar)
            // visita.deleteImage(image.imagem);
        }
    }
    return (
        <FormContent>
            <FormRow >
                <TextInput name="ocorrencia.nome" label="Nome" />
                <TextInput name="ocorrencia.tipo" label="Tipo" />
            </FormRow>
            <FormRow >

                <SelectInput
                    name="ocorrencia.nivel"
                    label="Nível"
                    options={["Alto", "Médio", "Baixo"]}
                />
            </FormRow>
            <FormRow >
                <TextInput name="ocorrencia.observacao" label="Observação" multiline />

            </FormRow>
            <FormRow>
                <ImageInput name={'ocorrencia.fotos'} label={'Fotos'} imageField="foto" geolocationField="georeferencia" multiple onRemove={handleRemoveImage}>
                    <FormRow>
                        <GeolocationButton name="georeferencia" label="Rota Maps" icon />
                    </FormRow>
                    <FormRow>
                        <TextInput name="descricao" label="Descrição" multiline rows={4} />
                    </FormRow>
                </ImageInput>

            </FormRow>
        </FormContent>
    )
}
