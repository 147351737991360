import React, { useState } from 'react'

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';


import TextInput from 'components/form/input/TextInput';
import Perfil from 'entity/Perfil';
import TabContainer from 'components/tabs/TabContainer';
import TabPanel from 'components/tabs/TabPanel';
import SelectInput from 'components/form/input/SelectInput';
import ImageInput from 'components/form/input/ImageInput';
import DateInput from 'components/form/input/DateInput';

function PerfilFormInter(props) {
    console.log("props ", props.values);
    const [tab, setTab] = useState(0)

    return <FormContent sx={{}}>
        <TabContainer tab={tab} changeTab={setTab}>
            <TabPanel label="Dados" >
                <FormContent sx={{ m: 1 }}>
                    <div style={{ display: 'flex', flex: 1, padding: 5 }}>
                        <ImageInput name='logoEmpresa' label='Selecionar Logo' imageField={null} txtSeletor="Selecionar" />
                    </div>
                    <FormRow>
                        <TextInput name="nome" label="Nome" />
                        <TextInput name="uid" label="UID" disabled />
                    </FormRow>
                    <FormRow>
                        <TextInput name="empresa" label="Empresa" />
                        <TextInput name="email" label="Email" disabled />
                    </FormRow>

                    <FormRow>
                        <TextInput name="crea" label="CREA" />
                        <TextInput name="telefone" label="Telefone" mask={"(00)[0]0000-0000"} />
                    </FormRow>
                    <FormRow>
                        <TextInput name="endereco_contato" label="Endereço" />
                    </FormRow>
                    <FormRow>
                        <SelectInput
                            name="tipo"
                            label="Tipo de Conta"
                            options={["comum", "admin", "equipe", "interna", "monitoramento"]}
                        />
                        <TextInput name="contaInternaDe" label="Token Conta Proprietária" />
                    </FormRow>
                    <FormRow>
                        <TextInput name="planoContratado.name" label="Plano contratado" />
                        <SelectInput
                            name="planoContratado.isActive"
                            label="Status"
                            toStringOptions={["Ativo", "Inativo"]}
                            options={[true, false]}
                        />
                        <DateInput name="planoContratado.purchaseDate" label="Data de aquisição" />
                    </FormRow>
                    <FormRow>
                    </FormRow>
                </FormContent >
            </TabPanel>
        </TabContainer>
    </FormContent >
}

export default function PerfilForm() {
    return (
        <DefaultEntityCrud
            EntityClass={Perfil}
            title={"Admin de Perfil"}
            routeForm="perfil"
            backRoute="consulta-perfil"
            formActions={{ novoForm: false, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <PerfilFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}