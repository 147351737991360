import React, { useCallback, useEffect, useState } from 'react'
import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import LaudoVisita from 'entity/LaudoVisita';
import { useUrlQueryUid2Field } from 'components/form';
import AreaCultivada from 'entity/AreaCultivada';
import { useInDebug, usePerfil } from 'useAuth';
import _ from 'lodash'
import { useSnapListEntity } from 'entity';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import FormRowLoading from 'components/form/FormRowLoading';
import { poligonStr2List } from 'components/MapPoligons/UtilCalcCordenadas';
import MapPoligons from 'components/MapPoligons';
import TabContainer from 'components/tabs/TabContainer';
import TabPanel from 'components/tabs/TabPanel';
import LaudosAreaCultivada from '../AreaCultivadaForm/components/LaudosDaArea';
import CardTalhaoMap from '../CardTalhaoMap';
import TabelaManejos from '../TabelaManejos';
import TotaisArea from './TotaisArea';
import DropDownButton from 'components/DropDownButton';
import { Print } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import ResumoAreaReport from 'entity/AreaCultivada/reports/ResumoAreaReport';
import { toast } from 'react-toastify';
import TabelaCaldas from '../TabelaCaldas';

function PainelControleFormInter(props) {
    const [mapTotais, setMapTotais] = useState({})
    const [talhoesComPoligono, setTalhoesComPoligono] = useState([])
    const [manejos, setManejos] = useState([])
    const [caldas, setCaldas] = useState([])
    const [showMapArea,] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const captureRef = React.createRef();
    const captureRefChart = React.createRef();

    const navigate = useNavigate()
    const IN_DEBUG = useInDebug()
    const [searchParams] = useSearchParams()
    const perfil = usePerfil()

    const { values } = props
    const { areaCultivada } = values
    const uidArea = searchParams.get("uidArea")

    useUrlQueryUid2Field('uidArea', 'areaCultivada', AreaCultivada, props.form)

    const extraQuery = useCallback(
        (col) => {
            if (uidArea) {
                col = col.where("areasCultivadasKeys." + uidArea, "==", true)
                return col;
            }
        },
        [uidArea],
    )

    const [laudos] = useSnapListEntity(LaudoVisita, extraQuery);
    console.log("laudos ", laudos);
    if (laudos)
        laudos.sort((a, b) => (b.sequencialIndex || 9999) - (a.sequencialIndex || 9999))
    const talhoes = areaCultivada?.talhoes
    //     // useReloadEntityField(AreaCultivada, "areaCultivada", props)
    //     // const [localCoordinates] = useCoordinates();
    useEffect(() => {
        if (talhoes) {
            let newArray = [];

            talhoes.forEach(t => {
                const plantios = (areaCultivada?.plantios || []).filter(p => p.talhao.nome === t.nome)

                let cultivarList = ""

                if (plantios) {
                    plantios.forEach((plantio) => {
                        if (plantio.cultivar) {
                            cultivarList += plantio.cultivar.descricao + "; "
                        }
                    })

                }

                let poligono = null;
                if (t.areaNoMapStr) {
                    poligono = {
                        title: t.nome,
                        props: { "Área": t.areaHa, "Cultivar": cultivarList ? cultivarList : " - " },
                        path: poligonStr2List(t.areaNoMapStr)
                    }
                }
                newArray.push({ ...t, poligono })
            }
            );
            setTalhoesComPoligono(newArray)

        }
    }, [areaCultivada, talhoes])
    useEffect(() => {
        if (laudos) {
            let TodosManejos = []
            let TodasCaldas = []
            laudos.forEach(l => {
                let manejos = _.get(l, "manejos.manejos")
                let caldas = _.get(l, "caldas")
                if (manejos) {
                    manejos.forEach(m => {
                        TodosManejos.push({ ...m, laudo: l })
                    });
                }
                if (caldas) {
                    caldas.forEach((c) => {
                        TodasCaldas.push({ ...c, laudo: l })
                    })
                }
            });
            setManejos(TodosManejos)
            setCaldas(TodasCaldas)
        }


    }, [laudos])
    const imprimirResumo = async () => {
        // console.log('captureRef.current', captureRef.current)
        // const canvas = await html2canvas(captureRef.current);
        setIsLoading(true)
        let imageArea = null;
        let imageChart = null;
        if (captureRef) {
            try {
                const canvas = await html2canvas(captureRef.current, {
                    useCORS: true,
                    allowTaint: false,
                    ignoreElements: (node) => {
                        if (node.nodeName === 'IFRAME') {
                            return true;
                        }
                        if (node.nodeName === 'SPAN') {
                            return true;
                        }
                        if (node.nodeName === 'BUTTON') {
                            return true;
                        }
                        if (node.nodeName === 'A') {
                            return true;
                        }
                        if (node.classList.contains('gmnoprint')) {
                            return true;
                        }
                        if (node.classList.contains('gm-style-cc')) {
                            return true;
                        }
                        return false;
                    }

                });
                imageArea = canvas.toDataURL("image/png", 1.0);
            } catch (error) {
                toast.error("Erro ao gerar resumo. Verifique os dados e tente novamente")
            } finally {
                setIsLoading(false)
            }
        }
        if (captureRefChart.current) {
            try {
                console.log('captureRefChart', captureRefChart)
                const canvas2 = await html2canvas(captureRefChart.current,);
                imageChart = canvas2.toDataURL("image/png", 1.0);
            } catch (error) {
                console.log("error ", error);
            } finally {
                setIsLoading(false)
            }
        }
        new ResumoAreaReport({ areaCultivada, perfil, imageArea, imageChart, mapTotais }).generate(IN_DEBUG);
    }

    return (<FormContent sx={{ m: 1 }}>
        <FormRowLoading condition={areaCultivada ? false : true} />
        {areaCultivada && <>
            <FormRow sx={{ p: 1 }}>
                <Box sx={{ flex: "1 1 400px" }}>

                    <Typography sx={{ flex: 1 }} variant='h6'>{areaCultivada.nomeArea}- Área{"(" + areaCultivada.unidadeArea + "): " + areaCultivada.areaHa} </Typography>
                    <Typography sx={{ flex: 1 }} variant='subtitle2'>{areaCultivada.nomeProdutor} - Safra: {areaCultivada.safra} - Cultura: {areaCultivada.cultura}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>

                    <Button disabled={isLoading} onClick={() => imprimirResumo()}><Print /> {isLoading ? "Carregando..." : " Resumo"}</Button>
                    <Button onClick={(evt) => {
                        if (evt.ctrlKey) {
                            window.open('/laudoVisita?uidArea=' + areaCultivada.uid, '_blank')
                        } else {
                            navigate('/laudoVisita?uidArea=' + areaCultivada.uid)
                        }

                    }}>Novo Laudo</Button>

                    <DropDownButton>
                        {/* <MenuItem onClick={() => setShoeMapArea(!shoeMapArea)}>{shoeMapArea ? "Ocultar Mapa" : "Exibir Mapa"}</MenuItem> */}
                        <MenuItem onClick={() => {

                            navigate('/areaCultivada?uid=' + areaCultivada.uid)


                        }}>Editar</MenuItem>
                    </DropDownButton >
                </Box>
            </FormRow>
            {showMapArea && <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ flex: "1 1 350px" }}>
                    <TotaisArea areaCultivada={areaCultivada} laudos={laudos} mapTotais={mapTotais} setMapTotais={setMapTotais} captureRefChart={captureRefChart} />
                </Box>
                <Box sx={{ flex: "1 1 350px", display: 'flex', minHeight: 350 }} ref={captureRef}>

                    {talhoesComPoligono && <MapPoligons outrosPoligonos={talhoesComPoligono.filter(t => t.poligono).map(t => t.poligono)} showCurrentLocation />}
                </Box>
            </Box>}
            <TabContainer sx={{ flex: 1, display: "flex", flexDirection: 'column' }}>
                <TabPanel label="Talhões" >
                    {(talhoesComPoligono || []).map((t, i) => <CardTalhaoMap key={i} talhao={t} areaCultivada={areaCultivada} laudos={laudos} />)}
                </TabPanel>
                <TabPanel label="Laudos" >
                    <LaudosAreaCultivada laudos={laudos} areaCultivada={areaCultivada} />
                </TabPanel>
                <TabPanel label="Manejos" >
                    <TabelaManejos manejos={manejos} areaCultivada={areaCultivada} laudos={laudos} />
                </TabPanel>
                <TabPanel label="Caldas" >
                    <TabelaCaldas caldas={caldas} areaCultivada={areaCultivada} laudos={laudos} />
                </TabPanel>
            </TabContainer>
        </>}

    </FormContent >
    )
}

export default function PainelControleForm() {

    return (
        <DefaultEntityCrud
            EntityClass={LaudoVisita}
            title={"Painel de Controle"}
            routeForm="PainelArea"
            backRoute="consulta-areaCultivada"
            formActions={{ novoForm: false, salvarForm: false, sairForm: false }}
        >
            {(props) => {
                return <PainelControleFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}
