import { Box, Button, Card, CardActions, CardContent, Link, Typography } from "@mui/material"
import { PurchaseButton } from "./components/PurchaseButton"
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from "app/AppContext";

export const Planos = () => {
    const navigate = useNavigate()
    const { plans } = useAppContext()
    const location = useLocation();
    const data = location.state;

    const handleBackroute = () => {
        if (data) {
            navigate(data.backRoute)
        } else {
            navigate("/")
        }
    }

    if (window.cordova && window.cordova.platformId === 'android') {
        return (
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Box>
                    <Typography variant='h5' textAlign='center' p={2} fontWeight='bold'>
                        Planos Disponíveis
                    </Typography>
                </Box>
                {
                    plans &&
                    plans.map((plan) => {
                        return (
                            <Card sx={{ maxWidth: '350px' }}>
                                <CardContent>
                                    <Typography variant='h6' gutterBottom pb={2}>{plan.title}</Typography>
                                    <Typography variant="body2" color="text.secondary" pb={2}>
                                        Plano com renovação mensal automática. Para realizar o cancelamento é
                                        necessário acessar as suas assinaturas através da PlayStore.
                                    </Typography>
                                    <Typography variant='h6' color="text.primary">{plan.pricing.price}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <PurchaseButton />
                                </CardActions>
                            </Card>
                        )
                    })
                }
            </Box>
        )
    } else if (window.cordova && window.cordova.platformId === 'ios') {
        return (
            <Box display='flex' gap={3} flexDirection='column' height='100%' textAlign='center' alignItems='center' justifyContent='center'>
                <Typography fontSize='20px' color="text.secondary">
                    Os planos serão disponibilizados em breve, qualquer dúvida entre em contato com nosso suporte <Link href="https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20contratar%20um%20plano." target="_blank" rel="noreferrer">clicando nesse link</Link>
                </Typography>
                <Button onClick={() => handleBackroute()}>Voltar</Button>
            </Box>
        )
    } else {
        return (
            <Box display='flex' gap={3} flexDirection='column' height='100%' textAlign='center' alignItems='center' justifyContent='center'>
                <Typography fontSize='20px' color="text.secondary">
                    Para ver os planos disponíveis, entre em contato com nosso suporte <Link href="https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20contratar%20um%20plano." target="_blank" rel="noreferrer">clicando nesse link</Link>
                </Typography>
                <Button onClick={() => handleBackroute()}>Voltar</Button>
            </Box>
        )
    }

}
