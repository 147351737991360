import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";

export default function BaseDialog({ open = false, onClose, setDialog, height, maxHeight, children,sx, ...props }) {
    const [_open, setOpen] = useState(open);
    // Callback extra para ser usado por setDialog para ouvir eventos onClose.
    const [dialogOnClose, setDialogOnClose] = useState();

    function handleClose(...args) {
        setOpen(false);

        if (onClose) {
            onClose(...args);
        }

        if (dialogOnClose) {
            dialogOnClose(...args);
        }
    }

    useEffect(() => {
        // Retorna um objeto que pode ser usado para abrir o diálogo via código,
        // e definir um listener para o evento onClose.
        if (setDialog) {
            setDialog({
                open: _open,
                setOpen: setOpen,
                // NOTA: para setar uma função como valor de state, ela deve ser retornada por outra.
                onClose: (callback) => setDialogOnClose(() => callback),
            });
        }
    }, [_open, setDialog]);

    const dialogProps = {
        onClose: handleClose,
    };

    return (
        <Dialog
            open={_open}
            onClose={() => handleClose(false)}
            sx={{ "& .MuiDialog-paper": { height: height, maxHeight: maxHeight, ...sx }, }}
            {...props}
        >
            {children(dialogProps)}
        </Dialog>
    );
}
