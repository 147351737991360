import { Box } from '@mui/material';
import { useAppContext } from 'app/AppContext';
import { OkFormContext, useFormApi } from 'components/form';
import FormContent from 'components/form/FormContent';
import useNotification from 'notification';
import React, { createRef, useEffect, useState } from 'react'
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { usePerfilContext } from 'useAuth';

export default function DefaultCrudForm({ title, routeForm, backRoute, initialValues, setNewValues, saveValues, disabledView, setDisabledView, validValues, formActions, contentProps, children }) {
    const [meta, setMeta] = useState({})
    const form = useFormApi();
    const formRef = createRef();
    const { showWarning } = useNotification()
    const { perfil } = usePerfilContext()



    useEffect(() => {
        if (form) {
            form.ref = formRef;
            if (disabledView)
                form.overPropsField = { disabled: disabledView }
        }
    }, [form, formRef, disabledView]);

    const { useSetTitle, setActionsView } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        const actions = []

        if (formActions.novoForm) {
            actions.push({
                uid: "novoForm",
                text: "Novo",
                iconName: "Add",
                sequence: "10",
                props: { disabled: disabledView, },
                action: () => {
                    form.reset();
                    navigate("/" + routeForm)
                },
            })
        }
        if (formActions.salvarForm) {
            actions.push({
                uid: "salvarForm",
                text: "Salvar",
                iconName: "Check",
                sequence: "20",
                props: { disabled: disabledView, },
                action: () => {
                    form.submit();
                },
            })
        }
        if (formActions.sairForm) {
            actions.push({
                uid: "sairForm",
                text: "Sair",
                iconName: "Close",
                sequence: "999999",
                action: () => {
                    // TODO: exibir diálogo de confirmação se o form estiver dirty.
                    if (backRoute) {

                        navigate("/" + backRoute);
                    } else {
                        navigate("/");

                    }
                },
            })
        }

        setActionsView(actions);

        return () => {
            setActionsView([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backRoute, form, navigate, routeForm, setActionsView, formActions, setNewValues]);

    useSetTitle(title);

    async function handleSubmit(values) {

        let continueSave = true;
        if (validValues) {
            continueSave = await validValues(values)
        }

        if (!perfil.planoContratado && perfil.tipo !== 'admin') {

            if (perfil.trialDate) {
                let today = new Date()
                let trialEndDate = new Date(perfil.trialDate.end)

                if (trialEndDate < today) {
                    showWarning("Seu período de testes acabou! Adquira o plano PRO acessando o menu 'Planos'.")
                    return false
                }
            }
        }

        if (perfil && perfil.planoContratado && perfil.planoContratado.isActive === false) {
            showWarning("Seu plano está inativo! Renove-o acessando o menu 'Planos'.")
            return false
        }

        if (continueSave) {
            if (saveValues) {
                console.log('vai salvar')
                saveValues(values)
            } else {
                console.log('sem metodo de salvamento')
            }
        }

    }

    return (
        <Box sx={{ flex: 1, display: 'flex', flexBasis: "100%", }}>
            <Form onSubmit={handleSubmit} initialValues={initialValues} >
                {(formProps) => {

                    const stateContext = {
                        formProps,
                        meta, setMeta: (meta) => {
                            setMeta(meta)
                            // setMeta(old => {
                            //     return _.merge(old, meta)
                            // })
                        }
                    };

                    return (
                        <OkFormContext.Provider value={stateContext}>
                            <form ref={formRef} onSubmit={formProps.handleSubmit} style={{ flex: 1, display: 'flex' }}>
                                <FormContent {...contentProps}>{children(formProps)}</FormContent>
                            </form>
                        </OkFormContext.Provider>
                    );
                }}
            </Form>
        </Box>
    )
}