import { Box } from "@mui/material";

/**
 * Componente opcional para layout de formulários que não são filhos diretos de FForm.
 * Ex: Campos dentro de abas de formulário.
 */
export default function FormContent({ sx, children, ...props }) {
    return (
        <Box sx={{ display: "flex",flexDirection:'column', flex: 1,   gap: 2, ...sx }}>
            {children}
        </Box>
    );
}
