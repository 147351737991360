import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import AutocompleteInput from 'components/form/input/AutocompleteInput'

import DateInput from 'components/form/input/DateInput'
import TextInput from 'components/form/input/TextInput'


import React, { useEffect } from 'react'





export default function ColheitaForm(props) {
    const { talhoesLaudo, areaCultivada } = props
    // console.log('ManejoForm', props.values)

    const { colheita, area } = props.values
    const { change } = props.form

    useEffect(() => {

        let total = 0

        if (colheita && colheita.producao && area) {
            total = parseFloat(colheita.producao) / parseFloat(area)
        }

        change("colheita.produtividade", total.toFixed(2))

    }, [colheita, area, change])

    return (<FormContent sx={{ p: 1 }}>

        <FormRow noWrap>
            <AutocompleteInput
                required
                name="talhao" label="Talhão"
                options={talhoesLaudo}
                disabled={!talhoesLaudo ? true : false}//{true}
                getOptionLabel={(option) => option ? option.nome : ""}
                isOptionEqualToValue={(option, value) => option.nome === value.nome}

                onSelect={(v) => {
                    console.log('onSelect', v)
                    if (v) {

                        change("area", v.areaHa)
                    }

                }}
            />
            <TextInput sx={{ flex: 1 }} type="number" name="area" label="Área Plantio" required disabled />
        </FormRow>
        <FormRow noWrap>
            <DateInput
                name="colheita.dataInicioColheita"
                label="Inicio da Colheita"
            />
            <DateInput
                name="colheita.dataFimColheita"
                label="Fim da Colheita"
            />

        </FormRow>
        <FormRow>
            <TextInput name="colheita.areaColhida" label="Área Colhida" type="number" />
        </FormRow>


        <FormRow noWrap>
            <TextInput name="colheita.producao" label={"Produção(" + areaCultivada?.unidadeProducao + ")"} type="number" />
            <TextInput name="colheita.produtividade" label={"Produtividade(" + areaCultivada?.unidadeProducao + "/" + areaCultivada?.unidadeArea + ")"} type="number" disabled />
        </FormRow>



    </FormContent>
    )
}
