import _ from "lodash";
import Report from "report";
import TableReportHelper from "report/helper";


export default class ResumoAreaReport extends Report {
    constructor({ perfil, areaCultivada, imageArea, mapTotais, imageChart }) {
        super();


        this.perfilAtual = perfil;
        this.areaCultivada = areaCultivada;
        this.imageArea = imageArea;
        this.imageChart = imageChart;
        this.mapTotais = mapTotais;

        // let safra = this.areaCultivada.safra
        // if(safra.includes("/")){
        //     safra = safra.split("/").join("-")
        // }
    }

    get title() {
        return "Resumo de Área Cultivada";
    }

    get fileName() {
        function replaceInvalidChars(fileNameToCheck) {
            var newFileName = fileNameToCheck.replace(/[^\w.-]+/g, '_');
            return newFileName;
        }

        if (this.areaCultivada) {
            return replaceInvalidChars('Resumo ' + this.areaCultivada.nomeArea + ' ' + this.areaCultivada.safra + ' ' + this.areaCultivada.cultura)
        } else {
            return this.title;
        }
    }
    adapterMeta(meta) {
        if (this.perfilAtual.logoEmpresa) {
            meta.logo = this.perfilAtual.logoEmpresa
        }
        let planoTxt = ""
        if (!this.perfilAtual.tipo || this.perfilAtual.tipo === 'comum') {
            planoTxt = "PLANO GRATUITO - "
        }
        meta.textoRodape2 = planoTxt + 'e-Laudo - www.elaudo.agr.br'
        meta.textoCabecalho1 = this.areaCultivada.nomeArea
        meta.textoCabecalho2 = this.areaCultivada.safra + ' - ' + this.areaCultivada.cultura;
        return meta
    }

    async render() {
        console.log('render', this.areaCultivada)

        this.helper = new TableReportHelper(this.areaCultivada);



        return [
            await this.renderMap(),
            await this.renderHeader(),
            await this.renderTotais(),
            // await this.renderPlantios(),

        ]
    }

    async renderMap() {
        const h = this.helper;
        let img = this.imageArea
        const content = []
        content.push(
            h.panel([
                // h.row(h.title("Talhão", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),
                h.row(h.image(img, { alignment: 'center', fit: [500, 200], })),
            ]),


        )


        return content;
    }

    renderHeader() {
        const h = this.helper;

        const content = []


        content.push(
            h.panel([
                h.row(h.title('Produtor'), h.textField('nomeProdutor')),
            ], { widths: [75, "*"] }),
            h.panel([
                h.row(h.title('Propriedade'), h.textField('nomeArea'), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.textField('areaHa')),
                h.row(h.title('Cultura'), h.textField('cultura'), h.title('Safra'), h.textField('safra')),
                // h.row(h.title('Talhão'), h.text(this.talhao.nome), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.text(this.talhao.areaHa)),
            ], { widths: [75, "*", 70, 80] })


        )


        return content;
    }
    renderPlantios() {
        const plantios = (this.areaCultivada?.plantios || []).filter(p => p.talhao.nome === this.talhao.nome)

        const h = this.helper;

        const content = []

        content.push(
            h.panel([
                h.row(h.title("Plantios", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),

            ]),


        )

        content.push(h.tablePanel([
            { title: 'Variedade', field: "cultivar.descricao", },
            { title: 'Área', field: "area", width: 35, type: "number" },
            { title: 'Ciclo', field: "cultivar.ciclo", width: 25, },
            { title: 'Início Plantio', field: "dataInicioPlantio", width: 55, },
            { title: 'Fim Plantio', field: "dataFimPlantio", width: 55, },
            // { title: 'Emergência', field: "dataEmergencia", width: 55,},
            {
                title: 'Prev. Colheita',
                getValue: (row) => {
                    let data = _.get(row, "dataPrevInicioColheita")
                    if (data) {
                        data = data.split("-").reverse().join("/")
                    }
                    return data;

                }
            },
            { title: 'Área Colhida', field: "colheita.areaColhida", width: 55, type: "number" },
            { title: 'Produção(' + this.areaCultivada.unidadeProducao + ")", field: "colheita.producao", width: 65, type: "number" },
            { title: '' + this.areaCultivada.unidadeProducao + "/" + this.areaCultivada.unidadeArea, field: "colheita.produtividade", width: 45, type: "number" },

        ], plantios))

        return content;
    }

    renderTotais() {
        const h = this.helper;

        const content = []
        const formatDate = (d) => {
            if (!d) {
                return '-'
            }
            if (d.includes("-")) {
                return d.split("-").reverse().join('/')
            }

            return d
        }

        const formatNumber = (number) => {
            if (!number) {
                let newNumber = 0
                return newNumber.toFixed(2)
            } else {
                return number
            }
        }

        content.push(
            h.panel([
                // h.row(h.title("Talhão", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),
                h.row(h.image(this.imageChart, { alignment: 'center', fit: [500, 200], })),
            ]),

            h.tablePanel([
                { title: 'Início Plantio', value: formatDate(this.mapTotais.dataInicioPlantioGeral), alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Fim Plantio', value: formatDate(this.mapTotais.dataFimPlantioGeral), alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Previsto colheita', value: formatDate(this.mapTotais.dataPrevistoColheitaGeral), alignment: "center", propsField: { fontSize: 12 } },
            ]),
            h.tablePanel([
                { title: 'Área Total Plantio', value: formatNumber(this.mapTotais.areaTotalPlantio), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Área Total Colhida', value: formatNumber(this.mapTotais.areaTotalColhida), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Porcentagem Colhida', value: formatNumber(this.mapTotais.percentColhida), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
            ]),
            h.tablePanel([
                { title: 'Produção', value: formatNumber(this.mapTotais.producao), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Produtividade Geral', value: formatNumber(this.mapTotais.produtividade), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
                { title: 'Produtividade Atual', value: formatNumber(this.mapTotais.produtividadeAreaColhida), type: 'money', alignment: "center", propsField: { fontSize: 12 } },
            ]),

        )


        return content;
    }

}