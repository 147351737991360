import EntitySearchInput from 'components/form/EntitySearch/EntitySearchInput'
import MembroEquipe from 'entity/MembroEquipe'


import React from 'react'
export default function SeletorMembroEquipe({ name = "membroEquipe", label = "Membro da Equipe", ...outerProps }) {

    const extraQuery = (query) => {

        query = query.where("conviteAceito", "==", true)

        return query
    }

    return (
        <EntitySearchInput
            name={name}
            label={label}
            EntityClass={MembroEquipe}
            addFields={["email"]}
            extraQuery={extraQuery}
            {...outerProps}
        />
    )
}
