import SelectInput from "components/form/input/SelectInput";
import Entity from "entity";
import Perfil from "entity/Perfil";
import { ValidatorHelper } from "functions";
import _ from 'lodash'

export default class AreaCultivada extends Entity {
    static get collectionUID() {
        return "AreaCultivada";
    }
    async initialize() {

        this.data.ativo = true;
        this.data.unidadeArea = "HA";
        this.data.unidadeProducao = "SC";
    }
    static async onQuery(collection) {
        let perfil = this.globalProps.perfil
        if (!perfil) {
            throw new Error(
                "this.globalProps deve receber perfil como globalProps"
            );
        }

        collection = collection.where("tokenContaProprietario", "==", Perfil.getTokenConta(perfil))
        collection = collection.where("usuariosConfirmados." + perfil.uid, "==", true)


        return collection//.where("status", "==", "ATIVO").orderBy("nome");
    }

    static get labelField() {
        return "nomeArea";
    }

    async preSave(entity) {
        let perfil = Entity.globalProps.perfil
        if (!perfil) {
            throw new Error(
                "this.globalProps deve receber perfil como globalProps"
            );
        }
        const tokenConta = Perfil.getTokenConta(perfil)

        if (!_.get(entity.data, "usuariosConfirmados." + perfil.uid)) {
            _.set(entity.data, "usuariosConfirmados." + perfil.uid, true)
        }
        if (!_.get(entity.data, "usuariosConfirmados." + tokenConta)) {
            _.set(entity.data, "usuariosConfirmados." + tokenConta, true)
        }
        if (!_.get(entity.data, "tokenContaProprietario")) {
            _.set(entity.data, "tokenContaProprietario", Perfil.getTokenConta(perfil))
        }

        //SAVE CULTURA?
    }
    static async validFieldErrors(values) {

        let v = new ValidatorHelper();

        // v.addRequired("inscricao1", "Participante 1")
        // v.addRequired("inscricao2", "Participante 2")

        let errorsMessage = v.valid(values);
        if (errorsMessage.length) {
            return errorsMessage
        }
        return null
    }



    static get searchColumns() {
        return [
            { field: "nomeArea", headerName: "Nome da Área", filter: true, mobileGridColumn: "2/ spam 2", },
            { field: "nomeProdutor", headerName: "Produtor", filter: true, mobileGridColumn: "1/ spam 2", },
            { field: "safra", headerName: "Safra", filter: true },
            { field: "cultura", headerName: "Cultura", filter: true },
            { field: "areaHa", headerName: "Área (HA)", },
            {
                headerName: "Status",
                selector: row => row.ativo ? 'ATIVO' : 'INATIVO',

                filter: {
                    render: (props) => <SelectInput sx={{ width: '100%' }} name={'status'} label={"Status"} permiteLimpar options={['ATIVO', 'INATIVO']} />,
                    compare: (value, row, filterValues) => {
                        if (filterValues.status && filterValues.status && "ATIVO") {
                            return row.ativo
                        }
                        return true
                    }
                }
            },
            { field: "dataUltimoLaudo", headerName: "Último Laudo", dataFormat: "dd/MM/yy hh:mm" },


        ];
    }
}