import { useEffect, useState } from 'react'
import { useAppContext } from "app/AppContext";
import { useNavigate } from "react-router-dom";
import _ from 'lodash'
import { usePerfil } from 'useAuth';
export default function BasicRoute({ uid, route, menuProps, element, divider, perfilAuth }) {
    const navigate = useNavigate();
    const { addMenu, addRoute, removeMenusRoutes } = useAppContext();
    let perfil = usePerfil()
    const [temPermissao, setTemPermissao] = useState(perfilAuth ? false : true)

    useEffect(() => {
        if (perfilAuth && perfil) {
            let usePerfilAuth = perfilAuth
            //TRANFORMA TUDO EM ARRAY            
            if (typeof usePerfilAuth === 'string' || usePerfilAuth instanceof String)
                usePerfilAuth = [usePerfilAuth]
            if (perfilAuth.includes(perfil.tipoPerfil)) {
                // console.log('exibir', route)
                setTemPermissao(true)
            } else {
                // console.log('ocultar', route)
                setTemPermissao(false)
            }
        }

        return () => { }
    }, [perfilAuth, perfil])



    useEffect(() => {
        let parentUid = _.get(menuProps || {}, "parent.uid")
        if (temPermissao) {

            addRoute({ uid: uid, route: route, element: element });
            if (menuProps) {

                addMenu({
                    uid: uid,
                    action: () => {
                        navigate("/" + route);
                    },
                    divider: divider,
                    ...menuProps
                });
            }

        } else {
            if (parentUid) {
                removeMenusRoutes([uid, parentUid]);
            } else {

                removeMenusRoutes([uid]);
            }
        }
        return () => {
            if (parentUid) {
                removeMenusRoutes([uid, parentUid]);
            } else {

                removeMenusRoutes([uid]);
            }
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addMenu, addRoute, navigate, removeMenusRoutes, temPermissao]);


    return (null)
}
