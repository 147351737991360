import React from 'react'
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { useUserUid } from 'useAuth';
import Perfil from 'entity/Perfil';

import { toast } from 'react-toastify';
import { usePerfil } from 'useAuth';

export const PurchaseButton = () => {
    const { store, ProductType, Platform } = window.CdvPurchase;
    const perfil = usePerfil()
    const navigate = useNavigate()
    const useruid = useUserUid();

    const BuscaProduto = () => {
        try {
            store.register([
                {
                    id: 'elaudo.assinatura.pro',
                    type: ProductType.PAID_SUBSCRIPTION,
                    platform: Platform.GOOGLE_PLAY,
                }
            ]);
            store.when()
                .productUpdated((...args) => {
                    console.log("args ", args);
                })
                .approved(finishPurchase)
                .finished(updateProfileWithPlan)
            store.initialize([Platform.GOOGLE_PLAY]);

            const myProduct1 = store.get('elaudo.assinatura.pro', Platform.GOOGLE_PLAY);
            myProduct1.getOffer().order()

        } catch (error) {
            console.log('error: ', error)
        }
    }

    function finishPurchase(p) {
        p.finish();
    }

    const updateProfileWithPlan = (transaction) => {
        let transactionOld = { ...transaction }

        if (useruid) {
            let updatePerfil = {
                uid: useruid,
                trialDate: {
                    isActive: false
                },
                planoContratado: {
                    purchaseDate: transactionOld.purchaseDate,
                    platform: transactionOld.platform,
                    isActive: true,
                    name: "Assinatura PRO (e-Laudo)",
                    planoAndroid: transactionOld
                }
            }

            Perfil.saveValuesEntity(updatePerfil)
            navigate("/")
            return toast.success("Plano adquirido com sucesso! Bem vindo ao e-Laudo Pro!")
        }
    }

    return (
        <Button onClick={BuscaProduto} disabled={perfil.planoContratado?.isActive === true ? true : false}>
            {perfil.planoContratado?.isActive === true ? "Já adquirido" : "Adquirir plano"}
        </Button>
    )
}
