import { Box } from "@mui/material";
import { createContext, createRef, useContext, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import FormContent from "./FormContent";
import _ from 'lodash'
/**
 * Cria um objeto que permite submeter o formulário.
 */
export function useFormApi() {
    const [form] = useState({
        ref: null,
        formProps: null,

        submit() {
            // console.log('submit a', this.ref)
            if (this.ref) {
                this.ref.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
            }
        },
        initTeste() {
            console.log('initTeste', this.ref)
            if (this.ref) {

            }

        },
        reset() {
            console.log(this.ref)
            console.log(this.formProps)
            if (this.formProps) {
                let formF = this.formProps.form
                formF.restart()
            }
        },

    });

    return form;
}
export const OkFormContext = createContext();

export const useOkFormContext = () => useContext(OkFormContext)
/**
 * Um formulário para uso geral na aplicação.
 */
export default function FForm({ initialValues, onSubmit, onChange, formApi, contentProps, sx, children, ...props }) {
    const formRef = createRef();
    // Armazena os valores em um memo para evitar reloads na mesma entidade.
    const values = useMemo(() => initialValues, [initialValues]);

    useEffect(() => {
        if (formApi) {
            formApi.ref = formRef;
        }
    }, [formApi, formRef]);

    function handleSubmit(...args) {
        if (onSubmit) {
            onSubmit(...args);
        }
    }

    return (
        <Box sx={{ flex: 1 }}>
            <Form onSubmit={handleSubmit} initialValues={values} {...props}>
                {(formProps) => {
                    if (onChange) {
                        // Passa o estado e a api do formulário para o evento.
                        formProps.form.subscribe((state) => onChange({ ...state, form: formProps.form }), {
                            values: true,
                        });
                    }
                    formApi.formProps = formProps

                    return (
                        <form ref={formRef} onSubmit={formProps.handleSubmit}>
                            <FormContent {...contentProps}>{children}</FormContent>
                        </form>
                    );
                }}
            </Form>
        </Box>
    );
}

export function useUrlQueryUid2Field(searchParamsUidObj, field, ObjEntity, form, onLoad) {
    const [searchParams] = useSearchParams()
    const { change } = form
    const uidObj = searchParams.get(searchParamsUidObj)

    useEffect(() => {
        if (uidObj) {
            ObjEntity.get(uidObj).then(result => {
                change(field, result.data)
                if (onLoad) onLoad(result.data)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ObjEntity, change, field, uidObj]);

}

export function useReloadEntityField(EntityClass, field, props, onReload) {
    const { change } = props.form

    let objUid = _.get(props.values, field + ".uid")
    useEffect(() => {
        let observer = null;
        if (objUid) {
            observer = EntityClass.onSnapshot(objUid, (newObj) => {
                change(field, newObj)
                if (onReload) onReload(newObj)
            })
        }
        return () => {
            if (observer) observer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objUid, change])
}