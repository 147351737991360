import { useFieldValue } from 'components/form/input/NestedField'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { GoogleMap, useJsApiLoader, Polygon, Marker, InfoWindow } from '@react-google-maps/api';
import { useCoordinates } from 'components/form/input/GeolocationButton';
import { calcCenterListCordenada, poligonList2Str, poligonStr2List } from './UtilCalcCordenadas';
import { Box, Button } from '@mui/material';

const containerStyle = {
    position: 'relative',
    flex: 1,
};

const defaultFixedCenter = { lat: -32.2291487, lng: -64.8020967, };


export default function MapPoligons({ name, outrosPoligonos, updatePoligono, pontos, captureRef, showCurrentLocation, sx }) {
    const [infoId, setInfoId] = useState(null)
    const [map, setMap] = useState(null)
    const [startCenter, setStartCenter] = useState(null)

    // const [poligono, setPoligono] = useState([])
    const [poligonoStr, setPoligonoStr] = useFieldValue(name || "SEM-NOME")
    const [coordinates] = useCoordinates();
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);


    useEffect(() => {
        if (map) {
            let listCordenada = []
            if (poligonoStr) {
                listCordenada = poligonStr2List(poligonoStr)
            }

            if (outrosPoligonos) {
                outrosPoligonos.forEach(p => {
                    if (p.path) {
                        listCordenada = listCordenada.concat(p.path)
                    }
                });
            }
            if (pontos) {
                pontos.forEach(p => {
                    if (p.position) {
                        listCordenada.push(p.position)
                    }
                });
            }

            let centerPol = calcCenterListCordenada(listCordenada)
            setStartCenter(centerPol)
            const bounds = new window.google.maps.LatLngBounds(centerPol);

            for (var i = 0; i < listCordenada.length; i++) {
                bounds.extend(listCordenada[i]);
            }

            map.fitBounds(bounds);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])


    useEffect(() => {
        if (!startCenter && coordinates) {

            setStartCenter(coordinates)
        }
    }, [coordinates, startCenter])

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPoligonoStr(poligonList2Str(nextPath));
            updatePoligono()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPoligonoStr]);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAEhmWG2jNKFUcbApO1bg-alG8TPOrDJqQ"

    })


    const onLoad = React.useCallback(function callback(map) {
        // console.log('map', map)
        // const bounds = new window.google.maps.LatLngBounds(startCenter || defaultFixedCenter);
        // map.fitBounds(bounds);
        setMap(map)
    }, [])


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    // Bind refs to current Polygon and listeners
    const onLoadPol = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );
    const onClickMap = useCallback((evt) => {
        if (name) {

            let list = poligonoStr ? poligonStr2List(poligonoStr) : []
            let ponto = {
                lat: evt.latLng.lat(), lng: evt.latLng.lng()
            }
            list.push(ponto)

            setPoligonoStr(poligonList2Str(list));
            updatePoligono()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poligonoStr]);



    // Clean up refs
    const onUnmountPol = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const removeUltimo = () => {
        let l = poligonoStr ? poligonStr2List(poligonoStr) : []
        l.pop()
        console.log('l', l)
        setPoligonoStr(poligonList2Str(l));
        updatePoligono()
    }
    const limpar = () => {
        setPoligonoStr("");
        updatePoligono()
    }
    // const customIcon3 = (opts) => Object.assign({
    //     path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
    //     fillColor: '#34495e',
    //     fillOpacity: 1,
    //     strokeColor: '#000',
    //     strokeWeight: 1,
    //     scale: 1,
    // }, opts);
    // const customIcon2 = (opts) => Object.assign({
    //     path: "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
    //     fillColor: '#f00',
    //     fillOpacity: 1,
    //     strokeColor: '#000',
    //     strokeWeight: 1,
    //     scale: 3.5,
    // }, opts);
    // const customIcon4 = (opts) => Object.assign({
    //     path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
    //     fillColor: '#f00',
    //     fillOpacity: 1,
    //     strokeColor: '#000',
    //     strokeWeight: 2,
    //     scale: 1,
    // }, opts);

    const customIconNone = (opts) => Object.assign({
        path: 'M 0,0 ',
        fillColor: '#f00',
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 2,
        scale: 1,
    }, opts);

    const blueDot = {
        fillColor: ' #4285F4',
        fillOpacity: 1,
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeColor: "#fff",
        strokeWeight: 2,
    };
    return (
        <>
            <Box sx={{ backgroundColor: '#1E3220', flex: 1, display: 'flex', ...sx }}>
                {isLoaded &&
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={startCenter || defaultFixedCenter}
                        zoom={16}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onClick={onClickMap}

                        options={{
                            streetViewControl: false,
                            mapTypeId: "hybrid"
                        }}

                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        {showCurrentLocation &&
                            <Marker position={coordinates} icon={blueDot} />
                        }

                        {poligonoStr &&

                            < Polygon
                                // Make the Polygon editable / draggable
                                editable
                                // draggable

                                path={poligonStr2List(poligonoStr)}
                                // Event used when manipulating and adding points
                                onMouseUp={onEdit}
                                // Event used when dragging the whole Polygon
                                onDragEnd={onEdit}
                                onLoad={onLoadPol}
                                onUnmount={onUnmountPol}


                                options={{
                                    strokeColor: '#006400',
                                    fillColor: "rgba(0,255,0,0.5)",
                                }}
                            />}

                        {(pontos || []).map((p, i) => {
                            let mapP = []
                            if (p.props) {
                                for (const key in p.props) {
                                    if (Object.hasOwnProperty.call(p.props, key)) {
                                        const value = p.props[key];
                                        mapP.push({ key, value })
                                    }
                                }
                            }
                            return <Marker
                                key={"ponto" + i}
                                position={p.position}
                                onMouseOver={(evt) => {
                                    setInfoId("markerp-" + p.title)

                                }}
                                onMouseOut={(evt) => {
                                    setInfoId(null)
                                }}>
                                {(infoId === "markerp-" + p.title) &&
                                    <InfoWindow>
                                        <div>
                                            <h4>{p.title}</h4>
                                            {mapP.map((v, i) => <p key={"info" + i}><b>{v.key}</b> {v.value}</p>)}
                                        </div>
                                    </InfoWindow>
                                }

                            </Marker>
                        })}
                        {(outrosPoligonos || []).map((p, i) => {
                            let mapP = []
                            if (p.props) {
                                for (const key in p.props) {
                                    if (Object.hasOwnProperty.call(p.props, key)) {
                                        const value = p.props[key];
                                        mapP.push({ key, value })
                                    }
                                }
                            }
                            return <Marker
                                key={"pontoPoli" + i}
                                icon={customIconNone({})}
                                position={calcCenterListCordenada(p.path)}                                >
                                {(infoId === "Polygon-" + p.title) &&
                                    <InfoWindow>
                                        <div>
                                            <h4>{p.title}</h4>
                                            {mapP.map((v, i) => <p><b>{v.key}</b> {v.value}</p>)}
                                        </div>
                                    </InfoWindow>
                                }

                            </Marker>
                        })}


                        {(outrosPoligonos || []).map((p, i) => {
                            return <Polygon
                                key={"outPol" + i}
                                path={p.path}
                                options={{
                                    strokeColor: '#8e9500',
                                    fillColor: '#c6cf00',
                                }}
                                onMouseOver={(evt) => {
                                    setInfoId("Polygon-" + p.title)

                                }}
                                onMouseOut={(evt) => {
                                    setInfoId(null)
                                }}
                            />
                        })}


                        <div style={{
                            position: 'absolute',
                            bottom: 9,
                            right: 45,
                            display: "flex",
                            gap: 10,
                            padding: 15
                        }}>
                            {name && <>
                                <Button style={{
                                    backgroundColor: 'rgba(255,255,255,0.7)',
                                    color: "#000"
                                }} onClick={removeUltimo}>Desfazer</Button>
                                <Button style={{
                                    backgroundColor: 'rgba(255,255,255,0.7)',
                                    color: "#000"
                                }} onClick={limpar}>Limpar</Button>
                            </>}
                            {showCurrentLocation && <Button style={{
                                backgroundColor: 'rgba(255,255,255,0.7)',
                                color: "#000"
                            }} onClick={() => {
                                console.log('coordinates', coordinates)
                                const bounds = new window.google.maps.LatLngBounds(coordinates);
                                map.fitBounds(bounds)
                            }}>Local</Button>
                            }
                        </div>
                    </GoogleMap>}

            </Box>
        </>
    )
}
