import { Box } from "@mui/material";

/**
 * Layout para ser utilizado por todos os Inputs.
 */
export default function InputBox({ sx, ...props }) {
    return (
        <Box
            sx={{
                display: "inline-flex",
                flex: 1,
                flexBasis: { xs: "100%", sm: 0 },
                ...sx,
            }}
            {...props}
        />
    );
}
