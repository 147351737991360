import { isValid } from "date-fns";
import _ from "lodash";

/**
 * Retorna se um campo contém erros, baseado no 'meta' passado pelo <PField>.
 */
export function fieldHasError(meta) {
    return Boolean(meta.error && (meta.touched || meta.modified));
}

export function requiredValidator(value) {
    // Aceita objeto Date.
    if (isValid(value)) {
        return undefined;
    }

    // checkbox
    if (value === true) return undefined

    return _.isEmpty(value) ? "Requerido" : undefined;
}
