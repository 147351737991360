import { Button } from '@mui/material';
import FormDialog from 'components/dialog/FormDialog';
import React, { useState } from 'react'
import _ from 'lodash'
import useNotification from 'notification';
export default function NewFormButton({ EntityClass, NewForm, selectItem }) {
    const [newValue, setNewValue] = useState(undefined);
    const { showSuccess } = useNotification()
    const saveAndSelect = (values) => {       

        let entity = new EntityClass()
        entity.update({ ...values });
        entity.save().then(() => {

            showSuccess("Salvo com Sucesso")

            selectItem(entity.data)
        })
    }

    return (
        <>
            <Button
                onClick={async () => {
                    let entity = await EntityClass.create();
                    setNewValue(entity.data)
                }}
                variant="contained"
                // size="small"
                sx={{ m: 1 }}
            >
                Novo
            </Button>

            {newValue && <FormDialog
                title={"Novo"}
                onClose={() => {
                    setNewValue(undefined)
                }}
                open={newValue ? true : false}
                value={newValue}
                validValues={EntityClass.validFieldErrors}
                updateValue={saveNew => {
                    if (!_.isEmpty(saveNew)) {
                        saveAndSelect(saveNew)
                    }

                    setNewValue(undefined)
                }}
            >
                {(formProps) => <NewForm {...formProps} />}
            </FormDialog>}
        </>
    )
}
