import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

export default function ExclusaoDialog({ open, handleClose, acaoDeletar }) {
    const [textoConfirm, setTextoConfirm] = useState("")
    const txtConfimDelete = "Deletar";

    const handleCloseDialog = () => {
        handleClose();
        setTextoConfirm("");
    }

    return (
        <Dialog
            open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title"
            PaperProps={{ style: { height: 'auto', flex: 1 } }}
        >
            <DialogTitle id="form-dialog-title">Excluir Registros</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Esta ação excluirá permanentemente esse registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                    <br />
                    <br />
                    Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    value={textoConfirm}
                    label={'Digite "' + txtConfimDelete + '" para confirmar a exclusão'}

                    onChange={(event) => {
                        setTextoConfirm(event.target.value);
                    }}

                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Cancelar
                </Button>
                <Button variant='contained'
                    onClick={() => {
                        handleCloseDialog()
                        acaoDeletar();
                    }}
                    color="primary"
                    disabled={txtConfimDelete.toUpperCase() !== textoConfirm.toUpperCase()}
                >
                    Excluir Registro
                </Button>
            </DialogActions>
        </Dialog>
    )
}