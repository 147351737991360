import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Insumo from 'entity/Insumo';

import DefaultEntityCrud from 'components/form/DefaultEntityCrud';
import FormContent from 'components/form/FormContent';
import FormRow from 'components/form/FormRow';
import TextInput from 'components/form/input/TextInput';
import SelectInput from 'components/form/input/SelectInput';
import AutocompleteInput from 'components/form/input/AutocompleteInput';
import SeletorInsumo from '../SeletorInsumo';

import { Box, Button, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';

export function InsumoFormInter(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [typedDelete, setTypedDelete] = useState(true)

    const { tipoMercadoria } = props.values

    const navigate = useNavigate()

    const handleOpenDeleteModal = () => setModalOpen(true)
    const handleCloseDeleteModal = () => setModalOpen(false)

    useEffect(() => {
        if (props.values.delete) {
            if (props.values.delete.toLowerCase() === "deletar") {
                setTypedDelete(false)
            } else {
                setTypedDelete(true)
            }
        }
    }, [props.values.delete, props.values.nome])

    const deleteInsumo = (uid) => {
        if (props.values.delete.toLowerCase() === "deletar") {
            try {
                Insumo.delete(uid).then(() => {
                    toast.success("Insumo excluído!")
                    handleCloseDeleteModal()
                    navigate("/consulta-insumo")
                })
            } catch (error) {
                toast.error("Oops, ocorreu algum erro no processo. Tente novamente")
            }
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxWidth: "400px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <FormContent sx={{ m: 1 }}>
            <FormRow>
                <TextInput name="descricao" label="Descrição" required />
                {props.values.uid && <Button color='error' onClick={() => handleOpenDeleteModal(true)}>Deletar</Button>}
            </FormRow>
            <FormRow>
                <AutocompleteInput
                    required
                    name="unidadeMedida" label="Unidade Medida"
                    options={Insumo.unidadeMedidas()}

                    getOptionLabel={(option) => option ? option.nome : ""}
                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                />
                <AutocompleteInput
                    required
                    name="tipoMercadoria" label="Tipo"
                    options={Insumo.tiposInsumo()}

                    getOptionLabel={(option) => option ? option.nome : ""}
                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                />
            </FormRow>
            {(tipoMercadoria?.nome === "Cultivar") &&
                <>
                    <FormRow >
                        <TextInput name="populacaoSementesMetro" label="População de Sementes por Metro" type="number" />
                        <TextInput name="ciclo" label="Ciclo" type="number" />
                        <SelectInput
                            name="flor"
                            label="Flor"
                            options={["ROXA", "BRANCA"]}
                        />
                    </FormRow>
                    <FormRow >
                        <SeletorInsumo
                            name='mercadoria'
                            label="Insumo"
                            required
                        />
                        <TextInput name="observacao" label="Observação" multiline />
                    </FormRow>
                </>}
            <Modal
                open={modalOpen}
                onClose={handleCloseDeleteModal}
            >
                <Box sx={style} display="flex" flexDirection="column" gap={2}>
                    <Typography variant='h6' component='h2'>Excluir registros</Typography>
                    <Typography>
                        Esta ação excluirá permanentemente este registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                    </Typography>
                    <Typography>
                        Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                    </Typography>
                    <form>
                        <FormContent>
                            <FormRow>
                                <TextInput label={'Digite "deletar" para confirmar a exclusão'} name="delete" initialValue="" required />
                            </FormRow>
                            <FormRow>
                                <Button color='primary' onClick={handleCloseDeleteModal}>Cancelar</Button>
                                <Button color='error' disabled={typedDelete} onClick={() => deleteInsumo(props.values.uid)}>Excluir definitivamente</Button>
                            </FormRow>
                        </FormContent>
                    </form>
                </Box>
            </Modal>
        </FormContent >
    )



}

export default function InsumoForm() {
    return (
        <DefaultEntityCrud
            EntityClass={Insumo}
            title={"Insumo"}
            routeForm="insumo"
            backRoute="consulta-insumo"
            formActions={{ novoForm: true, salvarForm: true, sairForm: true }}
        >
            {(props) => {
                return <InsumoFormInter {...props} />
            }}
        </DefaultEntityCrud>
    )
}