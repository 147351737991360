import { format, formatISO, isValid } from "date-fns";
import TextInput from "./TextInput";

/**
 * Um campo que exibe e armazena valores com o tipo Date.
 */
export default function DateInput({ showTime = false, ...props }) {
    function formatDate(date) {
        if (date && date.toDate) {
            // Firebase Timestamp.
            date = date.toDate();
        }



        if (date && date.includes && date.includes("/")) {
            return date.split('/').reverse().join("-")
        }
        if (isValid(date)) {
            let result = formatISO(date, { representation: "date" });

            if (showTime) {
                result += format(date, "'T'HH:mm");
            }

            return result;
        } else {
            return date || "";
        }
    }

    function parseDate(value) {
        // return parseISO(value);
        return value;
    }

    return (
        <TextInput
            type={showTime ? "datetime-local" : "date"}
            format={formatDate}
            parse={parseDate}
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    );
}
