import BasicRoute from "components/routes/BasicRoute";
import LaudoVisitaConsulta from "entity/LaudoVisita/components/LaudoVisitaConsulta";
import LaudoVisitaForm from "entity/LaudoVisita/components/LaudoVisitaForm";


export default function RoutesMenusLaudoVisita() {
    return <>
        <BasicRoute
            uid='LaudoVisitaConsult'
            route="consulta-laudoVisita"
            menuProps={{
                text: "Consultar Laudos",
                sequence: "000001",               
                iconName: "Search",              

            }}
            divider
            element={<LaudoVisitaConsulta />}
        />
        <BasicRoute
            uid='laudoVisitaForm'
            route="laudoVisita"
            menuProps={{
                text: "Novo Laudo",
                iconName: "ListAlt",
                sequence: "000000",
            }}
            element={<LaudoVisitaForm />}
        />



    </>;
}