import { useAppContext } from 'app/AppContext';
import DefaultConsultView from 'components/form/DefaultConsultView'
import { fetchArray } from 'entity';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { usePerfil } from 'useAuth';

export default function DefaultEntityConsult({ EntityClass, titulo, extraQuery, addPreColumns, filterValues, routeForm, noActionOnclickRow, prospTable, ...othersProps }) {
    const navigate = useNavigate();
    const { useSetTitle, setActionsView } = useAppContext();
    const [listData, setListData] = useState()
    const perfil = usePerfil()
    useSetTitle(titulo)

    const loadOptions = useCallback(async () => {
        try {

            // Carrega apenas uma vez.
            let query = await EntityClass.query();
            if (extraQuery) {
                query = await extraQuery(query);
                if (!query) {
                    return;
                }
            }
            let result = await fetchArray(query.get());

            if (filterValues) {
                filterValues(result).then(newl => setListData(newl))
            } else {
                setListData(result)
            }

        } catch (error) {
            // console.log('error loadOptions', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EntityClass, extraQuery, filterValues, perfil]);

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    useEffect(() => {
        const actions = [
            {
                uid: "novoForm",
                text: "Novo",
                iconName: "Add",
                sequence: "10",
                action: () => {
                    navigate("/" + routeForm);
                },
            },

        ];

        setActionsView(actions);

        return () => {
            setActionsView([]);
        };
    }, [navigate, routeForm, setActionsView]);
    let cols = EntityClass.searchColumns
    if (addPreColumns) {
        cols = addPreColumns.concat(EntityClass.searchColumns)
    }
    return (
        <DefaultConsultView columns={cols} values={listData} prospTable={prospTable} onRowClick={(item) => {
            if (!noActionOnclickRow) {

                navigate("/" + routeForm + "?uid=" + item.uid)
            } else {

                console.log('onRowClick item', item)
            }
        }} {...othersProps} />
    )
}
