import { TextField } from "@mui/material";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";
import InputBox from "./InputBox";
import { PField } from "./NestedField";
import { fieldHasError, requiredValidator } from "./validator";

const MaskInput = forwardRef((props, ref) => {
    const { onChange, mask, ...otherProps } = props;

    if (mask) {
        return (
            <IMaskInput
                mask={mask}
                lazy={false}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                unmask={true}
                {...otherProps}
            />
        );
    } else {
        return <input ref={ref} {...props} />;
    }
});

export default function TextInput({ name, initialValue, required, helperText, format, parse, mask, sx, ...props }) {
    return (
        <InputBox sx={sx}>
            <PField
                name={name}
                initialValue={initialValue}
                validate={required ? requiredValidator : null}
                format={format}
                parse={parse}
            >
                {(fieldProps) => {
                    const hasError = fieldHasError(fieldProps.meta);

                    return (
                        <TextField
                            variant="standard"
                            required={required}
                            error={hasError}
                            helperText={hasError ? fieldProps.meta.error : helperText}
                            sx={{ flex: 1 }}
                           
                            InputProps={
                                mask ? { inputComponent: MaskInput, inputProps: { mask: mask } } : {}
                            }
                            {...fieldProps.input}                            
                            {...props}
                        />
                    );
                }}
            </PField>
        </InputBox>
    );
}