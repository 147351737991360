import _ from "lodash";

/**
 * Compara e retorna as diferenças entre dois objetos, recursivamente (deep).
 * Fonte: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 *
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function deepDiff(object, base) {
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

/**
 * Compara e retorna as diferenças entre dois objetos, superficialmente
 * (apenas no primeiro nível).
 */
export function shallowDiff(object, base) {
    return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
            result[key] = value;
        }
    });
}

/**
 * Limpa valores do objeto mantendo somente chaves do array
  * (apenas no primeiro nível).
 */
 export function clearAtributesExeto(object, param, fieldsMantem) {
    if (object) {
        const clearObj = _.get(object, param)
        if (clearObj) {

            for (const key in clearObj) {
                if (!fieldsMantem.includes(key)) {
                    delete clearObj[key];
                }
            }
        }
    }
}



export function findUndefined(object, param) {
    // console.log('findUndefined param', param)
    if (object === undefined) {
        console.log('findUndefined AXO', param)
    } else {

        if (object && _.isObject(object)) {
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    findUndefined(element, param + "." + key)
                }
            }
        }
    }

}
