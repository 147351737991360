import { Edit, Print } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import OkTable from 'components/OkTable'
import LaudoVisita from 'entity/LaudoVisita'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePerfil } from 'useAuth'

export default function LaudosAreaCultivada({ laudos, areaCultivada }) {

    const perfil = usePerfil()
    const navigate = useNavigate();
    return (
        <>
            <OkTable 
            noDataComponent={"Sem Laudos"}
            mobileColumns={6} 
            columns={[
                {
                    width: "100px",
                    mobileOrder: 3,
                    mobileGridColumn: "4 / span 3",
                    cell: (row) => {
                        return <div style={{ display: "flex" }}>
                            <IconButton onClick={() => LaudoVisita.preparePrint(row, perfil, areaCultivada)}><Print /></IconButton>
                            <IconButton onClick={(evt) => {
                                if (evt.ctrlKey) {
                                    window.open('/laudoVisita?uid=' + row.uid, '_blank')
                                } else {
                                    navigate('/laudoVisita?uid=' + row.uid)
                                }

                            }}><Edit /></IconButton>
                        </div>
                    }
                },
                { field: "dataCadastro", name: "Data", mobileOrder: 6, dataFormat: "dd/MM/yyyy hh:mm", mobileGridColumn: "4 / span 3" },
                { name: "Status", selector: (l) => l.cancelado ? "Cancelado" : "Emitido", mobileOrder: 2, mobileGridColumn: "2 / span 2", },
                { field: "sequencialIndex", name: "Nº", mobileOrder: 1, width: "50px", },
                { field: "stringLotes", name: "Talhões", mobileOrder: 4, mobileGridColumn: "1 / span 6" },
                { field: "userNome", name: "Técnico", mobileOrder: 5, mobileGridColumn: "1 / span 3" },
                { field: "porcentagemPlantada", name: "% Plantada", mobileGridColumn: "1 / span 2", },
                { field: "porcentagemColhida", name: "% Colhida", mobileGridColumn: "3 ", },
                { field: "dataPrevistaColheita", name: "Prev.Colheita", mobileGridColumn: "4 / span 3", },

            ]} data={laudos} />
        </>
    )
}
