import { Box, Button, Typography } from "@mui/material";
import BasicRoute from "components/routes/BasicRoute";
import { useNavigate } from "react-router-dom";

export default function RoutesMenusExclusaoDados() {
    return (
        <>
            <BasicRoute
                uid='ExclusaoDados'
                route="exclusao-dados"
                divider
                menuProps={{
                    text: "Conta e Dados",
                    sequence: "600009",
                    iconName: "ManageAccounts",
                }}
                element={<Exclusaodados />}
            />
        </>
    )
}

function Exclusaodados() {
    const navigate = useNavigate()
    function handleNavigateToMyProfile() {
        navigate("/meuperfil")
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
            <Typography variant="h1" sx={{ fontSize: 34, fontWeight: "bold", textAlign: "center", mt: 4 }}>Exclusão de Conta e Dados / e-Laudo</Typography>
            <Typography variant="h2" sx={{ fontSize: 24, fontWeight: "bold", textAlign: "center", mt: 4 }}>Etapas para realizar a exclusão de conta</Typography>
            <ol>
                <li>Acesse o menu lateral <Button onClick={handleNavigateToMyProfile} style={{ backgroundColor: "transparent" }} sx={{ border: "none", background: "transparent", color: "green", padding: 0, boxShadow: "none", fontSize: 16, borderRadius: 4, ":hover": { background: "transparent", border: "none", boxShadow: "none" } }}>Meu Perfil</Button></li>
                <li>Acesse a aba "Exclusão de Conta" no menu superior</li>
                <li>Siga as instruções na tela para confirmar a exclusão.</li>
            </ol>
            <Typography variant="h2" sx={{ fontSize: 24, fontWeight: "bold", textAlign: "center", mt: 4 }}>Tipos de Dados Excluídos</Typography>
            <p>Ao solicitar a exclusão da conta, os seguintes dados serão excluídos permanentemente:</p>
            <ul>
                <li>Informações de perfil (nome, email, etc.)</li>
                <li>Histórico de atividades</li>
                <li>Dados salvos como: laudos, áreas cultivadas, etc</li>
            </ul>
        </Box>
    )
}