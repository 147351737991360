import BasicRoute from 'components/routes/BasicRoute';
import { Planos } from 'entity/Planos';

export const RoutesMenusPlanos = () => {
    return (
        <BasicRoute
            uid='planos'
            route="planos"
            menuProps={{
                text: 'Planos',
                sequence: "600002",
                iconName: "ShoppingCart",
            }}
            element={<Planos />}
        />
    )
}
