import { Box, CircularProgress, Typography } from "@mui/material";

export default function FormRowLoading({ condition, text, sx, ...props }) {
    if (condition === false) {
        return ""
    }
    return (
        <Box sx={{ display: "flex",justifyContent:"center",padding:2, ...sx }} {...props}>
            <CircularProgress /><Typography>{text}</Typography>
        </Box>
    );
}
