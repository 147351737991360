import { Box, Card, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import OkTable from 'components/OkTable'

import { LaunchSharp } from '@mui/icons-material'

export default function PainelProximasCaldas({ laudos }) {
    const [mapCaldasAreas, setMapCaldasAreas] = useState([])

    const navigate = useNavigate()
    useEffect(() => {
        let mapAreas = {}
        const getArea = (area) => {
            const { uidArea } = area
            let dataMap = mapAreas[uidArea];
            if (!dataMap) {
                dataMap = { uidArea: uidArea, area, caldas: [] }
                mapAreas[uidArea] = dataMap
            }
            return dataMap

        }
        if (laudos) {
            laudos.forEach(l => {
                // console.log('l', l)
                let caldas = _.get(l, "caldas")
                if (caldas) {
                    caldas = caldas.filter(c => c.dataPrevista)
                    let mapArea = getArea({ nome: l.nomesCulturas, uidArea: l.areaCultivadaUid, produtor: l.stringProdutores, cultura: l.cultura, safra: l.safra })
                    mapArea.caldas.push(...caldas.map(c => { return { ...c, laudo: l } }))
                    mapArea.caldas.sort((a, b) => a.dataPrevista.split('/').reverse() - b.dataPrevista.split('/').reverse())
                }

            });

            setMapCaldasAreas(Object.values(mapAreas))

        }
    }, [laudos])
    return (
        <Card sx={{ m: 2, minHeight: 260, display: "flex", flex: '1 1 650px', flexDirection: 'column' }}>
            <Typography variant='h6' sx={{ backgroundColor: "#008e6c", p: 1 }}>Próximas Caldas</Typography>
            <Box sx={{ maxHeight: 300, display: "flex", flex: '1 1 450px', flexDirection: 'column', overflow: 'auto' }}>

                {mapCaldasAreas.filter(c => c.caldas.length).map((c, i) =>
                    <Box key={i} sx={{ flex: 1, }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#008e6c", flexWrap: 'wrap', p: 1 }}>
                            <Typography variant='subtitle2' sx={{ flex: '1 1 330px' }}>Área: {c.area.nome}</Typography>
                            <Typography variant='subtitle2' sx={{ flex: 1 }}>{c.area.produtor}</Typography>
                            <Typography variant='subtitle2' >{c.area.cultura}-{c.area.safra}</Typography>
                        </Box>
                        <OkTable
                            data={c.caldas}
                            columns={[
                                {
                                    name: "Talhões",
                                    selector: (row) => {
                                        let talhaoName = ""
                                        if (row.talhoes) {
                                            row.talhoes.forEach((talhao) => {
                                                talhaoName += talhao.nome + "; "
                                            })

                                        }
                                        return talhaoName
                                    },
                                    mobileGridColumn: "1 / span 2"
                                },
                                {
                                    name: "Insumo (dose/ha)", selector: (row) => {
                                        let insumoName = ""
                                        if (row.insumos) {
                                            row.insumos.forEach((insumo) => {
                                                insumoName += insumo.tipoMercadoria.nome + " ( " + insumo.aplicacao?.doseHa + " ); "
                                            })
                                        }
                                        return insumoName
                                    }
                                },
                                { name: "Área Aplicação", field: "areaAplicacao", mobileGridRow: '2', mobileGridColumn: "3", width: "150px", },
                                {
                                    name: "Data Prevista", field: "dataPrevista", width: "150px",
                                    selector: (row) => {
                                        return row.dataPrevista ? row.dataPrevista : "Sem Data"
                                    }
                                },
                                {
                                    name: "Status", width: "150px", selector: (row) => {
                                        return row.executado ? "Executado" : "Pendente"
                                    }
                                },
                                {
                                    width: "65px",
                                    mobileGridColumn: "3", mobileGridRow: '1',
                                    cell: (row) => <IconButton onClick={(evt) => {
                                        if (evt.ctrlKey) {
                                            window.open('#/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Caldas", '_blank')
                                        } else {
                                            navigate('/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Caldas")
                                        }

                                    }}><LaunchSharp /></IconButton>
                                }
                            ]}
                        />
                    </Box>)}

            </Box>
        </Card>
    )
}
