import { Box, Button } from '@mui/material';
import { OkFormContext, useFormApi } from 'components/form';
import SubmitButton from 'components/form/SubmitButton';
import React, { createRef, useEffect, useState } from 'react'
import { Form } from 'react-final-form';
import WindowDialog from './WindowDialog'
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormContent from 'components/form/FormContent';
import useNotification from 'notification';


export default function FormDialog({ title, onClose, value, validValues, updateValue, children, contentProps, textSave, noCancelButtom,...otherProps }) {
    const form = useFormApi();
    const formRef = createRef();
    const [, _setDialog] = useState();
    const { showWarning } = useNotification()

    useEffect(() => {
        if (form) {
            form.ref = formRef;
        }
    }, [form, formRef]);

    const validValuesMensages = async (values) => {
        let errors = []
        if (validValues) {
            errors = await validValues(values)
        }
        if (errors) {
            if (errors.length) {
                showWarning(`${errors?.join("; ")}`)
                return false
            }
        }

        return true;
    }

    async function handleSubmit(values) {
        let continueSave = await validValuesMensages(values)


        if (continueSave) {
            if (updateValue) {
                // console.log('vai salvar')
                updateValue(values)
            }
        }

    }
    return (

        <WindowDialog
            setDialog={_setDialog}
            title={title}
            onClose={onClose}

            contentProps={{ sx: { p: 0, flex: 1, } }}
            maxWidth="lg"
            titleButtons={
                <Box sx={{ display: "flex", gap: 1 }}>
                    <SubmitButton size="small" startIcon={<CheckIcon />} onClick={() => {
                        form.submit();

                    }} >
                        {textSave || 'Salvar'}
                    </SubmitButton>
                    {!noCancelButtom && <Button size="small" startIcon={<CloseIcon />} onClick={onClose}>
                        Cancelar
                    </Button>}
                </Box>
            }
            hideCloseButton
            {...otherProps}
        >
            {(dialogProps) =>
                <Box sx={{ flex: 1, display: 'flex', flexBasis: "100%", }}>
                    <Form onSubmit={handleSubmit} initialValues={value}  >
                        {(formProps) => {

                            const stateContext = {
                                formProps,
                            };

                            return (
                                <OkFormContext.Provider value={stateContext}>
                                    <form ref={formRef} onSubmit={formProps.handleSubmit} style={{ flex: 1, display: 'flex' }}>
                                        <FormContent {...contentProps}>{children(formProps)}</FormContent>

                                    </form>
                                </OkFormContext.Provider>
                            );
                        }}
                    </Form>
                </Box>
            }
        </WindowDialog>
    )
}
