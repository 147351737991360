import { createTheme } from "@mui/material/styles";
//este e as pastas Entity e Routes tem codigos esclusivos deste app


// export const configFirebaseSecondary = {
//     apiKey: "AIzaSyAKLZUx-F9RBfpqLxld9Ah_D3hLLVqan9k",
//     authDomain: "appneloreproducao.firebaseapp.com",
//     projectId: "appneloreproducao",
//     storageBucket: "appneloreproducao.appspot.com",
//     messagingSenderId: "870673603009",
//     appId: "1:870673603009:web:ab11938fc867290d333740",
//     measurementId: "G-XEKFKZ9FPG"
// }

export const theme = createTheme({
    typography: {
        useNextVariants: true,
        fontFamily: `"Roboto", "Helvetica", "Arial", "Signika", sans-serif`,

    },
    palette: {
        primary: {
            light: '#008868',
            main: '#008868',
            dark: '#008868',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#00b388',
            main: '#00b388',
            dark: '#008e6c',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                variant: "contained",
            },
        },
    },
});

export const themedark = createTheme({
    typography: {
        useNextVariants: true,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,

    },

    palette: {
        mode: 'dark',
        background: {
            default: "#272727"
        },
        text: {
            primary: "#ffffff"
        },
        primary: {
            main: "#999999",
            contrastText: "#000",
        },
        secondary: {
            main: "#000000",
            contrastText: "#fff",
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                variant: "contained",
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    colorScheme: 'dark',
                },
            },
        }
    },


});