
import SelectInput from "components/form/input/SelectInput";
import Entity from "entity";
import Perfil from "entity/Perfil";



export default class Insumo extends Entity {
    static get collectionUID() {
        const { perfil } = this.globalProps
        if (!perfil) {
            throw new Error(
                "collectionUID() deve receber perfil por globalProps"
            );
        }
        let chavePermissao = Perfil.getTokenConta(perfil)
        // let chavePermissao = "T6KxdLADaEhTX3AtAXuJd2mI2vh2"
        return "Mercadorias/" + chavePermissao + "/Mecadorias";
        // return "Mercadorias"
    }
    async initialize() { }

    static async onQuery(collection) {
        return collection;
    }

    static get labelField() {
        return "descricao";
    }

    static get searchColumns() {
        return [
            { field: "descricao", headerName: "Descrição", flex: 2, filter: true },
            { field: "unidadeMedida.nome", headerName: "Unidade de Medida", flex: 1, filter: true },
            {   name: "Tipo",
                selector: (l) => l.tipoMercadoria?.nome,
                filter: {
                    render: (props) => <SelectInput sx={{ width: '100%', textAlign: 'left' }} name={'tipoMercadoria.nome'} permiteLimpar label={"Tipo"} 
                    options={[
                        "Adjuvante",
                        "Cultivar",
                        "Fertilizante",
                        "Fungicida",
                        "Herbicida",
                        "Inseticida",
                        "Inoculante",
                        "Mão-de-Obra",
                        "Outro",
                        "Semente",
                        "Tratamento de Semente",
                        "Sanitizante",
                        "Fertilizante Foliar",
                        "Biológico",
                        "Adubos",
                    ]} />,
                    compare: (value, row, filterValues) => {
                        if (filterValues.tipoMercadoria && filterValues.tipoMercadoria.nome) {
                            return row.tipoMercadoria.nome === filterValues.tipoMercadoria.nome
                        }
                        return true
                    }
                }
            },
        ];
    }

    static tiposInsumo() {
        return [
            { nome: "Adjuvante" },
            { nome: "Cultivar" },
            { nome: "Fertilizante" },
            { nome: "Fungicida" },
            { nome: "Herbicida" },
            { nome: "Inseticida" },
            { nome: "Inoculante" },
            { nome: "Mão-de-Obra" },
            { nome: "Outro" },
            { nome: "Semente" },
            { nome: "Tratamento de Semente" },
            { nome: "Sanitizante" },
            { nome: "Fertilizante Foliar" },
            { nome: "Biológico" },
            { nome: "Adubos" },
        ];
    }
    static unidadeMedidas() {
        return [
            { nome: "Unidade", sigla: "UN" },
            { nome: "Quilos", sigla: "KG" },
            { nome: "Litros", sigla: "L" },
            { nome: "Tonelada", sigla: "T" },
            { nome: "Hora", sigla: "H" },
            { nome: "DIA", sigla: "D" },
        ];
    }

}
