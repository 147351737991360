/* eslint-disable */
import React from 'react'
import pdfMake from 'pdfmake/build/pdfmake';
import { gerarDocumento as gerarPadrao } from './template/TemplatePadrao'
import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    BarChart, Bar, Cell,
    PieChart, Pie,
    ReferenceLine
} from 'recharts';

var pdfFonts = require('pdfmake/build/vfs_fonts.js');

export const templates = []

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export function addTemplate(template) {
    templates.push(template)
}
// addTemplate({ nome: "Proprio", gerar: pro })
export function getTemplate(templateNome) {
    for (const key in templates) {
        if (templates.hasOwnProperty(key)) {
            const template = templates[key];
            if (template.nome === templateNome)
                return template;
        }
    }

}

export function geraERetornaRelatorio(relatorioCustom, conteudo, meta) {

    // console.log("geraERetornaRelatorio1");

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    try {
        let templateImprimir = null;
        // console.log("geraERetornaRelatorio2");
        if (relatorioCustom.template) {
            // console.log("geraERetornaRelatorio3");
            templateImprimir = getTemplate(relatorioCustom.template)
        }
        // console.log("geraERetornaRelatorio4");
        if (templateImprimir) {
            // console.log("geraERetornaRelatorio5");
            return pdfMake.createPdf(templateImprimir.gerar(relatorioCustom, conteudo, meta))
        } else {
            // console.log("geraERetornaRelatorio6");
            return pdfMake.createPdf(gerarPadrao(relatorioCustom, conteudo, meta))
        }

    } catch (error) {
        console.log('error', error);
    }
}
export function gerarRelatorioImprimir(relatorioCustom, conteudo, meta, nomeArquivo) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let docRel = geraERetornaRelatorio(relatorioCustom, conteudo, meta);

    if (!window.cordova) {
        try {
            if (nomeArquivo) {
                docRel.download((meta.nomeArquivo ? meta.nomeArquivo : nomeArquivo) + '.pdf');
            } else {
                docRel.open();
            }
        } catch (error) {
            console.log('error', error);
        }

    } else {
        console.log("gerarRelatorioImprimir mobile1");
        console.log('wincordova.file', window.cordova.file);
        console.log('wincordova', window.cordova);


        console.log("geraERetornaRelatorio1");

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        try {
            let templateImprimir = null;
            console.log("geraERetornaRelatorio2");
            if (relatorioCustom.template) {
                console.log("geraERetornaRelatorio3");
                templateImprimir = getTemplate(relatorioCustom.template)
            }
            console.log("geraERetornaRelatorio4");
            if (templateImprimir) {
                console.log("geraERetornaRelatorio5");
                // return pdfMake.createPdf(templateImprimir.gerar(relatorioCustom, conteudo, meta))
                pdfMake.createPdf(templateImprimir.gerar(relatorioCustom, conteudo, meta)).getBuffer(function (buffer) {
                    console.log("gerarRelatorioImprimir mobile12");
                    var utf8 = new Uint8Array(buffer); // Convert to UTF-8...
                    var binaryArray = utf8.buffer; // Convert to Binary...
                    // window.requestFileSystem(window.PERSISTENT, 0, gotFS, fail);
                    window.resolveLocalFileSystemURL(window.cordova.file.externalRootDirectory).then(dirEntry => {
                        console.log("gerarRelatorioImprimir mobile13");
                        console.log('dirEntry', dirEntry);
                    })
                });

            } else {
                console.log("geraERetornaRelatorio6");

                // const pdfDocGenerator = pdfMake.createPdf(gerarPadrao(relatorioCustom, conteudo, meta))
                // pdfDocGenerator.getBase64(pdfData => {
                //     let pdf = {
                //         pdfData: `data:application/pdf;base64,${pdfData}`,
                //         onlyData: pdfData,
                //     }
                //     console.log("pdf: " + pdf);

                // })
                return pdfMake.createPdf(gerarPadrao(relatorioCustom, conteudo, meta)).getBuffer(function (buffer) {
                    console.log("gerarRelatorioImprimir mobile22");
                    var utf8 = new Uint8Array(buffer); // Convert to UTF-8...
                    var binaryArray = utf8.buffer; // Convert to Binary...

                    var fileName = meta.nomeArquivo ? meta.nomeArquivo : (nomeArquivo ? nomeArquivo : 'Relatório')
                    // window.requestFileSystem(window.PERSISTENT, 0, gotFS, fail);
                    console.log("window", window);

                    var pasta = window.cordova.file.externalRootDirectory;
                    //ios
                    pasta = window.cordova.file.dataDirectory
                    console.log("pasta", pasta);
                    window.resolveLocalFileSystemURL(pasta, function (dir) {
                        console.log("Access to the directory granted succesfully");
                        dir.getFile(fileName + '.pdf', { create: true }, function (file) {
                            console.log("File created succesfully.");
                            file.createWriter(function (fileWriter) {
                                console.log("Writing content to file");
                                fileWriter.write(binaryArray);
                                console.log("fileWriter", fileWriter);
                                console.log("FOLDER: ", pasta + fileName + ".pdf");


                                window.cordova.plugins.fileOpener2.open(
                                    pasta + (fileName + ".pdf"),
                                    'application/pdf', {
                                    error: function (e) {
                                        console.error('Error open file ', e);
                                        navigator.notification.alert("Error Opening the File.Unsupported document format.");
                                    },
                                    success: function () {

                                    }
                                }
                                );

                            }, function () {
                                alert('Unable to save file in path ' + pasta);
                            });
                        }, error => {
                            console.log('dir.getFile erro fileName', fileName)
                            console.log('dir.getFile error ', error.toString())
                        });
                    })
                });
            }

        } catch (error) {
            console.log('error', error);
        }


        pdfMake.createPdf(documentoImprimir).getBuffer(function (buffer) {
            console.log("gerarRelatorioImprimir mobile2");
            var utf8 = new Uint8Array(buffer); // Convert to UTF-8...
            binaryArray = utf8.buffer; // Convert to Binary...
            // window.requestFileSystem(window.PERSISTENT, 0, gotFS, fail);
            window.resolveLocalFileSystemURL(window.cordova.file.externalRootDirectory).then(dirEntry => {
                console.log("gerarRelatorioImprimir mobile3");
                console.log('dirEntry', dirEntry);
            })
        });
        // try {
        //     if (nomeArquivo) {
        //         console.log("gerarRelatorioImprimir mobile");
        //         docRel.download(nomeArquivo + '.pdf');
        //     } else {
        //         console.log("gerarRelatorioImprimir mobile2");
        //         docRel.open();
        //     }
        // } catch (error) {
        //     console.log('error', error);
        // }
        console.log("gerarRelatorioImprimir mobile4");

    }

}
export function imprimirDados(modelo, dados, meta) {
    // console.log('imprimirDados rel modelo', modelo)
    let propsExport = {};
    propsExport.jsonGerar = { meta, dados }
    propsExport.code_conteudo = modelo.code_conteudo
    propsExport.directCode = modelo.directCode
    propsExport.directGetGraficos = modelo.directGetGraficos
    //Para fins de teste
    if (modelo.directCode) {
        modelo.directCode(dados, meta);
    }
    // console.log('Modelo ', modelo);
    var strJsonConteudo = modelo.directCode ? modelo.directCode(dados, meta) : null;
    // console.log('Modelo direct code ', strJsonConteudo);
    // console.log('Meta antes chart ', meta);
    exportChart(propsExport, (charts) => {

        let metaUsar = {}
        if (meta) {
            metaUsar = meta;
        }
        // console.log('Meta depois chart ', meta);
        metaUsar.chats = charts

        doImprimirDados(modelo, dados, metaUsar)
    }, error => {
        console.log('Errror export chart OkRePortGerador: ', error);
    })

}

export function doImprimirDados(modelo, dados, meta) {
    if (meta && modelo && modelo.infoVersao) {
        meta.infoVersao = modelo.infoVersao;
    }


    if (!modelo.directCode) {
        let conteudoAjustado = "relatorio(dados,meta);"
        conteudoAjustado += modelo.code_conteudo;
        console.log("imprimirDados1");
        // let propsExport = {};
        // propsExport.jsonGerar = { meta, dados }
        // propsExport.code_conteudo = modelo.code_conteudo

        try {
            console.log("imprimirDados2");
            var strJsonConteudo = eval(conteudoAjustado);
            console.log("imprimirDados3");
            gerarRelatorioImprimir(modelo, strJsonConteudo, meta, meta.nomeArquivo)
            console.log("imprimirDados4");
        } catch (error) {
            console.log('Error ', error);
        }
    } else {

        try {
            // console.log('try directCode ');
            var strJsonConteudo = modelo.directCode(dados, meta);
            // console.log('directCode strJsonConteudo', strJsonConteudo)
            gerarRelatorioImprimir(modelo, strJsonConteudo, meta, meta.nomeArquivo)

        } catch (error) {
            console.log('Error directCode ', error);
        }
    }
}

//===============Grafico
function criaFuncoes1(code) {
    var arrStr = code.split('function ');
    let classe = {};
    // console.log('criaFuncoes 1111  ', arrStr);
    arrStr.forEach(m => {
        // console.log('criaFuncoes 22222  ', m);
        if (m) {
            // console.log('criaFuncoes 3333  ', m);
            let nome = m.split('(')[0].trim();
            let func = " return function " + m;
            let funJs = new Function(func)
            // funJs.bind(this)
            classe[nome] = funJs();
            // console.log('criaFuncoes 4444  ', m);
        }
    })
    return classe;
}
const svgToPng = (svg, width, height) => {

    return new Promise((resolve, reject) => {

        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');

        // Set background to white
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, width, height);

        let xml = new XMLSerializer().serializeToString(svg);
        let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
        let img = new Image(width, height);

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            let imageData = canvas.toDataURL('image/png', 1.0);
            resolve(imageData)
        }

        img.onerror = () => reject();

        img.src = dataUrl;
    })
}
const convertChart1 = async (ref, WIDTH, HEIGHT, retorno) => {
    if (ref && ref.container) {
        let svg = ref.container.children[0];
        let pngData = await svgToPng(svg, WIDTH, HEIGHT);
        retorno(pngData)
    } else if (ref && ref.gaugeDiv) {
        let svg = ref.gaugeDiv.children[0];
        let pngData = await svgToPng(svg, WIDTH, HEIGHT);
        retorno(pngData)
    } else if (ref && ref._reactInternalFiber) {
        let svg = ref._reactInternalFiber.child.stateNode;
    }
}
function getLineChart(grafico) {
    let dataNovo = [];
    grafico.columns.forEach((col, index) => {
        let dado = { name: col };
        grafico.series && grafico.series.forEach(serie => {
            if (serie.values[index])
                dado[serie.name] = serie.values[index];
        })
        dataNovo.push(dado)
    })

    return (<LineChart
        name={"lc"}
        width={500}
        height={250}
        data={dataNovo}
        isAnimationActive={false}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        {grafico.series.map((serie, index) => {
            return <Line isAnimationActive={false} dataKey={serie.name} {...serie.props} />
        })}
    </LineChart>)
}
function getBarChart(grafico) {
    const { meta, domain, ticks, width, height } = grafico;
    let dataNovo = [];
    // console.log('Grafico ', grafico);
    grafico.columns.forEach((col, index) => {
        let dado = { name: col };
        grafico.series && grafico.series.forEach(serie => {
            if (serie.values[index])
                dado[serie.name] = serie.values[index];
        })
        dataNovo.push(dado)
    })


    return (<BarChart
        name={"bc"}
        width={width || 350}
        height={height || 150}
        data={dataNovo}
        isAnimationActive={false}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={domain} ticks={ticks || null} />
        {meta && <ReferenceLine y={meta} stroke="blue" strokeDasharray="3 3" label={{ value: 'Meta', position: 'left' }} />}
        {grafico.series.map((serie, index) => {
            return <Bar isAnimationActive={false} dataKey={serie.name} {...serie.props} />
            // return <Bar fill='#20b6df' isAnimationActive={false} dataKey={serie.name} {...serie.props} />
        })}
    </BarChart>)
}

function getPieChart(grafico) {
    return (<PieChart width={400} height={400}>
        <Pie dataKey="value" isAnimationActive={false} data={grafico.values} {...grafico.props} />
    </PieChart>)
}

function getGaugeChart(grafico) {
    let valor = grafico.series && grafico.series[0] ? parseFloat(grafico.series[0].values) : 0;
    let segmentsColors = grafico.segmentsColors ? grafico.segmentsColors : [];
    let width = grafico.width ? parseInt(grafico.width) : null
    let height = grafico.height ? parseInt(grafico.height) : null
    let minValue = grafico.minValue ? parseInt(grafico.minValue) : null
    let maxValue = grafico.maxValue ? parseInt(grafico.maxValue) : 100
    let maxSegmentLabels = grafico.maxSegmentLabels ? parseInt(grafico.maxSegmentLabels) : null
    let segments = grafico.segments ? parseInt(grafico.segments) : null
    let customSegmentStops = grafico.customSegmentStops ? grafico.customSegmentStops : null
    // let config = {
    //     type: 'bar',
    //     series: [{
    //         values: [4, 5, 3, 4, 5, 3, 5, 4, 11]
    //     }]
    // }
    // console.log('Gráfico ', grafico);

    return (
        <ReactSpeedometer
            maxValue={maxValue}
            minValue={minValue || 0}
            value={valor}
            maxSegmentLabels={maxSegmentLabels || 5}
            customSegmentStops={customSegmentStops || []}
            segments={segments || 5}
            segmentColors={segmentsColors}
            width={width || 300}
            height={height || 300}
        />
    )
}

export function criaGrafico(chart, name) {



    let comp = null;

    if (chart.type == "BarChart") {
        comp = getBarChart(chart)
    }
    if (chart.type == "BarChartMult") {
        comp = getBarChartMult(chart)
    }
    if (chart.type == "CardIndicadorProps") {
        comp = getBarChartMult2(chart)
    }

    if (chart.type == "LineChart") {
        comp = getLineChart(chart)
    }

    if (chart.type == "PieChart") {
        comp = getPieChart(chart)
    }

    if (chart.type == "GaugeChart") {
        comp = getGaugeChart(chart)
    }



    return new Promise((resolve, reject) => {
        if (comp) {
            const WIDTH = comp.props.width;
            const HEIGHT = comp.props.height;

            // let compComRef = React.cloneElement(comp, {
            //     ref: ref => convertChart1(ref, WIDTH, HEIGHT, (r) => {
            //         let rr = {}
            //         rr[name] = r
            //         resolve(rr);
            //     }),
            //     isAnimationActive: false
            // })
            let compComRef = React.cloneElement(comp, {
                ref: ref => setTimeout(function () {
                    convertChart1(ref, WIDTH, HEIGHT, (r) => {
                        let rr = {};
                        rr[name] = r;
                        resolve(rr);
                    })
                }, 500),
                isAnimationActive: false
            })
            const helperDiv = document.createElement('tmp');
            ReactDOM.render(compComRef, helperDiv);
        } else {
            resolve(null);
        }
    })



}
const exportChart = (props, retorno, reject) => {
    const { dados, meta } = props.jsonGerar;
    // console.log('exportChart', props)
    if (!props.directCode) {
        const conteudo = props.code_conteudo;
        let conteudoAjustado = "relatorio(dados,meta);"
        conteudoAjustado += conteudo;

        let strJsonConteudo1 = eval(conteudoAjustado)
        let classCodigo = criaFuncoes1(conteudo);

        if (classCodigo.getGraficos) {
            // console.log('Meta ', meta);
            classCodigo.relatorio(dados, meta);
            let chs = classCodigo.getGraficos(dados, meta)

            let metaAsync = {};

            Promise.all(chs.map((c, index) => criaGrafico(c, c.name))).then(function (values) {
                values.forEach(element => {
                    metaAsync = { ...metaAsync, ...element };
                })
                retorno(metaAsync)
            })
                .catch(function (err) {
                    reject(err);
                })
        } else {
            retorno({})
        }
    } else {
        // console.log('exportChart directCode')
        if (props.directGetGraficos) {
            let chs = props.directGetGraficos(dados, meta)

            let metaAsync = {};
            // console.log('Charts direct ', chs);
            Promise.all(chs.map((c, index) => criaGrafico(c, c.name))).then(function (values) {
                values.forEach(element => {
                    metaAsync = { ...metaAsync, ...element };
                })
                retorno(metaAsync)
            })
                .catch(function (err) {
                    reject(err);
                })
        }
        else {
            retorno({})
        }

    }
}
