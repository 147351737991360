import EntitySearchInput from 'components/form/EntitySearch/EntitySearchInput'

import Insumo from 'entity/Insumo'
import React from 'react'
import { InsumoFormInter } from '../InsumoForm'

export default function SeletorInsumo({ name = "insumo", label = "Insumo", filterTipo, ...outerProps }) {
    const extraQuery = (query) => {
        if (filterTipo) {
            query = query.where("tipoMercadoria.nome", "==", filterTipo)
        }
        return query
    }

    return (
        <EntitySearchInput
            name={name}
            label={label}
            EntityClass={Insumo}
            addFields={["unidadeMedida.sigla",]}
            specificColumns={
                [
                    { field: "descricao", headerName: "Descrição", filter: true, flex: 1 },
                    { field: "unidadeMedida.nome", headerName: "Unidade", filter: true, flex: 1 },
                    { field: "tipoMercadoria.nome", headerName: "Tipo", filter: true, flex: 1 }
                ]
            }
            extraQuery={extraQuery}
            NewForm={InsumoFormInter}
            // searchButtonDisabled
            {...outerProps}
        />
    )
}
