
export const formatHoraDataLocal = horario => {
    if (!horario) {
        return ""
    }
    let split = horario.split("T");
    let splitDate = split[0].split("-");
    let r = split[1]
    r += " - " + splitDate[2] + "/" + splitDate[1]
    return r
}

// format date YYYY-MM-DD to DD-MM-YYYY
export const dateToLocal = (date) => {
    let dateFormatted = ""
    if (date) {
        dateFormatted = date.split("-").reverse().join("/")
    }
    return dateFormatted
}

export const dateHoraToStringIso = (data) => {
    let str = new Date(
        data.getTime() - data.getTimezoneOffset() * 60000
    ).toISOString();
    str = str.split(".")[0];

    return str.split("T")[0] + "T" + str.split("T")[1].substring(0, 5);
}
export const dateToStringIso = (data) => {
    let str = new Date(
        data.getTime() - data.getTimezoneOffset() * 60000
    ).toISOString();
    str = str.split(".")[0];

    return str.split("T")[0];
}
export const stringToDateIso = (strDate) => {
    let dateAux = new Date(strDate);
    dateAux = new Date(dateAux.getTime() + dateAux.getTimezoneOffset() * 60000);
    return dateAux;
}

export function dateToString(data) {

    if (!data) {
        return "";
    }
    if (typeof data === 'string') {
        data = new Date(data)
    }

    try {
        data.getMinutes()
    } catch (error) {
        data = new Date(data.seconds * 1000)
    }


    try {


        var mm = data.getMonth() + 1; // getMonth() is zero-based
        var dd = data.getDate();
        var minutes = data.getMinutes();
        var hour = data.getHours();


        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            data.getFullYear(),
        ].join('/') + " " + (hour > 9 ? '' : '0') + hour + ":" + (minutes > 9 ? '' : '0') + minutes;
    } catch (error) {

        console.log('error', error);
        console.log('data', data);

        return "erro"

    }
}