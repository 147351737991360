import { Box } from '@mui/material'
import { useSnapListEntity } from 'entity'
import AreaCultivada from 'entity/AreaCultivada'
import LaudoVisita from 'entity/LaudoVisita';

import React from 'react'
import AreasAtivas from '../AreasAtivas';
import CalendarioVisitas from '../CalendarioVisitas';
import GraficoAreasCultura from '../GraficoAreasCultura';
import PainelProximosManejos from '../PainelProximosManejos';
import PainelProximasCaldas from '../PainelProximasCaldas';

const extra = c => c.where('ativo', '==', true)

export default function TelaInicialDash() {
    const [areaCultivadas] = useSnapListEntity(AreaCultivada, extra);
    const [laudos] = useSnapListEntity(LaudoVisita);
    let laudosFiltrados = []
    if (areaCultivadas && areaCultivadas.length > 0) {
        areaCultivadas.forEach((area, i) => {
            const filter = laudos.filter((laudo) => laudo.areaCultivadaUid === area.uid)
            laudosFiltrados.push(...filter)
        })
    }

    return (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", justifyContent: 'space-evenly' }}>
            <AreasAtivas areaCultivadas={areaCultivadas} />
            <GraficoAreasCultura areaCultivadas={areaCultivadas} />
            <CalendarioVisitas laudos={laudosFiltrados} />
            <PainelProximosManejos laudos={laudosFiltrados} />
            <PainelProximasCaldas laudos={laudosFiltrados} />
        </Box>
    )
}
