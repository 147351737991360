import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Dashboard, Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import DefaultEntityConsult from 'components/form/DefaultEntityConsult'

import AreaCultivada from 'entity/AreaCultivada'

export default function AreaCultivadaConsulta() {
    // const [newArea, setNewArea] = useState()
    const navigate = useNavigate()

    const filterValues = (list) => {
        return new Promise((resolve, reject) => {
            resolve(list)
        })
    }

    const extraQuery = (query) => {
        return new Promise((resolve, reject) => {
            resolve(query)
        })
    }

    return (
        <DefaultEntityConsult
            initialFilters={{ status: "ATIVO" }}
            prospTable={{ mobileColumns: 3 }}
            EntityClass={AreaCultivada}
            titulo={"Áreas Cultivadas"}
            routeForm={'areaCultivada'}
            filterValues={filterValues}
            extraQuery={extraQuery}
            noActionOnclickRow
            addPreColumns={[{
                width: "100px",
                mobileOrder: 1,
                cell: (row) => {
                    return <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
                        <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('#/PainelArea?uidArea=' + row.uid, '_blank')
                            } else {
                                navigate('/PainelArea?uidArea=' + row.uid)
                            }

                        }}>
                            <Dashboard />
                        </IconButton>
                        <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('#/areaCultivada?uid=' + row.uid, '_blank')
                            } else {
                                navigate('/areaCultivada?uid=' + row.uid)
                            }

                        }}>
                            <Edit />
                        </IconButton>
                    </div>
                }
            }]}
        />
    )
}
