import { useTheme } from '@emotion/react'
import DataTable from "react-data-table-component";
import { Box, Button, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import FormRow from 'components/form/FormRow'
import { useFieldValue } from 'components/form/input/NestedField'
import React, { useState } from 'react'
import _ from 'lodash'
import { Add, Edit } from '@mui/icons-material';
import FormContent from 'components/form/FormContent';
import { generateUid } from 'functions';
import FormDialog from 'components/dialog/FormDialog';
import ColheitaForm from './ColheitaForm';


export default function PainelColheita({ areaCultivada }) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [editValue, setEditValue] = useState(null)
    const [listaDaView, setListaDaView] = useFieldValue('plantios')
    const [talhoesLaudo] = useFieldValue('talhoesLaudo')

    const columns = [
        {
            name: "Talhão",
            selector: (row) => _.get(row, "talhao.nome")
        },
        {
            name: "Cultivar",
            selector: (row) => _.get(row, "cultivar.descricao")
        },
        {
            name: "Área Plantada",
            width: "120px",
            selector: (row) => _.get(row, "area")
        },
        {
            name: "Colhido Atual",
            width: "120px",
            selector: (row) => _.get(row, "colheita.areaColhida")
        },
        {
            name: "Produtividade",
            selector: (row) => _.get(row, "colheita.produtividade")
        },
        {
            name: "Status",
            width: "100px",
            selector: (row) => {
                let itemOk = 'PENDENDE';
                if (row && row.colheita && row.colheita.dataInicioColheita && row.colheita.dataFimColheita && row.colheita.producao) {
                    itemOk = "OK";
                }

                return itemOk
            }
        },

        {
            name: "",
            width: "100px",
            cell: (row, index) => <EditComp row={row} index={index} />
        },

    ]


    const EditComp = ({ row, index }) => {
        return <div style={{ display: "flex", alignItems: 'center' }}>
            <IconButton onClick={() => setEditValue({ ...row, index })}><Edit /></IconButton>
        </div >
    }

    const updateValue = (value) => {
        let newArray = (listaDaView || []).slice();
        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf === -1) {

            if (Array.isArray(value.mercadoria)) {
                console.log('add multi', value)
                value.mercadoria.forEach(m => {
                    let newV = { ...value }
                    newV.uid = generateUid()
                    newV.mercadoria = m
                    newArray.push(newV)
                });

            } else {

                newArray.push(value)
            }
        } else {
            newArray[indexOf] = value;
        }
        setListaDaView(newArray)
    }

    return (
        <FormContent>
            <div style={{ display: "flex", padding: 10 }}>
                <Typography variant='h6' style={{ flex: 1 }}>
                    Colheita
                </Typography>
                <Button onClick={() => setEditValue({ uid: generateUid() })} ><Add /> Colheita</Button>
            </div >
            {mobile ?
                <>
                    {(listaDaView||[]).map((m, i) => {
                        let status = 'PENDENDE';
                        if (m && m.colheita && m.colheita.dataInicioColheita && m.colheita.dataFimColheita && m.colheita.producao) {
                            status = "OK";
                        }
                        return <Box key={i} sx={{ borderBottom: '1px solid #ababab', pb: 1 }}>
                            <FormRow noWrap>
                                <TextField
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}

                                    label="Talhão"
                                    variant="standard"

                                    value={m.talhao.nome}
                                    disabled
                                    InputProps={{ disableUnderline: true }}
                                />
                                <EditComp row={m} index={i} />

                            </FormRow>
                            <FormRow noWrap>
                                <TextField
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}
                                    label="Cultivar"
                                    variant="standard"
                                    value={m.cultivar?.descricao}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                />
                                <TextField
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}

                                    label="Status"
                                    variant="standard"

                                    value={status}
                                    disabled
                                    InputProps={{ disableUnderline: true }}
                                />

                            </FormRow>

                            <FormRow noWrap>


                                <TextField
                                    type="number"
                                    label="Área Plantada"
                                    variant="standard"
                                    value={m.area}
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                />
                                <TextField
                                    type="number"
                                    label="Área Colhida"
                                    variant="standard"
                                    value={m.colheita?.areaColhida}
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                />
                            </FormRow>
                            <FormRow noWrap>



                                <TextField
                                    type="number"
                                    label={"Produção(" + areaCultivada.unidadeProducao + ")"}
                                    variant="standard"
                                    value={m.colheita?.producao}
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                />
                                <TextField
                                    type="number"
                                    label={"Produtividade(" + areaCultivada.unidadeProducao + "/" + areaCultivada.unidadeArea + ")"}
                                    variant="standard"
                                    value={m.colheita?.produtividade}
                                    sx={{
                                        flex: 1, "& input.Mui-disabled": {
                                            color: "#000",
                                            "-webkit-text-fill-color": 'unset'
                                        },
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                />
                            </FormRow>


                        </Box>
                    })}

                </>
                :
                <DataTable
                    columns={columns}
                    data={listaDaView}
                    noDataComponent='Click em "+ Colheita" para adicionar um novo registro'
                />
            }
            {editValue && <FormDialog
                title={"Colheita"}
                onClose={() => {
                    setEditValue(undefined)
                }}
                open={editValue ? true : false}
                value={editValue}
                updateValue={values => {
                    updateValue(values);
                    setEditValue(undefined)
                }}
            >
                {(formProps) => <ColheitaForm {...formProps} talhoesLaudo={talhoesLaudo} areaCultivada={areaCultivada} />}
            </FormDialog>}

        </FormContent>
    )
}
