import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createRef, useEffect, useState } from "react";
import "./DataTable.css";
import _ from 'lodash'
import { NestedField } from "components/form/input/NestedField";

export default function DataTable({ columns, rows, idField = "uid", nestedFieldName, ...props }) {
    const [_columns, setColumns] = useState([]);
    const [_rows, setRows] = useState(null);
    const nodeRef = createRef();

    useEffect(() => {
        const c = columns.map((column) => {
            return {
                ...column,
                // Adiciona rótulos para permitir transpor a tabela em dispositivos mobile.
                renderCell: (params, r1, r2) => {
                    let render = <>

                        <Typography className="cellLabel" sx={{ display: "none", fontWeight: "bold" }}>
                            {params.colDef.headerName}
                        </Typography>
                        {column.renderCellValue ?
                            <>
                                {column.renderCellValue(params.row, params)}
                            </>
                            :
                            <Typography className="cellContent">
                                {column.valueGetter
                                    ? params.value
                                    : _.get(params.row, params.field)}
                            </Typography>
                        }
                    </>
                    if (nestedFieldName) {
                        console.log('params', params)
                        console.log('r1', r1)
                        console.log('r2', r2)

                        return <NestedField prefix={nestedFieldName} index={params.index}>{render}</NestedField>
                    }


                    return render
                },
            };
        });

        setColumns(c);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    useEffect(() => {
        const r = rows.filter((row) => row[idField] !== undefined && row[idField] !== null);

        setRows(r);
    }, [idField, rows]);
    useEffect(() => {
        function updateHeight() {
            if (nodeRef.current) {
                // Corrige altura do interior do DataGrid, quando exibido em mobile sem o header.
                // Pode ser removido se um dia o DataGrid do MUI suportar não exibir o header.
                // const virtualScroller = nodeRef.current.querySelector(".MuiDataGrid-virtualScroller");
                // const heightReference = nodeRef.current.querySelector(".Mui-resizeTriggers .expand-trigger div");


                // if (virtualScroller && heightReference) {
                //     virtualScroller.style.height = heightReference.style.height;
                // }
            }
        }
        // TODO: encontrar uma solução melhor para atualizar após o MUI.
        setTimeout(updateHeight, 500);
    }, [nodeRef]);
    return (
        <Box
            ref={nodeRef}
            className="DataTable"
            sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                flex: 1,
                textAlign: "center",
            }}
        >
            <DataGrid
                columns={_columns}
                rows={_rows || []}
                getRowId={(row) => row[idField]}
                disableSelectionOnClick
                disableColumnMenu
                hideFooterSelectedRowCount
                disableVirtualization
                initialState={{
                    pagination: {
                        pageSize: 25,
                    },
                }}
                components={{
                    // NoRowsOverlay: () => (
                    //     <CircularProgress
                    //         sx={{
                    //             mt: "4em",
                    //         }}
                    //     />
                    // ),
                    NoRowsOverlay: (p) => (
                        <>

                            {_rows ? <Stack height="100%" alignItems="center" justifyContent="center">
                                Sem registros
                            </Stack> :
                                <CircularProgress
                                    sx={{
                                        mt: "4em",
                                    }}
                                />
                            }
                        </>
                    ),

                }}

                labelRowsPerPage={"Your text"}
                // getRowClassName={(params) =>
                //     params.indexRelativeToCurrentPage % 2 === 0 ? 'MuiDataGrid-row-even' : 'MuiDataGrid-row-odd'
                // }
                {...props}
            />
        </Box >
    );
}
