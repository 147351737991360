import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from 'react-toastify';
import DrawerLayout from "./componentes/DrawerLayout";
import RoutesMenus from "../routes";
import AppControl, { AppProvider } from "./AppContext";
import RoutesControl from "./componentes/RoutesControl";
import MenuControl from "./componentes/MenuControl";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter as Router } from 'react-router-dom';
import store from "../reducer";
import { Provider } from "react-redux";
import { Suspense, useCallback, useEffect, useState } from "react";
import firebaseUtil from "../firebaseUtil";
import { CircularProgress } from "@mui/material";
import { salvarPerfilLogin } from "../dbApi";
import { NotificationProvider } from "notification";
import { theme, themedark } from "AppPersonalise";
import { useCookies } from "react-cookie";
import { CookiesProvider } from "react-cookie";
import { PerfilProvider, usePerfil } from "useAuth";
function AppCore() {
    const perfil = usePerfil()
    const [themeUsar, setThemeUsar] = useState(theme);
    // console.log('perfil APPPPCORE', perfil)
    const [cookies, setCookie] = useCookies(["theme"]);

    const toggleTheme = useCallback(
        () => {
            console.log("cookie", cookies.theme)
            if (themeUsar.palette.mode === 'dark') {
                setCookie("theme", "theme", {
                    path: "/"
                });
                setThemeUsar(theme)
            } else {
                setCookie("theme", "themedark", {
                    path: "/"
                });
                setThemeUsar(themedark)
            }
        },
        [cookies.theme, setCookie, themeUsar.palette.mode],
    )

    return <>
        {perfil === undefined ? <div>Loading User...</div> :
            <>
                <RoutesMenus />
                <DrawerLayout RoutesContainer={RoutesControl} MenuContainer={MenuControl} toggleTheme={toggleTheme} />
            </>}
    </>
}

function App() {
    // console.log('RENDER APPPPPPPPPPPP')
    const [firebaseInitialized, setFirebaseInitialized] = useState(false);
    const [themeUsar, setThemeUsar] = useState(theme);
    const [cookies,] = useCookies(["theme"]);
    const valueAppContext = AppControl();
    // const perfil = usePerfil()
    // console.log('perfil MAXXXXXXXXXXXXXX', perfil)
    useEffect(() => {
        firebaseUtil.isInitialized(valueAppContext.setUserAux).then((val) => {
            setFirebaseInitialized(val);

            if (val) {
                salvarPerfilLogin(val);
                if (cookies.theme === 'themedark') setThemeUsar(themedark)
                else setThemeUsar(theme)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return firebaseInitialized !== false ? (
        <CookiesProvider>
            <Suspense fallback={<CircularProgress />}>
                <Provider store={store}>
                    <Router>
                        <ThemeProvider theme={themeUsar}>
                            <NotificationProvider>
                                <AppProvider valueUsar={valueAppContext}>
                                    <PerfilProvider>
                                        <AppCore />
                                        <ToastContainer closeOnClick={true} autoClose={1500} />
                                        {/* <RoutesMenus />
                                        {perfil === undefined ? <div>Loading User...</div> :
                                            <DrawerLayout RoutesContainer={RoutesControl} MenuContainer={MenuControl} toggleTheme={toggleTheme} />} */}
                                    </PerfilProvider>
                                </AppProvider>
                            </NotificationProvider>
                        </ThemeProvider>
                    </Router>
                </Provider>
            </Suspense>
        </CookiesProvider>
    ) : (
        <div className="loader" style={{ textAlign: "center", marginTop: "1em" }}>
            <CircularProgress />
        </div>
    );
}

export default App;
