import React, { useState } from 'react'

import { useFieldValue } from 'components/form/input/NestedField'
import FormRow from 'components/form/FormRow'
import FormDialog from 'components/dialog/FormDialog';
import DropDownButton from 'components/DropDownButton';
import FormContent from 'components/form/FormContent';
import OkTable from 'components/OkTable';
import DataTable from "react-data-table-component";

import ManejoForm from './ManejoForm';
import CaldaForm from "./CaldaForm";

import { Add, Edit } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Typography, } from '@mui/material'

import { generateUid } from 'functions';
import _ from 'lodash'

const CopiarParaComp = ({ row, index, copiarParaManejo, copiarParaCalda, talhoesLaudo }) => {
    const [talhoesDupli, setTalhoesDupli] = useState(null)

    if (copiarParaManejo) {
        return (
            <>
                <MenuItem onClick={() => {
                    setTalhoesDupli([]);
                }}>Copiar Para</MenuItem>
                {talhoesDupli && <FormDialog
                    textSave="Copiar"
                    title={"Copiar Para"}
                    onClose={() => {
                        setTalhoesDupli(undefined)
                    }}
                    open={talhoesDupli ? true : false}
                    value={talhoesDupli}
                    updateValue={values => {
                        console.log('updateValue', values)
                        if (values) {
                            values.sort((a, b) => a.nome > b.nome ? 1 : -1)
                            copiarParaManejo(row, values)
                        }
                        setTalhoesDupli(undefined)
                    }}
                >
                    {(formProps) => <FormContent sx={{ p: 1 }}>
                        <FormRow>
                            <DataTable
                                columns={[{
                                    name: "Talhão",
                                    selector: (row) => _.get(row, "nome")
                                },]}
                                data={talhoesLaudo.filter(f => f.nome !== row.talhao.nome)}
                                selectableRows
                                onSelectedRowsChange={state => {

                                    setTalhoesDupli(state.selectedRows);
                                }}
                            />
                        </FormRow>
                    </FormContent>
                    }
                </FormDialog>}
            </>
        )
    } else if (copiarParaCalda) {
        return (
            <>
                <MenuItem onClick={() => {
                    setTalhoesDupli([]);
                }}>Copiar Para</MenuItem>
                {talhoesDupli && <FormDialog
                    textSave="Copiar"
                    title={"Copiar Para"}
                    onClose={() => {
                        setTalhoesDupli(undefined)
                    }}
                    open={talhoesDupli ? true : false}
                    value={talhoesDupli}
                    updateValue={values => {
                        if (values) {
                            values.sort((a, b) => a.nome > b.nome ? 1 : -1)
                            copiarParaCalda(row, values)
                        }
                        setTalhoesDupli(undefined)
                    }}
                >
                    {(formProps) => <FormContent sx={{ p: 1 }}>
                        <FormRow>
                            <DataTable
                                columns={[{
                                    name: "Talhão",
                                    selector: (row) => _.get(row, "nome")
                                },]}
                                data={talhoesLaudo.filter(f => f.nome !== row.talhoes.nome)}
                                selectableRows
                                onSelectedRowsChange={state => {
                                    setTalhoesDupli(state.selectedRows);
                                }}
                            />
                        </FormRow>
                    </FormContent>
                    }
                </FormDialog>}
            </>
        )
    }

}

export default function PainelManejos({ areaCultivada }) {
    const [editValue, setEditValue] = useState(null)
    const [editValueCalda, setEditValueCalda] = useState(null)
    const [manejos, setManejos] = useFieldValue('manejos.manejos')
    const [caldas, setCaldas] = useFieldValue('caldas')
    const [talhoesLaudo] = useFieldValue('talhoesLaudo')

    const columnsManejo = [
        {
            mc: "1 / span 2",
            name: "Talhão",
            selector: (row) => _.get(row, "talhao.nome")
        },

        {
            mc: "1 / span 2",
            name: "Insumo",
            selector: (row) => _.get(row, "mercadoria.descricao") + " - " + _.get(row, "mercadoria.unidadeMedida.sigla")
        },
        {
            name: "Área",
            width: "100px",
            selector: (row) => _.get(row, "area")
        },
        {
            name: "Dose",
            width: "100px",
            selector: (row) => _.get(row, "dose")
        },
        {
            name: "Un. medida",
            width: "150px",
            selector: (row) => _.get(row, "mercadoria.unidadeMedida.nome") + "(" + _.get(row, "mercadoria.unidadeMedida.sigla") + ")"
        },
        {
            name: "QT",
            width: "100px",
            selector: (row) => _.get(row, "quantidadeTotal")
        },
        {
            width: "130px",
            name: "Data Prevista",
            selector: (row) => {
                if (row.dataPrevista && row.dataPrevista.includes("-")) {
                    row.dataPrevista = row.dataPrevista.split('-').reverse().join("/")
                } else {
                    return _.get(row, "dataPrevista")
                }
                return _.get(row, 'dataPrevista')
            }
        },
        {
            mr: 2, mc: 3,
            name: "Status", width: "100px", selector: (row) => {
                return row.executado ? "Executado" : "Pendente"
            }
        },
        {
            mr: 1, mc: 3,
            width: "100px",
            cell: (row, index) => <EditComp row={row} index={index} />
        },

    ]

    const columnsCalda = [
        {
            mc: "1 / span 2",
            width: "31%",
            name: "Talhão",
            selector: (row, index) => {
                let talhaoName = ""
                if (row.talhoes) {
                    row.talhoes.forEach((talhao) => {
                        talhaoName += talhao.nome + "; "
                    })

                }
                return talhaoName
            }
        },

        {
            mc: "1 / span 2",
            width: "31%",
            name: "Insumo",
            selector: (row) => {
                let insumoName = ""
                if (row.insumos) {
                    row.insumos.forEach((insumo) => {
                        insumoName += insumo.descricao + " (" + insumo.unidadeMedida.sigla + "); "
                    })

                }
                return insumoName
            }
        },
        {
            name: "Área Total",
            // width: "100px",
            selector: (row) => _.get(row, "areaAplicacao")
        },
        {
            // width: "130px",
            name: "Data Prevista",
            selector: (row) => {
                if (row.dataPrevista && row.dataPrevista.includes("-")) {
                    row.dataPrevista = row.dataPrevista.split('-').reverse().join("/")
                } else {
                    return _.get(row, "dataPrevista")
                }
                return _.get(row, 'dataPrevista')
            }
        },
        {
            mr: 2, mc: 3,
            name: "Status", width: "100px", selector: (row) => {
                return row.executado ? "Executado" : "Pendente"
            }
        },
        {
            mr: 1, mc: 3,
            // width: "50px",
            cell: (row, index) => <EditCompCalda row={row} index={index} />
        },

    ]


    const EditComp = ({ row, index }) => {
        return <div style={{ display: "flex", alignItems: 'center' }}>
            <IconButton onClick={() => setEditValue({ ...row, index })}><Edit /></IconButton>
            <DropDownButton>
                <CopiarParaComp row={row} index={index} copiarParaManejo={copiarParaManejo} copiarParaCalda={copiarParaCalda} talhoesLaudo={talhoesLaudo} />
                <MenuItem onClick={() => duplicarManejo(row)}>Duplicar</MenuItem>
                <MenuItem onClick={() => removerManejo({ ...row, index })}>Remover</MenuItem>
            </DropDownButton >
        </div >
    }

    const EditCompCalda = ({ row, index }) => {
        return <div style={{ display: "flex", alignItems: 'center' }}>
            <IconButton onClick={() => setEditValueCalda({ ...row, index })}><Edit /></IconButton>
            <DropDownButton>
                <CopiarParaComp row={row} index={index} copiarParaCalda={copiarParaCalda} talhoesLaudo={talhoesLaudo} />
                <MenuItem onClick={() => duplicarCalda(row)}>Duplicar</MenuItem>
                <MenuItem onClick={() => removerCalda({ ...row, index })}>Remover</MenuItem>
            </DropDownButton >
        </div >
    }

    const duplicarManejo = (manejo) => {
        let newV = { ...manejo }
        newV.uid = generateUid()
        delete newV.index;

        updateValueManejo(newV)
    }

    const duplicarCalda = (calda) => {
        let newV = { ...calda }
        newV.uid = generateUid()
        delete newV.index;

        updateValueCalda(newV)
    }

    const copiarParaManejo = (manejo, talhoes) => {
        let newArray = (manejos || []).slice();
        talhoes.forEach(t => {
            let newV = { ...manejo }
            newV.area = t.areaHa
            newV.quantidadeTotal = (parseFloat(t.areaHa) * parseFloat(newV.dose)).toString()
            newV.uid = generateUid()
            delete newV.index;
            newV.talhao = t

            newArray.push(newV)
        })


        setManejos(newArray)
    }

    const copiarParaCalda = (calda, talhoes) => {
        let newArray = (caldas || []).slice();
        talhoes.forEach(t => {
 console.log("t ", t);
            let newV = { ...calda }
 console.log("newV ", newV);
            newV.area = t.areaHa
            newV.quantidadeTotal = (parseFloat(t.areaHa) * parseFloat(newV.dose)).toString()
            newV.uid = generateUid()
            delete newV.index;
            newV.talhao = t

            newArray.push(newV)
        })
        setCaldas(newArray)
    }

    const updateValueManejo = (value) => {
        let newArray = (manejos || []).slice();
        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf === -1) {

            if (Array.isArray(value.mercadoria)) {
                console.log('add multi', value)
                value.mercadoria.forEach(m => {
                    let newV = { ...value }
                    newV.uid = generateUid()
                    newV.mercadoria = m
                    newArray.push(newV)
                });

            } else {

                newArray.push(value)
            }
        } else {
            newArray[indexOf] = value;
        }
        setManejos(newArray)
    }

    const updateValueCalda = (value) => {
        let newArray = (caldas || []).slice();
        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf === -1) {

            if (Array.isArray(value.mercadoria)) {
                console.log('add multi', value)
                value.mercadoria.forEach(m => {
                    let newV = { ...value }
                    newV.uid = generateUid()
                    newV.mercadoria = m
                    newArray.push(newV)
                });

            } else {

                newArray.push(value)
            }
        } else {
            newArray[indexOf] = value;
        }
        setCaldas(newArray)
    }

    const removerManejo = (value) => {
        console.log('remover', value)

        let newArray = (manejos || []).slice();

        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf !== -1) {
            newArray.splice(indexOf, 1)
        }
        setManejos(newArray)
    }

    const removerCalda = (value) => {
        let newArray = (caldas || []).slice();

        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf !== -1) {
            newArray.splice(indexOf, 1)
        }
        setCaldas(newArray)
    }

    return (
        <FormContent>

            {/* MANEJO */}
            <div style={{ display: "flex", padding: 10 }}>
                <Typography variant='h6' style={{ flex: 1 }}>
                    Manejo Único
                </Typography>
                <Button onClick={() => setEditValue({ uid: generateUid() })} ><Add /> Manejo</Button>
            </div >
            <OkTable
                mobileColumns={"1fr 60px 80px"}
                columns={columnsManejo}
                data={manejos}
                noDataComponent='Click em "+ Manejo" para adicionar uma nova recomendação'
            />

            {editValue && <FormDialog
                title={"Manejo"}
                onClose={() => {
                    setEditValue(undefined)
                }}
                open={editValue ? true : false}
                value={editValue}
                updateValue={values => {
                    updateValueManejo(values);
                    setEditValue(undefined)
                }}
            >
                {(formProps) => <ManejoForm {...formProps} talhoesLaudo={talhoesLaudo} />}
            </FormDialog>}

            {/* FIM MANEJO */}

            {/* CALDA */}
            <div style={{ display: "flex", padding: 10 }}>
                <Typography variant='h6' style={{ flex: 1 }}>
                    Calda
                </Typography>
                <Button onClick={() => setEditValueCalda({ uid: generateUid() })} ><Add /> Calda</Button>
            </div >
            <OkTable
                mobileColumns={"1fr 60px 80px"}
                columns={columnsCalda}
                data={caldas}
                noDataComponent='Click em "+ Calda" para adicionar uma nova recomendação'
            />

            {editValueCalda && <FormDialog
                title={"Calda"}
                onClose={() => {
                    setEditValueCalda(undefined)
                }}
                open={editValueCalda ? true : false}
                value={editValueCalda}
                updateValue={values => {
                    updateValueCalda(values);
                    setEditValueCalda(undefined)
                }}
            >
                {(formProps) => <CaldaForm {...formProps} talhoesLaudo={talhoesLaudo} />}
            </FormDialog>}

            {/* talhões, insumos, área total, data prevista e status */}

            {/* FIM CALDA */}


        </FormContent>
    )
}
