
import Entity from "entity";
import Perfil from "entity/Perfil";



export default class MembroEquipe extends Entity {
    static get collectionUID() {
        const { perfil } = this.globalProps
        if (!perfil) {
            throw new Error(
                "collectionUID() deve receber perfil por globalProps"
            );
        }
        let chavePermissao = Perfil.getTokenConta(perfil)
        return "User/" + chavePermissao + "/Equipe";
    }
    async initialize() { }

    static async onQuery(collection) {
        return collection;
    }

    static get labelField() {
        return "nome";
    }

    static get searchColumns() {
        return [
            { field: "nome", headerName: "Nome", filter: true },
            { field: "email", headerName: "Email", filter: true },


        ];
    }


}
