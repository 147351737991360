import { Autocomplete, TextField } from "@mui/material";
import InputBox from "./InputBox";
import { PField } from "./NestedField";
import { fieldHasError, requiredValidator } from "./validator";

export default function AutocompleteInput({ name, label, required, helperText, initialValue, sx, multiple, children, onSelect, formatText, options, ...props }) {
    return (
        <InputBox sx={sx}>
            <PField name={name} initialValue={initialValue} validate={required ? requiredValidator : null}>
                {(fieldProps) => {
                    const hasError = fieldHasError(fieldProps.meta);
                    let optionsUse = [...options] || []

                    if (!fieldProps.input.value) {
                        optionsUse.push("")
                    }
                    return (
                        <Autocomplete
                            options={optionsUse}
                            multiple={multiple}
                            sx={{ flex: 1 }}
                            renderInput={(params) => (
                                <TextField
                                    label={label}
                                    required={required}
                                    error={hasError}
                                    helperText={hasError ? fieldProps.meta.error : helperText}
                                    variant="standard"

                                    onChange={(event) => {
                                        let value = event.target.value
                                        
                                        if (props.freeSolo) {
                                            if (formatText) {
                                                value = formatText(value)
                                            }
                                            console.log('value', value)
                                            if (onSelect) onSelect(value)
                                            fieldProps.input.onChange(value);
                                        }
                                    }}
                                    {...params}
                                />
                            )}
                            renderOption={(propsR, option) =>
                                option ? (
                                    <li {...propsR} key={props.getOptionLabel(option)}>
                                        {props.getOptionLabel(option)}
                                    </li>
                                ) : null
                            }
                            {...fieldProps.input}
                            {...props}
                            value={multiple ? fieldProps.input.value || [] : fieldProps.input.value}
                            onChange={(event, value) => {
                                // Necessário para passar os valores do MUI para o Final Form imediatamente.
                                if (onSelect) onSelect(value)
                                fieldProps.input.onChange(value);
                            }}
                        />
                    );
                }}
            </PField>
        </InputBox>
    );
}
