import React from 'react'
import FormDialog from './FormDialog'

export default function FormDialogState({ stateValeu, setStateValue, updateValue,children, ...props }) {
    return (<>
        {stateValeu && <FormDialog
            onClose={() => {
                setStateValue(undefined)
            }}
            open={stateValeu ? true : false}
            value={stateValeu}

            updateValue={values => {
                if (values) {
                    updateValue(values)
                }
                setStateValue(undefined)
            }}
            {...props}
        >
            
            {(formProps) => children(formProps)}
        </FormDialog>
        }
    </>)
}
