
import Entity from "entity";
import Perfil from "entity/Perfil";



export default class Cultura extends Entity {
    static get collectionUID() {
        const { perfil } = this.globalProps
        if (!perfil) {
            throw new Error(
                "collectionUID() deve receber perfil por globalProps"
            );
        }
        let chavePermissao = Perfil.getTokenConta(perfil)
        // let chavePermissao = "T6KxdLADaEhTX3AtAXuJd2mI2vh2"
        return "Cultura/" + chavePermissao + "/Cultura";
        // return "Mercadorias"
    }
    async initialize() {
        this.data.fases = Cultura.getFasesPadrao.itensFase.map(f => f.nome)
    }



    static async onQuery(collection) {
        return collection;
    }

    static get labelField() {
        return "nome";
    }

    static get searchColumns() {
        return [
            { field: "nome", headerName: "Nome", flex: 2, filter: true },


        ];
    }

    static get getFasesPadrao() {
        let fases = {}
        fases.itensFase = [
            { nome: "Germinativo" },
            { nome: "Vegetativo" },
            { nome: "Florescimento" },
            { nome: "Frutificação" },
            { nome: "Mat. Fisiológica" },
            { nome: "Perc. Colhido" }
        ]
        return fases
    }

}
