import { useTheme } from "@emotion/react";
import { Check, Edit, MenuOpen } from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, MenuItem, SpeedDial, useMediaQuery } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import PainelPageMobile from "../PainelPageMobile";

export default function ContainerPainelMobile({ pageMobile, disabled = false, setPageMobile, values, children, startMenu, indexMenu = 0, sx, ...props }) {


    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [newChildren, setNewChildren] = useState([...children])
    const MenuRapido = (p) => <PainelPageMobile {...p} sx={{ gap: 0 }} >
        {children.map((c, i) => {
            return <MenuItem
                sx={{
                    borderBottom: '1px solid #ababab'
                }}
                onClick={() => setPageMobile(c.props.title)}
                key={i}
            // onClick={() => setPageMobile(c.props.title)}
            >
                <ListItemIcon>
                    {!c.props.icon ? <MenuOpen sx={{ color: '#008868' }} /> : <img src={c.props.icon} width="25px" alt={c.props.title} />}
                </ListItemIcon>
                <ListItemText>{c.props.title}</ListItemText>

                {c.props.funcStatus && <>
                    {c.props.funcStatus(values) ?
                        <Check sx={{ color: '#008868' }} />
                        :
                        <Edit sx={{ color: '#f44336' }} />
                    }
                </>}

            </MenuItem>
        })}

    </PainelPageMobile >
    const menuUsar = <MenuRapido title="Menu Rápido" />;
    useEffect(() => {
        if (mobile) {
            let n = [...children]
            n.splice(indexMenu, 0, menuUsar);
            const childrenWithProps = React.Children.map(n, (child, index) => {
                // Checking isValidElement is the safe way and avoids a
                // typescript error too.
                if (React.isValidElement(child)) {

                    return React.cloneElement(child, { pageMobile, setPageMobile, next: (n[index + 1]?.props.title), previous: (n[index - 1]?.props.title) });
                }
                return child;
            });
            setNewChildren(childrenWithProps)
        } else {
            let n = [...children]

            setNewChildren(n)
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, mobile, pageMobile,])

    if (disabled) {
        return (
            <Box sx={{
                display: "flex", flexDirection: 'column',
                maxWidth: 1200,
                width: "100%",
                margin: "0 auto",
                flex: 1, ...sx,
            }}>
                {newChildren}
            </Box>
        )
    }

    return (
        <Box sx={{
            display: "flex", flexDirection: 'column',
            maxWidth: 1200,
            width: "100%",
            margin: "0 auto",
            flex: 1, ...sx
        }}>
            {newChildren}

            {mobile &&
                <SpeedDial
                    ariaLabel="SpeedDial"
                    sx={{ position: 'fixed', bottom: 16, left: 16, disabled: disabled }}
                    onClick={() => setPageMobile('Menu Rápido')}

                    icon={<GridMenuIcon />}
                >
                    {/* {(newChildren ? newChildren.slice() : []).reverse().map((c) => {

                    if (c.props.notFastButton) {
                        return null
                    }
                    return <SpeedDialAction
                        sx={{
                            maxWidth: 'none',
                            "& .MuiSpeedDialAction-staticTooltipLabel": {
                                whiteSpace: "nowrap",
                                maxWidth: "none",

                            }
                        }}
                        key={c.props.title}
                        icon={c.props.icon ? <img src={c.props.icon} width="25px" alt={c.props.title} /> : <MenuOpen sx={{ color: '#008868' }} />}
                        tooltipPlacement={"right"}
                        tooltipOpen
                        tooltipTitle={c.props.title}
                        onClick={() => setPageMobile(c.props.title)}
                    />
                })} */}

                </SpeedDial>
            }

        </Box>
    );
}
