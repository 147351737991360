import { useTheme } from '@emotion/react';
import { Edit, LaunchSharp } from '@mui/icons-material';
import { Card, IconButton, Typography, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'

import React, { useEffect, useState } from 'react'
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import './style.css'
export default function CalendarioVisitas({ laudos }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate()
  const [selectDate, setSelectDate] = useState(null)
  const [datasVisita, setDatasVisita] = useState({})
  useEffect(() => {
    if (laudos) {
      let mapDatas = {}
      const getData = (data) => {
        let dataMap = mapDatas[data];
        if (!dataMap) {
          dataMap = { data: data, laudos: [], proximaArea: [] }
          mapDatas[data] = dataMap
        }
        return dataMap

      }
      laudos.forEach(l => {
        if (l.dataCadastro) {
          let dataCadastro = format(l.dataCadastro.toDate(), 'yyyy-MM-dd')
          let dataMap = getData(dataCadastro);
          dataMap.laudos.push(l)
        }
        if (l.dataProxVisita) {
          let dataVisita = l.dataProxVisita
          if (dataVisita.includes("/")) {
            dataVisita = dataVisita.split('/').reverse().join("-")
          }
          let dataMap = getData(dataVisita);
          dataMap.proximaArea.push({ nome: l.nomesCulturas, uidArea: l.areaCultivadaUid, cultura: l.cultura, safra: l.safra })
        }
      });

      setDatasVisita(mapDatas)
    }
  }, [laudos])

  const CardData = ({ data }) => {
    if (!data) {
      return <div />
    }
    let dataVerifica = format(data, 'yyyy-MM-dd')
    let mapData = datasVisita[dataVerifica]

    if (!mapData) {
      return <div />
    }
    const uidsVistados = mapData.laudos.map(l => l.areaCultivadaUid)
    return <div style={{ paddingTop: 10 }}>
      {mapData.laudos.length ? <Typography variant='subtitle2' color="#fff">Laudos</Typography> : ""}
      {mapData.laudos.map((l, i) => <div key={i} style={{ display: "flex", borderBottom: "1px solid #000" }}>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant='caption'>{l.nomesCulturas}</Typography>
          <Typography variant='caption'>Nº {l.sequencialIndex}-{l.cultura}-{l.safra}</Typography>
        </div>
        <IconButton onClick={(evt) => {
          if (evt.ctrlKey) {
            window.open('/laudoVisita?uid=' + l.uid, '_blank')
          } else {
            navigate('/laudoVisita?uid=' + l.uid)
          }

        }}><Edit /></IconButton>
      </div>)
      }
      {mapData.proximaArea.length ? <Typography variant='subtitle2' color="#fff">Novas Visitas</Typography> : ""}
      {mapData.proximaArea.filter(a => !uidsVistados.includes(a.uidArea)).map((a, i) => <div key={i} style={{ display: "flex", borderBottom: "1px solid #000", alignItems: "center" }}>


        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant='caption'>{a.nome}</Typography>
          <Typography variant='caption'>{a.cultura}-{a.safra}</Typography>
        </div>
        <IconButton onClick={(evt) => {
          if (evt.ctrlKey) {
            window.open('/laudoVisita?uidArea=' + a.uidArea, '_blank')
          } else {
            navigate('/laudoVisita?uidArea=' + a.uidArea)
          }

        }}><LaunchSharp /></IconButton>
      </div>)
      }


    </div>
  }

  return (
    <Card sx={{ m: 2, minHeight: 360, display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: 1, minWidth: 200, backgroundColor: "#008e6c", padding: 15 }}>
        <Typography variant='h6'>Visitas</Typography>
        {!mobile && <CardData data={selectDate} />}
      </div>
      <Calendar
        style={{ flex: 2, }}
        onChange={setSelectDate}
        value={selectDate}
        tileClassName={({ date, view }) => {
          let dataVerifica = format(date, 'yyyy-MM-dd')
          let mapData = datasVisita[dataVerifica]
          if (mapData) {
            if (mapData.laudos.length) {
              return "marca";
            }
            if (mapData.proximaArea.length) {
              return "marcaProxVisita";
            }
          }
          return "";
        }}
      />
      {mobile && <div style={{ flex: 1, minWidth: 200, backgroundColor: "#008e6c", padding: 15 }}>
        <CardData data={selectDate} />
      </div>}
    </Card>
  )
}
