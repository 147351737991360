import Report from "report";
import TableReportHelper from "report/helper";


export default class CaldasTalhaoReport extends Report {
    constructor({ caldas, perfil, areaCultivada }) {
        super();
        this.caldas = caldas;
        this.perfilAtual = perfil;
        this.areaCultivada = areaCultivada;
    }

    get title() {
        return "Caldas";
    }

    get fileName() {
        if (this.areaCultivada) {
            return 'Caldas :' + this.areaCultivada.nome + ' - ' + this.areaCultivada.safra + ' - ' + this.areaCultivada.cultura;
        } else {
            return this.title;
        }
    }
    adapterMeta(meta) {
        if (this.perfilAtual.logoEmpresa) {
            meta.logo = this.perfilAtual.logoEmpresa
        }
        let planoTxt = ""
        if (!this.perfilAtual.tipo || this.perfilAtual.tipo === 'comum') {
            planoTxt = "PLANO GRATUITO - "
        }
        meta.textoRodape2 = planoTxt + 'e-Laudo - www.elaudo.agr.br'
        meta.textoCabecalho1 = "Resumo de Caldas"
        meta.textoCabecalho2 = this.areaCultivada.nomeArea
        return meta
    }

    async render() {
        console.log('render', this.areaCultivada)
        console.log('this.caldas', this.caldas)
        this.helper = new TableReportHelper(this.areaCultivada);
        return [
            await this.renderHeader(),
            await this.renderCaldas(),
        ]
    }

    renderHeader() {
        const h = this.helper;
        const content = []
        content.push(
            h.panel([
                h.row(h.title('Produtor'), h.textField('nomeProdutor')),
            ], { widths: [75, "*"] }),
            h.panel([
                h.row(h.title('Propriedade'), h.textField('nomeArea'), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.textField('areaHa')),
                h.row(h.title('Cultura'), h.textField('cultura'), h.title('Safra'), h.textField('safra')),
            ], { widths: [75, "*", 70, 80] })
        )
        return content;
    }
    renderCaldas() {
        const content = []
        this.caldas.forEach((calda) => {
            this.renderCaldasTalhoes(content, this.caldas.talhoes, calda)
        })
        return content;
    }

    renderCaldasTalhoes(content, talhoes, caldasTalhao) {
        console.log("caldasTalhao ", caldasTalhao);
        const h = this.helper;
        let title = ""
        if (caldasTalhao) {
            caldasTalhao.talhoes.forEach((talhao) => {
                title += talhao.nome + "; "
            })
        }

        content.push(
            h.panel([
                h.row(h.title("Talhões: " + title, { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),
            ]),
        )


        content.push(h.tablePanel([
            {
                title: "Insumos (dose/ha)", getValue: (row) => {
                    let insumoName = ""
                    if (row.insumos) {
                        row.insumos.forEach((insumo) => {
                            insumoName += insumo.tipoMercadoria.nome + " ( " + insumo.aplicacao.doseHa + " ); "
                        })

                    }
                    return insumoName
                },
            },
            { title: "Área", field: "areaAplicacao", width: 70 },
            {
                title: "Data Prevista", field: "dataPrevista", width: 70
            },
            {
                title: "Status",
                getValue: (row) => {
                    return row.executado ? "Executado" : "Pendente"
                },
                width: 70
            },
        ], [caldasTalhao]))
    }
}