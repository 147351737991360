import { createContext, useContext, useEffect } from "react";
import { Field, useField, useForm } from "react-final-form";
import { useOkFormContext } from "..";

const NestedFieldContext = createContext();

export function usePrefixContext() {
    return useContext(NestedFieldContext);
}

function addPrefix(name, context) {
    if (context && context.prefix) {
        const index = context.index !== undefined ? `[${context.index}]` : "";

        return `${context.prefix}${index}` + (name ? `.${name}` : "");
    } else {
        return name;
    }
}

/**
 * Adiciona o prefixo no contexto atual ao nome de campo especificado.
 */
export function useFieldPrefix(name) {
    const context = usePrefixContext();
    return addPrefix(name, context);
}

/**
 * Retorna o valor atual de um campo, atualizado se modificado no formulário.
 */
export function useFieldValue(name) {
    const fieldName = useFieldPrefix(name);
    const field = useField(fieldName);
    const form = useForm();

    function setValue(value) {
        if (value instanceof Function) {


            form.change(fieldName, value(field.input.value));
        } else {

            form.change(fieldName, value);
        }
    }

    return [field.input.value, setValue];
}

/**
 * Adiciona um prefixo que permite agrupar campos em um objeto aninhado dentro de uma coleção.
 */
export function NestedField({ prefix, index, children }) {
    return (
        // Um NestedField pode estar contido em outro, e neste caso os prefixos são concatenados.
        <NestedFieldContext.Consumer>
            {(context) => {
                const nestedContext = {
                    prefix: addPrefix(prefix, context),
                    index: index,
                };

                return <NestedFieldContext.Provider value={nestedContext}>{children}</NestedFieldContext.Provider>;
            }}
        </NestedFieldContext.Consumer>
    );
}

/**
 * Prefix Field: Um Field com suporte a aninhamento e onChange(value).
 */
export function PField({ name, parse, onChange, ...props }) {
    const fieldName = useFieldPrefix(name);
    const form = useForm();
    const OkFormContext = useOkFormContext()
    useEffect(() => {
        if (onChange) {
            // Passa o estado do field e a api do formulário para o evento.
            form.registerField(fieldName, (state) => onChange({ ...state, form: form }), { value: true });
        }
    }, [fieldName, form, onChange]);

    // Força o final form a enviar strings vazias quando o campo estiver vazio.
    parse = parse || ((value) => value);
    let overPropsField = undefined
    // console.log('formApi', form.getState())
    if (OkFormContext?.meta?.disabledView) {

        overPropsField = { disabled: OkFormContext.meta.disabledView }
    }


    return <Field name={fieldName} parse={parse} {...props} overPropsField={overPropsField} />;
}
