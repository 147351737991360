import { Box, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import BaseDialog from "./BaseDialog";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "AppPersonalise";

export default function WindowDialog({
    title,
    children,
    hideTitle,
    hideCloseButton,
    titleButtons,
    titleProps = {},
    contentProps = {},
    ...props
}) {
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <BaseDialog fullWidth={true} maxWidth="md" fullScreen={fullScreen} {...props}>
            {(dialogProps) => (
                <>
                    {hideTitle ? null : (
                        <DialogTitle
                            {...titleProps}
                            sx={{
                                pr: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                                ...titleProps.sx,
                            }}
                        >
                            <Box>{title}</Box>

                            <Box>
                                {titleButtons}

                                {hideCloseButton ? null : (
                                    <IconButton
                                        onClick={() => dialogProps.onClose()}
                                        sx={{ color: theme.palette.secondary.contrastText }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </DialogTitle>
                    )}
                    <DialogContent {...contentProps} sx={{ display: "flex", ...contentProps.sx }} dividers>
                        {children(dialogProps)}
                    </DialogContent>
                </>
            )}
        </BaseDialog>
    );
}
