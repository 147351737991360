import React, { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

export const AppContext = React.createContext({})

export default function AppControl() {
    const [title, setTitle] = useState(null)
    const [actions, setActions] = useState([])
    const [routes, setRoutes] = useState([])
    const [menus, setMenus] = useState([])
    const [plans, setPlans] = useState([])

    const addMenu = useCallback((menu) => {

        setMenus((antMenus) => {
            let newArray = antMenus.slice();
            let menuAdd = menu

            if (menu.parent) {
                menuAdd = { ...menu.parent, children: [menu] }
            }
            if (!menuAdd.sequence) {
                menuAdd.sequence = '555555'
            }
            let index = newArray.map(a => a.uid).indexOf(menuAdd.uid);
            if (index !== -1) {
                if (menu.parent) {
                    let filhos = newArray[index].children
                    let nefF = filhos ? filhos.slice() : []
                    let indexf = nefF.map(a => a.uid).indexOf(menu.uid);
                    if (indexf !== -1) {
                        nefF[indexf] = menu
                    } else {

                        nefF.push(menu)
                    }
                    nefF.sort((a, b) => {
                        let av = a.sequence || a.text;
                        let bv = b.sequence || b.text;
                        // eslint-disable-next-line eqeqeq
                        if (av == bv) {
                            return a.text > b.text ? 1 : -1
                        }

                        return av > bv ? 1 : -1
                    });
                    newArray[index].children = nefF
                } else {

                    newArray[index] = menuAdd;
                }
            } else {
                newArray.push(menuAdd);
            }
            newArray.sort((a, b) => {
                let av = a.sequence || a.text;
                let bv = b.sequence || b.text;
                // eslint-disable-next-line eqeqeq
                if (av == bv) {
                    return a.text > b.text ? 1 : -1
                }

                return av > bv ? 1 : -1
            });

            if (!_.isEqual(antMenus, newArray)) {
                return newArray
            }
            return antMenus
        });
    }, [])

    const addRoute = useCallback((route) => {
        setRoutes((antRoutes) => {
            let newArray = antRoutes.slice();
            let index = newArray.map(a => a.uid).indexOf(route.uid);
            if (index !== -1) {
                newArray[index] = route;
            } else {

                newArray.push(route);
            }

            if (!_.isEqual(antRoutes, newArray)) {
                return newArray
            }
            return antRoutes
        });
    },
        []
    )

    const setActionsView = useCallback((actionsView) => {
        if (Array.isArray(actionsView)) {
            actionsView.sort((a, b) => {
                let av = a.sequence || a.text;
                let bv = b.sequence || b.text;
                // eslint-disable-next-line eqeqeq
                if (av == bv) {
                    return a.text > b.text ? 1 : -1
                }

                return av > bv ? 1 : -1
            });
            setActions(actionsView);
        } else {
            setActions([actionsView]);
        }
    }, [])


    const removeMenusRoutes = useCallback((arrayUids) => {
        // console.log('removeMenusRoutes', arrayUids)
        if (typeof arrayUids === 'string' || arrayUids instanceof String)
            arrayUids = [arrayUids]


        setMenus((antMenus) => {
            let newArray = antMenus.slice();
            arrayUids.forEach(uid => {
                let index = newArray.map(a => a.uid).indexOf(uid);
                if (index !== -1) {
                    newArray.splice(index, 1)
                }
            })
            return newArray
        });
        setRoutes((antRoutes) => {
            let newArray = antRoutes.slice();
            arrayUids.forEach(uid => {
                let index = newArray.map(a => a.uid).indexOf(uid);
                if (index !== -1) {
                    newArray.splice(index, 1)
                }
            })


            return newArray
        });
    }, [])

    const clearMenusRoutes = useCallback(() => {
        console.log('clearMenusRoutes')
        setMenus([]);
        setRoutes([]);
    }, [])


    const useSetTitle = (newTitle) => {
        useEffect(() => {
            setTitle(newTitle)

            return () => {
                setTitle(null)
            }
        }, [newTitle])
        return;
    }

    const usePlans = useCallback(async () => {
        if (window.cordova && window.cordova.platformId === 'android') {
            const { store, ProductType, Platform } = window.CdvPurchase;
            store.register([
                {
                    id: 'elaudo.assinatura.pro',
                    type: ProductType.PAID_SUBSCRIPTION,
                    platform: Platform.GOOGLE_PLAY,
                }
            ]);
            store.when()
                .productUpdated((...args) => {
                    console.log("args ", args);
                    setPlans(args)
                })
            store.initialize([Platform.GOOGLE_PLAY]);
        }
    }, [])

    return { routes, addRoute, menus, addMenu, actions, setActionsView, removeMenusRoutes, clearMenusRoutes, title, setTitle, useSetTitle, usePlans, plans }

}

export const AppProvider = ({ valueUsar, children }) => {

    return <AppContext.Provider value={valueUsar}>
        {children}
    </AppContext.Provider>

}
export const useAppContext = () => useContext(AppContext)

