

import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../app/AppContext';
import { Box, Typography, useMediaQuery } from '@mui/material';



import "./style.css"
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import TelaInicialDash from 'entity/AreaCultivada/components/Dashboards/TelaInicialDash';
import AlertaPeriodoTeste from 'entity/Perfil/components/AlertaPeriodoTeste';
import { usePerfil } from 'useAuth';
import { saveOnlyNew } from 'dbApi';

const im_Logo = require('./images/elaudo_logo.png');
const im_Novo = require('./images/novo.png');
const im_Consulta = require('./images/consulta.png');
// const im_fundo = require('./images/fundo.jpg');

export default function Home() {
    const perfil = usePerfil()
    const theme = useTheme()
    let mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [showDialog, setShowDialog] = useState(true)

    const { useSetTitle, usePlans } = useAppContext();

    useSetTitle("e-Laudo")
    usePlans()

    const handleSupport = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=556798463002&text=Olá!%20Gostaria%20de%20ajuda%20sobre%20o%20e-Laudo.",
            "_blank"
        );
    }

    useEffect(() => {
        const dialogShown = window.localStorage.getItem('dialogPlanWarning');
        if (dialogShown === "false") {
            setShowDialog(false)
        }
    }, []);

    useEffect(() => {
        if (perfil) {
            if (perfil.tipo && perfil.tipo === "admin") {
                return
            }
            if (perfil.trialDate) {
                if (perfil.planoContratado && perfil.planoContratado.isActive) {
                    perfil.trialDate.isActive = false
                    saveOnlyNew({ uid: perfil.uid, trialDate: perfil.trialDate }, "User")
                }

                let endDateToJsFormat = new Date(perfil.trialDate.end)
                let today = new Date()
                if (endDateToJsFormat < today) {
                    perfil.trialDate.isActive = false
                    saveOnlyNew({ uid: perfil.uid, trialDate: perfil.trialDate }, "User")
                }
            } else {
                let startDate = new Date()
                let endDate = new Date(startDate)
                endDate.setMonth(startDate.getMonth() + 1);
                perfil.trialDate = { start: startDate.toISOString().split('T')[0], end: endDate.toISOString().split('T')[0], isActive: true }
                saveOnlyNew({ uid: perfil.uid, trialDate: perfil.trialDate }, "User")
            }
        }
    }, [perfil])

    return (
        <Box className='inicialContant'>
            {showDialog &&
                <AlertaPeriodoTeste />
            }
            <Box sx={{ background: "rgba(0,0,0,0.38)", paddingLeft: "5px", paddingRight: "5px", borderBottomRightRadius: 5, borderBottomLeftRadius: 5, cursor: 'pointer' }} onClick={handleSupport}>
                <Typography textAlign="center" color="white" >
                    <strong>O e-Laudo mudou!</strong>
                </Typography>
                <Typography textAlign="center" fontSize={14} color="white" >
                    Caso tenha alguma dúvida, clique nesta mensagem para entrar em contato com o suporte.
                </Typography>
            </Box>
            <div className='logoContant'>
                <img className='logo' src={im_Logo} alt="Logo" />
                <Typography style={{
                    fontSize: 48,
                    fontFamily: "Roboto",
                    fontWeight: '900',
                    color: '#028b76',
                }}>e-Laudo</Typography>
            </div>



            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', paddingBottom: 25, paddingTop: 25 }}>

                <Link to={'laudoVisita'} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img style={{ width: mobile ? 100 : 120 }} src={im_Novo} alt="novoLaudo" />
                    <Typography style={{
                        fontSize: mobile ? 18 : 24,
                        fontFamily: "Roboto",
                        fontWeight: '900',
                        color: '#008e6c',
                    }}>Novo Laudo</Typography>
                </Link>

                <Link to={'consulta-laudoVisita'} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img style={{ width: mobile ? 100 : 120 }} src={im_Consulta} alt="consutaLaudos" />
                    <Typography style={{
                        fontSize: mobile ? 18 : 24,
                        fontFamily: "Roboto",
                        fontWeight: '900',
                        color: '#008e6c',
                    }}>Consultar Laudos</Typography>
                </Link>

            </div>
            <div style={{ flex: 1, width: "100%", marginBottom: 30 }}>
                <TelaInicialDash />
            </div>

        </Box>
    )
}
