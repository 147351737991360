import React, { useEffect } from 'react'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { format } from 'date-fns'
import _ from 'lodash'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
const processaColuns = (columns, formatField) => {
    let r = []
    columns.forEach(c => {
        let nc = { ...c };
        if (c.headerName) {
            c.name = c.headerName
        }
        if (c.field) {
            nc.selector = (row) => {

                return formatField(_.get(row, c.field), row, c)
            }
        }
        // width: "100px",
        //cell: (row, index,column) => <EditComp row={row} index={index} />
        r.push(nc)

    });
    return r;
}
const defaultFormatField = (value, row, c) => {

    if (value && (value.toDate || value.getTime || value.seconds)) {
        let date = null;
        if (value.toDate) {
            date = value.toDate()
        }
        if (value.getTime) {
            date = value
        }
        if (value.seconds) {
            date = new Date(value.seconds * 1000)
        }
        if (date) {

            // console.log('date', date)
            let dataFormat = c.dataFormat || 'dd/MM/yyyy'
            let formatStr = format(date, dataFormat)
            // console.log('formatStr', formatStr)
            return formatStr
        } else {
            return "INVALID DATE"
        }

    }
    return value;
}
export default function OkTable({ columns, data, formatField, noWrapMobile, mobileColumns, noDataComponent, onRowClicked, ...props }) {
    const FormatFieldUSE = formatField || defaultFormatField
    const theme = useTheme()
    let mobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (noWrapMobile) {
        mobile = false;
    }
    const [_columns, setColumns] = useState(processaColuns(columns, FormatFieldUSE))

    let mobilegridTemplateColumns = '1fr'
    if (mobileColumns) {
        if (typeof mobileColumns === 'string') {
            mobilegridTemplateColumns = mobileColumns
        } else {
            mobilegridTemplateColumns = 'repeat(' + mobileColumns + ', 1fr)'
        }
    }

    useEffect(() => {
        if (mobile) {

            let cols = processaColuns(columns, FormatFieldUSE)
            cols.sort((a, b) => {
                if (!a.mobileOrder && !b.mobileOrder) {
                    return 0
                }
                if (a.mobileOrder === b.mobileOrder) {
                    return 0
                }
                if (!a.mobileOrder) {
                    return 1
                }
                if (!b.mobileOrder) {
                    return -1
                }

                return a.mobileOrder > b.mobileOrder ? 1 : -1
            })
            setColumns(cols)
        } else {
            setColumns(processaColuns(columns, FormatFieldUSE))
        }

    }, [FormatFieldUSE, columns, mobile])

    return (
        <>
            {mobile ? <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {(!(data || []).length) && <Typography>{noDataComponent || 'Sem registros.'}</Typography>}
                {(data || []).map((row, i) => {
                    return <Box key={'row' + i} onClick={() => {
                        if (onRowClicked) onRowClicked(row)
                    }}
                        sx={{ borderBottom: '1px solid #c9c9c9', mb: 1, p: 1, display: 'grid', gridTemplateColumns: mobilegridTemplateColumns }}>
                        {_columns.map((c, j) => {
                            if (!c.cell) {
                                return (
                                    <Box
                                        key={'row' + i + " c" + j}
                                        sx={{
                                            gridColumn: c.mobileGridColumn || c.mc,
                                            gridRow: c.mobileGridRow || c.mr,
                                            pr: 1,
                                        }}
                                    >
                                        <Typography variant='caption' color={"rgba(0,0,0,0.6)"}>{c.name}</Typography>
                                        <Typography>{c.selector(row, i) || "-"}</Typography>
                                    </Box>
                                )

                            } else {
                                return (
                                    <Box
                                        key={'row' + i + " c" + j}
                                        sx={{
                                            gridColumn: c.mobileGridColumn || c.mc,
                                            gridRow: c.mobileGridRow || c.mr,
                                            pr: 1,
                                        }}
                                    >
                                        <Typography variant='caption' color={"rgba(0,0,0,0.6)"}>{c.name}</Typography>
                                        {c.cell(row, i, c)}
                                    </Box>
                                )
                            }

                        })}
                    </Box>
                })}</Box>
                :
                <DataTable
                    columns={_columns.map((column) => ({ ...column, sortable: true }))}
                    data={data}
                    onRowClicked={onRowClicked}
                    noDataComponent={noDataComponent || 'Sem registros.'}
                    {...props}

                />
            }
        </>
    )
}
