import React, { useEffect } from 'react'

import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import AutocompleteInput from 'components/form/input/AutocompleteInput'
import TextInput from 'components/form/input/TextInput'
import SeletorInsumo from 'entity/Insumo/components/SeletorInsumo'

import _ from 'lodash'
import OkTable from 'components/OkTable'
import DateInput from 'components/form/input/DateInput'
import CheckboxInput from 'components/form/input/CheckboxInput'

export default function CaldaForm(props) {
    const { talhoesLaudo } = props
    const { change } = props.form

    const { talhoes, insumos, volumeTanque, vazao, areaTanque } = props.values

    useEffect(() => {


        (insumos || []).forEach((insumo, index) => {
            if (insumo.aplicacao) {

                let doseHa = insumo.aplicacao.doseHa
                let doseTanque = insumo.aplicacao.doseTanque
                let r = 0
                if (doseHa && areaTanque) {

                    r = parseFloat(doseHa) * parseFloat(areaTanque)
                    r = r.toFixed(2)
                    if (r !== doseTanque) {
                        change("insumos." + index + ".aplicacao.doseTanque", r)
                    }

                }
            }


        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insumos, areaTanque])


    useEffect(() => {
        let totalAreaTalhoes = 0;

        (talhoes || []).forEach((talhao) => {
            totalAreaTalhoes += parseFloat(talhao.areaHa)
        })

        change("areaAplicacao", totalAreaTalhoes.toFixed(2))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talhoes])

    useEffect(() => {
        let totalTanque = 0

        if (volumeTanque && vazao) {
            totalTanque = parseFloat(volumeTanque) / parseFloat(vazao)
        }

        change("areaTanque", totalTanque.toFixed(1))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vazao, volumeTanque])

    const columnsInsumo = [
        {
            mc: "1 / span 2",
            name: "Insumo",
            selector: (row) => _.get(row, "descricao") + " - " + _.get(row, "unidadeMedida.sigla")
        },

        {
            name: "Dose (ha)",
            cell: (row, index) => {
                return <TextInput name={"insumos." + index + ".aplicacao.doseHa"} label=" " type="number" />

            }
        },
        {
            name: "Dose Tanque",
            cell: (row, index) => <TextInput name={"insumos." + index + ".aplicacao.doseTanque"} label=" " type="number" disabled />
        },

    ]


    return (
        <FormContent sx={{ p: 1 }}>
            <FormRow noWrap>
                <AutocompleteInput
                    required
                    multiple
                    name="talhoes" label="Talhões"
                    options={talhoesLaudo}
                    disabled={!talhoesLaudo ? true : false}
                    getOptionLabel={(option) => option ? option.nome : ""}
                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                />
            </FormRow>

            <FormRow>
                <TextInput name="volumeTanque" label="Volume Tanque (L)" type="number" required />
                <TextInput name="vazao" label="Vazão (L/ha)" type="number" required />
                <TextInput name="areaTanque" label="Quantidade Tanque (ha)" type="number" disabled />
            </FormRow>
            <FormRow noWrap>
                <TextInput name="volumeTanque" label="Vol. Tanque (L)" type="number" required />
                <TextInput name="vazao" label="Vazão (L/ha)" type="number" required />
            </FormRow>
            <FormRow>
                <SeletorInsumo name='insumos' label="Insumos" searchButtonDisabled={false} required helperText={"Informe vários insumos"} multiple={true} />
            </FormRow>
            <OkTable
                mobileColumns={"1fr 60px 80px"}
                noWrapMobile
                columns={columnsInsumo}
                data={insumos}
                noDataComponent='Nenhum insumo lançado'
            />

            <FormRow>
                <TextInput name="observacao" label="Observação de aplicação" multiline />
            </FormRow>
            <FormRow noWrap>
                <DateInput
                    name="dataPrevista"
                    label="Data Prevista Realizar"
                />
                <CheckboxInput name="executado" label="Executado" />
            </FormRow>

        </FormContent>
    )
}
