import { useEffect, useState } from "react";

import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import _ from 'lodash'
import FForm, { useFormApi } from "..";
import FormRow from "../FormRow";
import DataTable from "components/DataTable";
import WindowDialog from "components/dialog/WindowDialog";
import TextInput from "../input/TextInput";
import SubmitButton from "../SubmitButton";
import FormContent from "../FormContent";
import { theme } from "AppPersonalise";
import { filterList } from "../../DataFilter/filter";
import NewFormButton from "./NewFormButton";


export default function EntitySearchDialog({ checkboxSelection, columns, data, hideFilterColumns, defaultFilters, NewForm, EntityClass, ...props }) {
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const form = useFormApi();
    // const [newValue, setNewValue] = useState(undefined);
    const [filters, setFilters] = useState();
    const [results, setResults] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedInsumos, setSelectedInsumos] = useState([]);
    const [showFilterForm, setShowFilterForm] = useState(false);
    // console.log('newValue', newValue)
    useEffect(() => {
        let newArray = filterList(data, filters)

        setResults(newArray);
    }, [columns, data, filters]);

    useEffect(() => {
        if (defaultFilters) {
            let f = {}
            for (const key in defaultFilters) {
                if (Object.hasOwnProperty.call(defaultFilters, key)) {
                    const element = defaultFilters[key];
                    f[key] = { value: element }

                }
            }
            setFilters(f)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters])

    
    const handleSetInsumos = async () => {
        let values = []
        if(results && selectedRows){
            selectedRows.map((row) => {
                return values.push(results.filter(value => value.uid === row)[0])
                // row = row.where("tipoMercadoria.nome", "==", filterTipo)
            })
        }
        setSelectedInsumos(values)

    }

    function handleFilter(values) {
        const newFilters = {};
        columns.forEach((column) => {
            if (column.filter) {



                let v = _.get(values, column.field)
                if (v) {
                    newFilters[column.field] = { value: v };
                }

            }
        })

        setFilters(newFilters);
    }

    function toggleFilterForm() {
        setShowFilterForm(!showFilterForm);
    }

    useEffect(() => {
        setShowFilterForm(!fullScreen);
    }, [fullScreen]);

    return (
        <WindowDialog
            contentProps={{ sx: { p: { xs: 0, sm: 3 } } }}
            {...props}
        >
            {(dialogProps) => (
                <Box
                    sx={{ display: "flex", flex: 1, flexDirection: { xs: "column", sm: "row" }, gap: { xs: 0, sm: 2 } }}
                >
                    <Box className="filters" sx={{ maxWidth: { xs: "100%", sm: 200 }, p: { xs: 2, sm: 0 } }}>
                        <FForm formApi={form} onSubmit={handleFilter} initialValues={defaultFilters} contentProps={{ sx: { gap: showFilterForm ? 2 : 0 } }}>
                            <FormRow sx={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                                <SubmitButton startIcon={<SearchIcon />} size="small">
                                    Buscar
                                </SubmitButton>

                                <Button
                                    onClick={toggleFilterForm}
                                    variant="contained"
                                    size="small"
                                    endIcon={showFilterForm ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    sx={{ display: fullScreen ? "flex" : "none" }}
                                >
                                    Filtros
                                </Button>
                            </FormRow>

                            <Collapse in={showFilterForm}>
                                <FormContent>
                                    {columns.map((column, index) => {
                                        if (column.filter) {
                                            if (hideFilterColumns && hideFilterColumns.includes(column.field)) {
                                                return null;
                                            }
                                            return (
                                                <FormRow key={index}>
                                                    <TextInput name={column.field} label={column.headerName} />
                                                </FormRow>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </FormContent>
                            </Collapse>
                        </FForm>
                    </Box>
                    <Box sx={{ flex: 1, height: { xs: "100%", sm: "60vh" } }}>
                        <DataTable
                            idField="uid"
                            columns={hideFilterColumns ? columns.filter(c => !hideFilterColumns.includes(c.field)) : columns}
                            rows={results}
                            checkboxSelection={checkboxSelection}
                            onRowClick={checkboxSelection ? () => { } : (row) => dialogProps.onClose(row.row)}
                            sx={!checkboxSelection && {
                                "& .MuiDataGrid-row:hover": {
                                    cursor: "pointer",
                                },
                            }}
                            onSelectionModelChange={(selection) => {
                                setSelectedRows(selection)
                            }}
                            // components={{ Footer: CustomFooter }}
                            components={{
                                Footer: () =>
                                (
                                    <GridFooterContainer sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {/* Add what you want here */}
                                        {checkboxSelection &&
                                            <Button
                                                onClick={async (rows) => {
                                                    // console.log("rows", rows)
                                                    // setSelectedInsumos(values)
                                                    await handleSetInsumos()
                                                    dialogProps.onClose(selectedInsumos)
                                                }}
                                                variant="contained"
                                                // size="small"
                                                sx={{ m: 1 }}
                                            >
                                                Confirmar
                                            </Button>}
                                        <Button
                                            onClick={() => dialogProps.onClose(false)}
                                            variant="contained"
                                            // size="small"
                                            sx={{ m: 1 }}
                                        >
                                            Cancelar
                                        </Button>
                                        {NewForm && <NewFormButton EntityClass={EntityClass} NewForm={NewForm} selectItem={item => dialogProps.onClose(item)} />}

                                        <GridFooter />
                                    </GridFooterContainer>
                                )
                            }}
                            {...props}
                        />
                    </Box>

                </Box>
            )}
        </WindowDialog>
    );
}
