import Report from "report";
import TableReportHelper from "report/helper";


export default class ManejosTalhaoReport extends Report {
    constructor({ manejos, perfil, areaCultivada }) {
        super();

        this.manejos = manejos;
        this.perfilAtual = perfil;
        this.areaCultivada = areaCultivada;

    }

    get title() {
        return "Manejos por Talhão";
    }

    get fileName() {
        if (this.areaCultivada) {


            return 'Manejos por Talhão :' + this.areaCultivada.nome + ' - ' + this.areaCultivada.safra + ' - ' + this.areaCultivada.cultura;
        } else {
            return this.title;
        }
    }
    adapterMeta(meta) {


        if (this.perfilAtual.logoEmpresa) {
            meta.logo = this.perfilAtual.logoEmpresa
        }
        let planoTxt = ""
        if (!this.perfilAtual.tipo || this.perfilAtual.tipo === 'comum') {
            planoTxt = "PLANO GRATUITO - "
        }
        meta.textoRodape2 = planoTxt + 'e-Laudo - www.elaudo.agr.br'
        meta.textoCabecalho1 = this.areaCultivada.nomeArea
        // meta.textoCabecalho2 = "Talhão: " + this.talhao.nome
        return meta
    }

    async render() {
        console.log('render', this.areaCultivada)
        console.log('this.manejos', this.manejos)
        this.helper = new TableReportHelper(this.areaCultivada);



        return [
            // await this.renderMap(),
            await this.renderHeader(),
            await this.renderManejos(),

        ]
    }

    async renderMap() {
        // const h = this.helper;
        // let img = this.imageTalhao
        const content = []
        // content.push(
        //     h.panel([
        //         // h.row(h.title("Talhão", { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),
        //         h.row(h.image(img, { alignment: 'center' })),
        //     ]),


        // )


        return content;
    }

    renderHeader() {
        const h = this.helper;

        const content = []


        content.push(
            h.panel([
                h.row(h.title('Produtor'), h.textField('nomeProdutor')),
            ], { widths: [75, "*"] }),
            h.panel([
                h.row(h.title('Propriedade'), h.textField('nomeArea'), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.textField('areaHa')),
                h.row(h.title('Cultura'), h.textField('cultura'), h.title('Safra'), h.textField('safra')),
                // h.row(h.title('Talhão'), h.text(this.talhao.nome), h.title('Área (' + this.areaCultivada.unidadeArea + ")"), h.text(this.talhao.areaHa)),
            ], { widths: [75, "*", 70, 80] })


        )


        return content;
    }
    renderManejos() {
        // const h = this.helper;

        const content = []
        this.areaCultivada.talhoes.forEach(talhao => {
            let manejosTalhao = this.manejos.filter(m => m.talhao.nome === talhao.nome)
            if (manejosTalhao.length) {
                this.renderManejosTalhao(content, talhao, manejosTalhao)
            }
        });
        return content;

    }

    renderManejosTalhao(content, talhao, manejosTalhao) {
        const h = this.helper;


        content.push(
            h.panel([
                h.row(h.title("Talhão: " + talhao.nome, { fontSize: 12, margin: [1, 10, 1, 10], alignment: "center" }),),
            ]),


        )


        content.push(h.tablePanel([
            // { title: "Talhão", field: "talhao.nome", },
            { title: "Insumo", field: "mercadoria.descricao", },
            { title: "Área", field: "area", type: "number", width: 55 },
            { title: "Dose", field: "dose", type: "number", width: 35 },
            { title: "QT", field: "quantidadeTotal", type: "number", width: 55 },
            {
                title: "Data Prevista", field: "dataPrevista", width: 60,
            },
            {
                title: "Status", width: 50,
                getValue: (row) => {
                    return row.executado ? "Executado" : "Pendente"
                }
            },

        ], manejosTalhao))


    }

}