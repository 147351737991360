
import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import SelectInput from 'components/form/input/SelectInput'
import TextInput from 'components/form/input/TextInput'
import SeletorMembroEquipe from 'entity/MembroEquipe/components/SeletorMembroEquipe'
import React from 'react'

export default function FormAddUsuario(props) {
    const { tipo } = props.values
    
    return (
        <FormContent sx={{ p: 1 }}>
            {tipo === "Equipe" ?
                <FormRow >
                    <SeletorMembroEquipe onSelectItem={(v) => {
                        console.log('onSelect', v)
                        if (v) {
                            props.form.change("uidConvidado", v.userUid)
                            props.form.change("emailConvidado", v.email)
                            props.form.change("nome", v.nome)
                            props.form.change("aceito", true)
                        }
                    }
                    } />

                </FormRow>
                :
                <FormRow >
                    <TextInput name="emailConvidado" label="Email" required />
                    <TextInput name="nome" label="Nome" required />
                </FormRow>
            }



            <FormRow >
                <SelectInput
                    required
                    name="permissao"
                    label="Permissão"
                    options={["Editor", "Leitura"]}//"Admin"
                />
            </FormRow>




        </FormContent>
    )
}
