import React, { useEffect } from 'react'
import { useAppContext } from '../../../app/AppContext'
import { useNavigate } from "react-router-dom";
import LoginPage from './components/LoginPage';

export default function RoutesMenusLogin() {

    const navigate = useNavigate();
    const { addMenu, addRoute, removeMenusRoutes } = useAppContext();
    useEffect(() => {
        addMenu({ uid: "loginPage", sequence: "111111", text: "Login", iconName: "Login", action: () => { navigate("/login") }, })
        addRoute({ uid: "loginPage", title: "Login", route: "/login", element: <LoginPage /> })

        return () => { removeMenusRoutes('loginPage', 'signUpPage') }
    }, [addMenu, addRoute, navigate, removeMenusRoutes])

    return (<></>)
}
