import FormContent from 'components/form/FormContent'
import FormRow from 'components/form/FormRow'
import AutocompleteInput from 'components/form/input/AutocompleteInput'
import DateInput from 'components/form/input/DateInput'
import TextInput from 'components/form/input/TextInput'
import { addDays, formatISO, parseISO } from 'date-fns'
import SeletorInsumo from 'entity/Insumo/components/SeletorInsumo'
import React, { useEffect } from 'react'

export default function PlantioForm(props) {
    const { talhoesLaudo } = props
    const { dataInicioPlantio,  cultivar } = props.values

    const { change } = props.form


    useEffect(() => {
        if (cultivar && cultivar.ciclo) {

            let ciclo = cultivar.ciclo;
            let strRef = null
            // if (dataFimPlantio) {
            //     strRef = dataFimPlantio
            //     if (dataFimPlantio.includes('/')) {
            //         strRef = dataFimPlantio.split("/").reverse().join('-')
            //     }
            // } else 
            if (dataInicioPlantio) {
                strRef = dataInicioPlantio
                if (dataInicioPlantio.includes('/')) {
                    strRef = dataInicioPlantio.split("/").reverse().join('-')
                }
            }
            if (strRef) {
                try {

                    const from = parseISO(strRef)
                    console.log('from', from)
                    console.log('cultivar', cultivar)
                    const fromPlusDay = addDays(from, parseInt(ciclo))
                    console.log('fromPlusDay', fromPlusDay)
                    let result = formatISO(fromPlusDay, { representation: "date" });
                    console.log('result', result)
                    change("dataPrevInicioColheita", result)
                } catch (error) {

                }
            }
        }

        return () => {
        }
    }, [dataInicioPlantio, cultivar, change])



    return (
        <FormContent sx={{ p: 1 }}>
            <FormRow noWrap>
                <AutocompleteInput
                    required
                    name="talhao" label="Talhão"
                    options={talhoesLaudo}
                    disabled={!talhoesLaudo ? true : false}
                    getOptionLabel={(option) => option ? option.nome : ""}
                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                    onSelect={(v) => {
                        console.log('onSelect', v)
                        if (v) {
                            change("area", v.areaHa)
                        }

                    }}
                />
                <TextInput sx={{ flex: 1 }} type="number" name="area" label="Área Plantio" required />
            </FormRow>
            <FormRow>
                <SeletorInsumo name='cultivar' label='Cultivar' required filterTipo={"Cultivar"} />
            </FormRow>
            <FormRow >
                <DateInput
                    name="dataInicioPlantio"
                    label="Inicio do Plantio"
                />
                <DateInput
                    name="dataFimPlantio"
                    label="Fim do plantio"
                />
                <DateInput
                    name="dataEmergencia"
                    label="Data Emergência"
                />
                <DateInput
                    name="dataPrevInicioColheita"
                    label="Prev. Início do Colheita"
                    disabled
                />

            </FormRow>

            <FormRow noWrap>
                <TextInput name="numeroGraos" label="N° Grãos" type="number" />
                <TextInput name="numeroVagens" label="N° Vagens" type="number" />
            </FormRow>
            <FormRow noWrap>
                <TextInput name="numeroNo" label="N° Nó" type="number" />
                <TextInput name="alturaPlanta" label="Altura Planta(CM)" type="number" />
            </FormRow>
            <FormRow noWrap>
                <TextInput name="sementeMetro" label="Sementes p/ Metro" type="number" />
                <TextInput name="plantasMetro" label="Plantas p/ Metro" type="number" />

            </FormRow>
            <FormRow noWrap>
                <TextInput name="espacamento" label="Espaçamento entre linhas(CM)" type="number" />
            </FormRow>
        </FormContent>
    )
}
