import BasicRoute from "components/routes/BasicRoute";
import CulturaConsulta from "entity/Cultura/components/CulturaConsulta";
import CulturaForm from "entity/Cultura/components/CulturaForm";


export default function RoutesMenusCultura() {
    return <>
        <BasicRoute
            uid='CulturaConsult'
            route="consulta-cultura"
            divider
            menuProps={{
                text: "Culturas",
                sequence: "400000",
                iconName: "Spa",
            }}
            element={<CulturaConsulta />}
        />
        <BasicRoute
            uid='culturaForm'
            route="cultura"
            // menuProps={{
            //     text: "Nova Reunião",
            //     iconName: "GroupAdd",
            //     sequence: "000000",
            // }}
            element={<CulturaForm />}
        />



    </>;
}