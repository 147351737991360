import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { theme } from "AppPersonalise";
import React, { Children, useState } from "react";
import styled from "@emotion/styled";

function TabContent({ value, index, children }) {
    return value === index ? children : "";
}

const Tabselectedcolor = styled(Tab)(({ selectedcolor, colort }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: colort || "white",
        backgroundColor: selectedcolor
    },
    backgroundColor: "rgba(150,150,150,0.2)",


}));
export default function TabContainer({ children, sx, tabsProps, tab, changeTab, hideIfOne, ...props }) {
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        if (changeTab) {
            changeTab(newValue)
        }
        setValue(newValue);
    }



    children = Children.toArray(children);
    const tabs = [];

    for (const child of children) {
        if (child.props.label) {
            tabs.push(child.props.label);
        }
    }
    const atualTab = tab !== undefined ? tab : value
    return (
        <Box sx={{ width: "100%", ...sx }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", display: !hideIfOne ? 'block' : tabs.length === 1 ? 'none' : 'block' }}>
                <Tabs value={atualTab} onChange={handleChange} variant={smallScreen ? "fullWidth" : "standard"} {...tabsProps} {...props}>
                    {tabs.map((tab) => (
                        // <Tab label={tab} key={tab} />
                        <Tabselectedcolor selectedcolor={theme.palette.primary.main} label={tab} key={tab} />
                    ))}
                </Tabs>
            </Box>

            {children.map((child, index) => (
                <TabContent value={atualTab} index={index} key={index}>
                    {child}
                </TabContent>
            ))}
        </Box>
    );
}
