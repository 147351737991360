/**
 * Exibe o dialógo, e retorna uma promise que será resolvida quando o diálogo for fechado.
 */
export async function showDialog(dialog) {
    return new Promise((resolve, reject) => {
        dialog.onClose((...args) => {
            // resolve não pode receber mais de um argumento, então passa os valores em um array.
            if (args.length > 1) {
                resolve([...args]);
            } else {
                resolve(...args);
            }
        });

        dialog.setOpen(true);
    });
}
