import { Button, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useAppContext } from 'app/AppContext'
import React from 'react'

import * as Icons from '@mui/icons-material/';


const RenderButton = ({ action }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const actionClick = () => {
        if (action.action) {
            action.action();
        }

    }

    let iconeUsar = null;

    if (action.icone) {
        let Teste = action.icone
        iconeUsar = <Teste />;
    }
    if (action.iconeStr) {
        const RecIcon = Icons[action.iconeStr]
        iconeUsar = <RecIcon />;
    }
    if (action.iconName) {
        const RecIcon = Icons[action.iconName]
        iconeUsar = <RecIcon />;
    }

    if (mobile && iconeUsar) {
        return (<IconButton className='topbarbuttomaction'
            key={"BtActionCrud" + action.iconName}
             style={{ color:"#fff" }}
          
            onClick={actionClick} {...action.props} >
            {iconeUsar}
        </IconButton>)
    }
    return <Button key={"BtActionCrud" + action.iconName}
        startIcon={iconeUsar}
        style={{ marginLeft: 10 }}
        variant="contained" color="secondary" onClick={actionClick} {...action.props}  >
        {action.text}
    </Button>
}
export default function TopMenu() {
    const { actions } = useAppContext()
    let aUsar = actions || []
    return (
        <div>
            {aUsar.map((a, i) => <RenderButton key={"ActionMenu" + i} action={a} />)}
        </div>
    )
}
