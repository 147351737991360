import { Button, IconButton, Menu } from '@mui/material';
import React, { Children, cloneElement, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export default function DropDownButton({ label, children, style }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [newChildren, setNewChildren] = useState([]);

    useEffect(() => {

        let newChildrenAux = [];


        for (const child of Children.toArray(children)) {

            newChildrenAux.push(cloneElement(child, {
                onClick: () => {
                    child.props.onClick()
                    handleClose()
                }
            }));
        }


        setNewChildren(newChildrenAux);
    }, [children]);

    return (
        <div>
            {label ?
                <Button
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    style={style}
                >{label}</Button>
                :
                <IconButton
                    onClick={handleClick}
                >
                    <KeyboardArrowDownIcon />
                </IconButton>
            }
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {newChildren}
            </Menu>
        </div>
    )
}
