
import { Check, LaunchSharp } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material'
import OkTable from 'components/OkTable'
import CaldasTalhaoReport from 'entity/AreaCultivada/reports/CaldasTalhaoReport';
import LaudoVisita from 'entity/LaudoVisita';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInDebug, usePerfil } from 'useAuth';

export default function TabelaCaldas({ caldas, areaCultivada, laudos }) {
    const [isLoading, setIsLoading] = useState(false)
    const IN_DEBUG = useInDebug()
    const perfil = usePerfil()
    const navigate = useNavigate();


    const imprimirCaldasTalhao = async () => {
        new CaldasTalhaoReport({ caldas, areaCultivada, perfil }).generate(IN_DEBUG);
    }


    const setExecuted = async (value) => {
        setIsLoading(true)
        let updateLaudo = null

        if (!value.executado || value.executado === false) {
            laudos.forEach((laudo) => {
                let caldasToUpdate = laudo.caldas.find(caldas => caldas.uid === value.uid)
                if (caldasToUpdate) {
                    caldasToUpdate.executado = true
                    updateLaudo = laudo
                }
            })
        } else {
            laudos.forEach((laudo) => {
                let caldasToUpdate = laudo.caldas.find(c => c.uid === value.uid)
                if (caldasToUpdate) {
                    caldasToUpdate.executado = false
                    updateLaudo = laudo
                }
            })
        }
        const laudoUpdate = new LaudoVisita()
        laudoUpdate.update(updateLaudo)
        await laudoUpdate.save()
        setIsLoading(false)
        
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flex: 1, gap: 1, p: 1, alignItems: 'center' }}>
                <Typography variant='h6' sx={{ flex: 1 }}>Caldas</Typography>
                <Button onClick={() => { imprimirCaldasTalhao() }}>Imprimir</Button>
            </Box>
            <OkTable
                noDataComponent={"Sem caldas"}
                data={caldas}
                columns={[
                    {
                        name: "Talhões",
                        selector: (row) => {
                            let talhaoName = ""
                            if (row.talhoes) {
                                row.talhoes.forEach((talhao) => {
                                    talhaoName += talhao.nome + "; "
                                })

                            }
                            return talhaoName
                        },
                        mobileGridColumn: "1 / span 2"
                    },
                    {
                        name: "Insumo (dose/ha)", selector: (row) => {
                            let insumoName = ""
                            if (row.insumos) {
                                row.insumos.forEach((insumo) => {
                                    insumoName += insumo.tipoMercadoria.nome + " ( " + insumo.aplicacao.doseHa + " ); "
                                })

                            }
                            return insumoName
                        }
                    },
                    { name: "Área Aplicação", field: "areaAplicacao", mobileGridRow: '2', mobileGridColumn: "3", width: "150px", },
                    {
                        name: "Data Prevista", field: "dataPrevista", width: "150px",
                        selector: (row) => {
                            return row.dataPrevista ? row.dataPrevista : "Sem Data"
                        }
                    },
                    {
                        name: "Status", width: "150px", selector: (row) => {
                            return row.executado ? "Executado" : "Pendente"
                        }
                    },
                    {
                        // name: "Marcar como realizado",
                        width: "65px",
                        mobileGridColumn: "3", mobileGridRow: '1',
                        cell: (row) => <IconButton disabled={isLoading} sx={{ fontSize: "9px" }} onClick={() => setExecuted(row)}><Check /></IconButton>
                    },
                    {
                        width: "65px",
                        mobileGridColumn: "3", mobileGridRow: '1',
                        cell: (row) => <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('#/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Caldas", '_blank')
                            } else {
                                navigate('/laudoVisita?uid=' + row.laudo.uid + "&tabMobile=Caldas")
                            }

                        }}><LaunchSharp /></IconButton>
                    }
                ]}
            />

        </Box>
    )
}
