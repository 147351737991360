import { isNumber } from "lodash";

/**
 * Slugify. Transforma string em um formato usável em URL.
 */
export function toSlug(txt) {
    if (isNumber(txt)) {
        txt = txt + "";
    }
    let str = txt.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");

    return str;
}

export function includesInsensitive(a, b) {
    if (!a) {
        return false;
    }
    if (!b) {
        return false;
    }
    return toSlug(a).includes(toSlug(b));
}


const formatNde = d => d < 10 ? "0" + d : d

export function formatHorarioString(horarioStr) {
    let d = new Date(horarioStr)
    return formatNde(d.getHours()) + ":" + formatNde(d.getMinutes()) + " - "
        + formatNde(d.getDate()) + "/" + formatNde(d.getMonth() + 1);
}