/**
 * Se possível, retorna o conteúdo do arquivo em formato de string Base 64.
 *
 * Função assíncrona.
 */
export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);

        reader.readAsDataURL(file);
    });
}
