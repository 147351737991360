import FormContent from 'components/form/FormContent'
import TextInput from 'components/form/input/TextInput'
import React from 'react'

export default function FormAddMembro(props) {
    // const { tipo } = props.values

    return (
        <FormContent sx={{ p: 1 }}>            
                <TextInput name="nome" label="Nome Convidado" required />
                <TextInput name="email" label="Email" required />          
        </FormContent>
    )
}
