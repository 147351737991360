import React from 'react'
import { useNavigate } from 'react-router-dom'

import DefaultEntityConsult from 'components/form/DefaultEntityConsult'

import Cultura from 'entity/Cultura'

import { Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default function CulturaConsulta() {
    const navigate = useNavigate()

    return (
        <DefaultEntityConsult
            prospTable={{ noWrapMobile: true }}
            EntityClass={Cultura}
            titulo={"Culturas"}
            routeForm={'cultura'}
            addPreColumns={[{
                width: "50px",
                mobileOrder: 1,
                cell: (row) => {
                    return <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
                        <IconButton onClick={(evt) => {
                            if (evt.ctrlKey) {
                                window.open('/cultura?uid=' + row.uid, '_blank')
                            } else {
                                navigate('/cultura?uid=' + row.uid)
                            }
                        }}><Edit /></IconButton>
                    </div>
                }
            }]}

        />
    )
}