import React, { useCallback } from 'react'

import DefaultEntityConsult from 'components/form/DefaultEntityConsult'

import Perfil from 'entity/Perfil'

export default function ReuniaoConsulta() {

    const filterValues = (list) => {
        return new Promise((resolve, reject) => {
            list.forEach(u => {
                if (!u.uid) {
                    u.uid = u.userUid
                }
                if (!u.tipo) {
                    u.tipo = "comum"
                }
            });
            resolve(list)
        })
    }

    const extraQuery = useCallback(
        (query) => {
            return new Promise((resolve, reject) => {
                query = query.orderBy("lastLogin", "desc")
                resolve(query)
            })
        }, []
    )

    return (
        <DefaultEntityConsult
            EntityClass={Perfil}
            titulo={"Perfis"}
            routeForm={'perfil'}
            filterValues={filterValues}
            extraQuery={extraQuery}
        />
    )
}