import React, { useCallback, useEffect, useState } from 'react'
import DefaultCrudForm from '../DefaultCrudForm'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useNotification from 'notification';
import { usePerfil } from 'useAuth';
import FormRowLoading from '../FormRowLoading';
export default function DefaultEntityCrud({ EntityClass, children, fixedUid, routeForm, ...othersProps }) {
    const navigate = useNavigate();
    const [loadingUid, setLoadingUid] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const { showSuccess, showWarning } = useNotification()
    const [searchParams] = useSearchParams();
    const searchUid = fixedUid || searchParams.get("uid")
    const perfil = usePerfil();

    const setNewValues = useCallback(async () => {
        let entity = await EntityClass.create();
        // console.log('setNewValues', entity.data)
        setInitialValues(entity.data)
    }, [EntityClass])

    useEffect(() => {
        let observer = null
        if (searchUid) {
            setLoadingUid(true)
            try {
                observer = EntityClass.onSnapshot(searchUid, (obj) => {
                    setInitialValues(obj)
                    setLoadingUid(false)
                })
            } catch (error) {

            }
        } else {
            setNewValues()
        }
        return () => {
            setLoadingUid(false)
            if (observer) {
                observer()

            }
        }
    }, [EntityClass, searchUid, setNewValues, perfil])


    const saveValues = (values) => {
        let entity = new EntityClass()
        // console.log("saveValues", values)

        entity.update({ ...values });

        return new Promise((resolve) => entity.save().then(() => {
            // console.log('salvou')
            showSuccess("Salvo com Sucesso")

            navigate("/" + routeForm + "?uid=" + entity.data.uid)
            resolve(entity.data)
        })
        )
    }
    const validValues = async (values) => {
        let errors = await EntityClass.validFieldErrors(values)

        if (errors) {
            if (errors.length) {
                showWarning(`${errors?.join("; ")}`)
                return false
            }
        }

        return true;
    }


    return (<DefaultCrudForm saveValues={saveValues} validValues={validValues} initialValues={initialValues} setNewValues={setNewValues} routeForm={routeForm} {...othersProps}>
        {(formProps) => (loadingUid ? <FormRowLoading /> : children(formProps))}
        
    </DefaultCrudForm>

    )
}