import { Add, Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'

import FormDialog from 'components/dialog/FormDialog'
import FormContent from 'components/form/FormContent'
import { useFieldValue } from 'components/form/input/NestedField'
import OkTable from 'components/OkTable'
import React, { useState } from 'react'
import FormAddUsuario from './FormAddUsuario'
import _ from 'lodash'
import { usePerfil } from 'useAuth'
export default function PainelMembrosPermitidos() {
    const title = "Usuários"
    const perfil = usePerfil()

    const permiteEditar = perfil && ['admin', 'equipe'].includes(perfil.tipo)
    const [values, setValues] = useFieldValue("usuarioPermitidos")

    const [usuariosConfirmados, setUsuariosConfirmados] = useFieldValue("usuariosConfirmados")//True False

    const columns = [
        { name: "Tipo", field: "tipo", width: '100px', },
        { name: "Nome", field: "nome" },
        { name: "Email", field: "emailConvidado" },
        { name: "Status", selector: (c) => c.aceito === undefined ? "Pendente" : c.aceito ? "Aceito" : "Recusado", width: '100px', },
        { name: "Permissão", field: "permissao" },

    ]
    const extraUpdate = (value) => {
        if (value.uidConvidado) {
            let up = _.cloneDeep(usuariosConfirmados)
            up[value.uidConvidado] = true
            setUsuariosConfirmados(up)
        }
    }
    const extraRemove = (value) => {
        if (value.uidConvidado) {
            let up = _.cloneDeep(usuariosConfirmados)
            up[value.uidConvidado] = false
            setUsuariosConfirmados(up)
        }
    }
    //up parans

    const [editValue, setEditValue] = useState(null)

    const updateValue = (value) => {
        let newArray = (values || []).slice();
        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf === -1) {
            newArray.push(value)

        } else {
            newArray[indexOf] = value;
        }
        setValues(newArray)
        extraUpdate(value)
    }
    const remover = (value) => {
        let newArray = (values || []).slice();
        let indexOf = value.index !== undefined ? value.index : -1;
        if (indexOf !== -1) {
            newArray.splice(indexOf, 1)
        }
        setValues(newArray)
        extraRemove(value)
    }

    const EditCellFunc = (row, index) => {
        return <div style={{ display: "flex", alignItems: 'center' }}>
            <IconButton onClick={() => setEditValue({ ...row, index })}><Edit /></IconButton>
            <IconButton onClick={() => remover({ ...row, index })}><Delete /></IconButton>
        </div >
    }
    if (permiteEditar) {

        columns.push({ cell: EditCellFunc, width: "100px" })
    }
    return (
        <FormContent>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant='h6' style={{ flex: 1 }}>
                    {title}
                </Typography>
                {permiteEditar && <>
                    <Button onClick={() => setEditValue({ tipo: "Equipe" })} ><Add /> Equipe</Button>
                    {/* <Button onClick={() => setEditValue({ tipo: "Convidado" })} >Convidar Email</Button> */}
                </>}
            </Box >
            <OkTable
                columns={columns}
                data={values || []}
                noDataComponent={"Sem usuários convidados"}
            />

            {editValue && <FormDialog
                title={"Adicionar Usuário"}
                onClose={() => {
                    setEditValue(undefined)
                }}
                open={editValue ? true : false}
                value={editValue}
                validValues={async (v) => {
                    let errorsMessage = []
                    for (let index = 0; index < values.length; index++) {
                        const t = values[index];

                        if (v.emailConvidado === t.emailConvidado) {
                            errorsMessage.push("Email já convidado")
                        }

                    }
                    if (errorsMessage.length) {
                        return errorsMessage
                    }

                    return null
                }}
                updateValue={values => {
                    updateValue(values);
                    setEditValue(undefined)
                }}
            >
                {(formProps) => <FormAddUsuario {...formProps} />}
            </FormDialog>}

        </FormContent>
    )
}
