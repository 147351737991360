import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
const colors = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042'
]
const CustomTooltip = (props) => {

    const { active } = props;

    if (active) {
        const { payload } = props;
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].name} : ${payload[0].value.toFixed(1)}% -  ${payload[0].payload.area.toFixed(2)}HA`}</p>
            </div>
        );
    }

    return null;
}
export default function TotaisArea({ areaCultivada, laudos, mapTotais, setMapTotais, captureRefChart }) {

    useEffect(() => {
        if (areaCultivada && laudos) {
            console.log('areaCultivada', areaCultivada)
            let dataInicioPlantioGeral = null
            let dataFimPlantioGeral = null
            let dataPrevistoColheitaGeral = null
            let producao = 0;
            let newMap = {}
            //grafico percet por cultivar
            let areaTotalPlantio = 0
            let areaTotalColhida = 0
            let areaPorCultivar = {}
            if (areaCultivada.plantios) {
                areaCultivada.plantios.forEach(plantio => {

                    let dataInicioPlantio = plantio.dataInicioPlantio
                    let dataFimPlantio = plantio.dataFimPlantio
                    let dataPrevistoColheita = plantio.dataPrevInicioColheita
                    if (dataInicioPlantio && dataInicioPlantio.includes("/")) {
                        dataInicioPlantio = dataInicioPlantio.split('/').reverse().join("-")
                    }
                    if (dataFimPlantio && dataFimPlantio.includes("/")) {
                        dataFimPlantio = dataFimPlantio.split('/').reverse().join("-")
                    }
                    if (dataPrevistoColheita && dataPrevistoColheita.includes("/")) {
                        dataPrevistoColheita = dataPrevistoColheita.split('/').reverse().join("-")
                    }
                    if (dataInicioPlantio && (!dataInicioPlantioGeral || dataInicioPlantioGeral > dataInicioPlantio)) {
                        dataInicioPlantioGeral = dataInicioPlantio
                    }
                    if (dataFimPlantio && (!dataFimPlantioGeral || dataFimPlantioGeral < dataFimPlantio)) {
                        dataFimPlantioGeral = dataFimPlantio
                    }
                    if (dataPrevistoColheita && (!dataPrevistoColheitaGeral || dataPrevistoColheitaGeral > dataPrevistoColheita)) {
                        dataPrevistoColheitaGeral = dataPrevistoColheita
                    }
                    let cultivar
                    if(plantio.cultivar){
                        cultivar = plantio.cultivar.descricao
                    } else {
                        cultivar = ""
                    }
                    let area = parseFloat(plantio.area)
                    areaTotalPlantio = areaTotalPlantio + area
                    let obj = areaPorCultivar[cultivar];
                    if (!obj) {
                        obj = { cultivar, area: 0 }
                        areaPorCultivar[cultivar] = obj
                    }

                    obj.area = obj.area + area
                    if (plantio.colheita) {
                        if (plantio.colheita.producao) {
                            producao = producao + parseFloat(plantio.colheita.producao)
                        }
                        if (plantio.colheita.areaColhida) {
                            areaTotalColhida = areaTotalColhida + parseFloat(plantio.colheita.areaColhida)
                        }
                    }
                });
            }

            console.log('areaPorCultivar', areaPorCultivar)
            newMap.areaTotalPlantio = areaTotalPlantio
            newMap.areaPorCultivar = areaPorCultivar
            newMap.areaTotalColhida = areaTotalColhida
            if (areaTotalPlantio) {

                newMap.percentColhida = areaTotalColhida / areaTotalPlantio * 100
            } else {
                newMap.percentColhida = 0
            }
            newMap.producao = producao

            newMap.dataInicioPlantioGeral = dataInicioPlantioGeral
            newMap.dataFimPlantioGeral = dataFimPlantioGeral
            newMap.dataPrevistoColheitaGeral = dataPrevistoColheitaGeral

            if (newMap.producao && areaTotalPlantio) {
                newMap.produtividade = parseFloat(newMap.producao) / parseFloat(areaTotalPlantio)
            }
            if (newMap.producao && areaTotalColhida) {
                newMap.produtividadeAreaColhida = parseFloat(newMap.producao) / parseFloat(areaTotalColhida)
            }
            newMap.areaPorCultivarChart = []

            Object.values(newMap.areaPorCultivar).forEach(a => {
                let percent = 0
                percent = a.area / areaTotalPlantio * 100

                newMap.areaPorCultivarChart.push({ name: a.cultivar, percent, area: a.area })
            });


            console.log('newMap', newMap)
            setMapTotais(newMap)
        }


    }, [areaCultivada, laudos, setMapTotais])
    const renderLegend = (props) => {
        const { payload } = props;

        return (<div >
            {payload.length > 6 ? "Muitas Safras" :
                <div >
                    {payload.map((entry, index) => (
                        <div key={index}>
                            <Typography style={{ color: entry.color }}>
                                {`${entry.payload.name}: ${entry.payload.value.toFixed(1)}% - ${entry.payload.area.toFixed(2)} ${areaCultivada.unidadeArea}`}
                            </Typography></div>
                    ))}
                </div>
            }
        </div>
        );
    }
    const TotalView = ({ label, value, sufix, date }) => {
        let v = value
        if (value && value.toFixed) {
            v = value.toFixed(2)
        }
        if (value && date) {
            v = value.split('-').reverse().join("/")
        }
        return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h6'>{v || "-"} {sufix}</Typography>
            <Typography variant='caption'>{label}</Typography>
        </Box>
    }
    return (
        <Box sx={{ display: 'flex', flexWrap: "wrap", p: 1, flexDirection: 'column' }}>
            {(!mapTotais.areaPorCultivarChart || !mapTotais.areaPorCultivarChart.length) && <Typography sx={{ textAlign: "center" }}>Sem dados de Plantios</Typography>}
            <Box sx={{ display: 'flex', flex: 1 }} ref={captureRefChart}>

                <ResponsiveContainer width={'95%'} height={280} >
                    <PieChart width={220} height={250}>
                        <Pie
                            data={mapTotais.areaPorCultivarChart || []}
                            dataKey="percent"
                            labelLine={false}
                            innerRadius={40} outerRadius={80} fill="#82ca9d" >
                            {(mapTotais.areaPorCultivarChart || []).map((entry, index) => <Cell key={index} fill={colors[index % colors.length]} />)}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend layout='vertical' content={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: 2, flex: 1 }}>

                <TotalView label={"Início Plantio"} value={mapTotais.dataInicioPlantioGeral} date />
                <TotalView label={"Fim Plantio"} value={mapTotais.dataFimPlantioGeral} date />
                <TotalView label={"Previsto colheita"} value={mapTotais.dataPrevistoColheitaGeral} date />

                <TotalView label={"Área Total Plantio"} value={mapTotais.areaTotalPlantio} sufix={areaCultivada.unidadeArea} />
                <TotalView label={"Área Total Colhida"} value={mapTotais.areaTotalColhida} sufix={areaCultivada.unidadeArea} />
                <TotalView label={"Porcentagem Colhida"} value={mapTotais.percentColhida} sufix={'%'} />
                <TotalView label={"Produção"} value={mapTotais.producao} sufix={areaCultivada.unidadeProducao} />
                <TotalView label={"Produtividade Geral (Área plantada)"} value={mapTotais.produtividade} sufix={areaCultivada.unidadeProducao + "/" + areaCultivada.unidadeArea} />
                <TotalView label={"Produtividade Atual (Área Colhida)"} value={mapTotais.produtividadeAreaColhida} sufix={areaCultivada.unidadeProducao + "/" + areaCultivada.unidadeArea} />

            </Box>
        </Box>

    )
}
