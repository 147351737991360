import React from 'react'
import FormContent from 'components/form/FormContent';
import { useSnapListEntity } from 'entity';
import OkTable from 'components/OkTable';

import Convite from 'entity/Convite';
import FormRowLoading from 'components/form/FormRowLoading';
import { Box, Button } from '@mui/material';
import { usePerfil } from 'useAuth';
import { useAppContext } from 'app/AppContext';



export default function PainelConvites() {
    const { useSetTitle } = useAppContext();
    useSetTitle('Convites')
    const perfil = usePerfil()

    const [convites] = useSnapListEntity(Convite)

    const aceitarConvite = (convite) => {
        if (convite.tipo === "Equipe") {
            Convite.aceitaConviteEquipe(convite.uid, perfil, convite.dadosConvite)
        }
    }
    const recusarConvite = (convite) => {
        if (convite.tipo === "Equipe") {
            Convite.recusarConviteEquipe(convite.uid, perfil, convite.dadosConvite)
        }
    }


    return (<FormContent sx={{ m: 2 }}>

        <FormRowLoading condition={!convites} />
        <OkTable
            noDataComponent={"Você não possui nenhum convite pendente"}
            data={convites || []}
            columns={[
                { field: "assunto", headerName: "Assunto", width: '100px', },
                { field: "descricao", headerName: "Descrição" },
                // { field: "emailConvidado", headerName: "Email", width: '225px', },
                { field: "perfilConvidou.nome", headerName: "Quem Convidou", width: '225px', },
                { field: "perfilConvidou.email", headerName: "Email", width: '225px', },

                { name: "Status", width: '225px', cell: (c) => (c.aceito === undefined || c.aceito === null) ? <Box sx={{ gap: 1 }}> <Button onClick={() => aceitarConvite(c)}>Aceitar</Button>  <Button onClick={() => recusarConvite(c)}>Recusar</Button> </Box> : c.aceito ? "Aceito" : "Recusado" },

            ]}
        />

    </FormContent >
    )



}

