

import logo64 from '../logo.png';

const stylesTemplate = {
    template: {
        relatorioTitulo: {
            fontSize: 20,
            bold: true,
        },
        cabecalhoTexto1: {
            fontSize: 18,
            bold: true,
        },
        cabecalhoTexto2: {
            fontSize: 12,
            bold: false,

        },
        cabecalhoTexto3: {
            fontSize: 24,
            bold: true,
        },
        rodapeTexto: {
            fontSize: 10,
        }
    },
    cellTitulo: {
        margin: [1, 1, 1, 1],
        fontSize: 9,
        bold: true,
        fillColor: '#dcdcdc',
    },
    cellValor: {
        margin: [1, 1, 1, 1],
        fontSize: 9,
        fillColor: '#f0f0f0',
    },
    cellLimpo: {
        margin: [1, 1, 1, 1],
        fontSize: 9,
    },
    fieldsetTitulo: {
        margin: [2, 2, 2, 2],
        fontSize: 11,
        bold: true,
        fillColor: '#285137',
        color: 'white'
    }
}

export function gerarDocumento(relatorioCustom, conteudo, meta) {
    let tituloRelatorio = "Titulo Relatorio"
    let textoCabecalho1 = ""
    let textoCabecalho2 = ""
    let textoCabecalho3 = ""
    let textoRodape1 = ""
    let textoRodape2 = ""

    let stylesUsar = stylesTemplate;

    const { styles, nome } = relatorioCustom
    if (styles) {
        stylesUsar = { ...stylesUsar, ...styles }
    }
    if (nome)
        tituloRelatorio = nome;

    let imageLogo = logo64;
    if (meta) {
        if (meta.logo) {
            console.log("meta.logo ", meta.logo);
            imageLogo = meta.logo.Foto.startsWith('data:') ? meta.logo.Foto : `data:image/png;base64,${meta.logo}`;
        }
        textoCabecalho1 = meta.textoCabecalho1
        textoCabecalho2 = meta.textoCabecalho2
        textoCabecalho3 = meta.textoCabecalho3
        textoRodape1 = meta.textoRodape1 || meta.infoVersao
        textoRodape2 = meta.textoRodape2
    }

    const documentImprimir = templatePadrao(conteudo,
        {
            icone: imageLogo,
            tituloRelatorio,
            textoCabecalho1,
            textoCabecalho2,
            textoCabecalho3,
            textoRodape1,
            textoRodape2,

        }, stylesUsar,
    );
    return documentImprimir
}


function cabecalhoPadrao(templateDados, currentPage, pageCount, pageSize, style) {
    return [{
        margin: [25, 9, 25, 0],
        // left, top, right, bottom
        table: {
            widths: [100, '*'],
            body: [
                [
                    {
                        border: [false, false, false, true],
                        alignment: 'center',
                        image: templateDados.icone,
                        width: 100,
                        height: 100,
                    },
                    {
                        border: [false, false, false, true],
                        margin: [15, 12, 25, 0],
                        // left, top, right, bottom
                        table: {
                            body: [
                                // [{
                                //     text: templateDados.tituloRelatorio,
                                //     style: style.template.relatorioTitulo,
                                // }],
                                [{
                                    text: templateDados.textoCabecalho1,
                                    style: style.template.relatorioTitulo,
                                    height: 150,
                                }],
                                [{
                                    text: templateDados.textoCabecalho2,
                                    style: style.template.cabecalhoTexto2
                                }],
                                [{
                                    text: templateDados.textoCabecalho3,
                                    style: style.template.cabecalhoTexto3
                                }],
                            ]
                        }, layout: 'noBorders'
                    }
                ]
            ]
        }
    }]
}

export function templatePadrao(conteudo, templateDados, styles) {
    var top = 120;
    const documentDefinition = {
        pageMargins: [25, top, 25, 55],
        pageSize: 'A4',
        pageOrientation: styles.orientation,

        header: function (currentPage, pageCount, pageSize) {
            return cabecalhoPadrao(templateDados, currentPage, pageCount, pageSize, styles)
        },

        footer: function (currentPage, pageCount) {
            return [{
                margin: [25, 10, 25, 0],
                table: {
                    widths: ['*', "auto", 60],
                    body: [
                        [
                            {
                                border: [false, true, false, false],
                                text: templateDados.textoRodape1,
                                style: styles.template.rodapeTexto
                            },
                            {
                                border: [false, true, false, false],
                                text: templateDados.textoRodape2,
                                style: styles.template.rodapeTexto
                            },
                            {
                                border: [false, true, false, false],
                                alignment: 'right',
                                text: 'Pág.: ' + currentPage.toString() + '/' + pageCount,
                                style: styles.template.rodapeTexto
                            }

                        ]
                    ],

                }
            }]
        },
        content: conteudo,
        styles: styles,

    };

    return documentDefinition;
}