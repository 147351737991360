import _ from "lodash";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBox from "../input/InputBox";
import { PField } from "../input/NestedField";
import { fetchArray } from "entity";
import EntitySearchDialog from "./EntitySearchDialog";
import { fieldHasError, requiredValidator } from "../input/validator";

export default function EntitySearchInput({
    name,
    label,
    required,
    helperText,
    EntityClass,
    initialValue,
    optionLabelField,
    extraQuery,
    onChange,
    onSelectItem,
    onlyIcon,
    sx,
    specificColumns,
    addFields,
    multiple,
    NewForm,
    ...props
}) {
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const loading = open && options.length === 0;

    const [searchDialog, setSearchDialog] = useState();

    const labelField = optionLabelField || EntityClass.labelField;
    const realExtraQuery = useMemo(() => extraQuery, [extraQuery]);
    useEffect(() => {
        // console.log('realExtraQuery', realExtraQuery)
        setOptions([]);
        return () => { }
    }, [realExtraQuery])



    const loadOptions = useCallback(async () => {
        // Carrega apenas uma vez.
        if (options.length === 0) {
            // console.log('load')
            let query = await EntityClass.query();
            // let query = await EntityClass.where(labelField, "!=", "");

            if (realExtraQuery) {
                query = realExtraQuery(query);
            }

            let result = await fetchArray(query.get());

            setOptions(["", ...result]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.length, EntityClass, realExtraQuery, addFields]);

    useEffect(() => {
        // Carrega ao abrir auto complete.
        if (open) {
            loadOptions();
        }
    }, [loadOptions, open]);

    function openSearchDialog() {
        loadOptions();
        searchDialog.setOpen(true);
    }

    return (
        <InputBox sx={sx}>
            <PField
                name={name}
                initialValue={initialValue}
                validate={required ? requiredValidator : null}
                onChange={onChange}
            >
                {(fieldProps) => {
                    const hasError = fieldHasError(fieldProps.meta);
                    return (
                        <>
                            <Autocomplete
                                multiple={multiple}
                                options={options}
                                getOptionLabel={(option) => option ? (_.get(option, labelField) + (addFields ? addFields.map(f => " - " + _.get(option, f)) : "")) : ""}
                                isOptionEqualToValue={(option, value) => value && option.uid === value.uid}
                                //
                                renderOption={(props, option) =>
                                    option.uid && _.get(option, labelField) ? (
                                        <li {...props} key={option.uid}>
                                            {_.get(option, labelField)}{addFields && addFields.map(f => " - " + _.get(option, f))}
                                        </li>
                                    ) : null
                                }
                                //
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                loading={loading}
                                //
                                sx={{ flex: 1 }}
                                forcePopupIcon={false}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ height: '100%' }}//para linhas responsivas
                                        label={label}
                                        required={required}
                                        error={hasError}
                                        helperText={hasError ? fieldProps.meta.error : helperText}
                                        variant="standard"
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        InputProps={{
                                            sx: { height: '100%' },//para linhas responsivas
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    <IconButton onClick={openSearchDialog} disabled={props.searchButtonDisabled} sx={{ p: "2px" }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                //
                                {...fieldProps.input}
                                {...props}
                                value={multiple ? fieldProps.input.value || [] : fieldProps.input.value

                                }
                                onChange={(event, value) => {
                                    // Necessário para passar os valores do MUI para o Final Form imediatamente.
                                    fieldProps.input.onChange(value);
                                    if (onSelectItem) onSelectItem(value)
                                }}
                            />

                            <EntitySearchDialog
                                checkboxSelection={multiple}
                                title={label}
                                columns={specificColumns ? specificColumns : EntityClass.searchColumns}
                                data={options}
                                setDialog={setSearchDialog}
                                EntityClass={EntityClass}
                                NewForm={NewForm}
                                onClose={(value) => { // está a retornar um boolean
                                    if (multiple) {
                                        if (Array.isArray(value)) {
                                            let newValues = []

                                            if (fieldProps.input.value) {
                                                fieldProps.input.value.map((v) => (
                                                    newValues.push(v)
                                                ))
                                            }
                                            (value || []).map((v) => newValues.push(v))
                                            console.log("newValues ", newValues);
                                            fieldProps.input.onChange(newValues);
                                        } else {
                                            fieldProps.input.onChange(fieldProps.input.value);
                                            // return false
                                        }

                                    } else {
                                        fieldProps.input.onChange(fieldProps.input.value);
                                    }
                                    if (onSelectItem) onSelectItem(value)
                                }}

                            />
                        </>
                    );
                }}
            </PField>
        </InputBox>
    );
}
