import { imprimirDados } from "OkReport/OkReportGerador";

export default class Report {
    constructor() {
        this.render = this.render.bind(this);
    }

    //
    // Métodos abstratos.
    //

    /**
     * Título do relatório.
     */
    get title() {
        return "";
    }

    /**
     * Nome do arquivo.
     */
    get fileName() {
        return this.title;
    }

    async render() { }

    //
    // Fim dos métodos abstratos.
    //

    async generate(open) {
        const result = await this.render();

        function renderSync() {
            return result;
        }

        const model = {
            directCode: renderSync,
            // directGetGraficos: null,
            // descricao: "Relatório de Visita Técnica",
            nome: this.title,
            // tipoModelo: "sSCy71ONYFEwlGVDK4kKUnico",
            publico: true,
        };

        const meta = {
            nomeArquivo: open ? null : this.fileName,
            infoVersao: "V." + process.env.REACT_APP_VERSION
        };

        imprimirDados(model, null, this.adapterMeta(meta));
    }
    adapterMeta(meta) {
        return meta;
    }

}
