import React from 'react'
import RoutesMenusLogin from './Login'
import RoutesMenusHome from './Home'
import RoutesMenusPoliticas from './TermosPoliticas'
import RoutesMenusSupport from './Support'
import RoutesMenusSignUp from './SignUp'
import RoutesMenusExclusaoDados from "./ExclusaoDados";

export default function RoutesMenusPublic() {
    console.log('Public config')
    return (
        <>
            <RoutesMenusLogin />
            <RoutesMenusHome />
            <RoutesMenusSupport />
            <RoutesMenusSignUp />
            <RoutesMenusPoliticas />
            <RoutesMenusExclusaoDados />
        </>
    )
}
